

import React, { useState } from 'react';
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';
import { UtilityService } from '../../../services/utility-service';
import { ContactUsFormData } from '../../../utils/types/contact-us-form-data';
import { useMutation } from 'react-query';
import { motion } from "framer-motion";
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';

const ContactUs = () => {
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const [inValidMessage, setInValidMessage] = useState(false);

    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const postContactFormSubmission = async (
      data: ContactUsFormData,
    ): Promise<ContactUsFormData | undefined> => {
        const response =
            await UtilityService.instance.postContactUsSubmission(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };
  
    const {
        mutate: submitContactFormData,
        isLoading: isContactFormLoading,
    } = useMutation(postContactFormSubmission, {
        onSuccess: data => {
            setFullName('');
            setEmail('');
            setMessage('');
            setIsFormSubmitted(true);
        },
        onError: error => {
            alert('Failed to process the request. Please try again!');
        },
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if(message.length < 16){
          setInValidMessage(true);
          return;
        }
        submitContactFormData({ 
          fullName, 
          email, 
          message,
          origin: 'STUDENT APP'
        });
    };

    return (
        <div className='w-full min-h-screen flex flex-col'>
            <NavbarStatic />
            <div className="flex justify-center items-center w-full p-2 flex-grow">
                <div className="w-[500px] p-10 rounded-lg border-2 shadow-xl">
                    <h2 className="text-2xl text-center m-0 mb-2">Have Questions?</h2>
                    <p>
                        We would love to hear from you! Contact us and share any
                        feedback or questions you may have.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-[15px]">
                            <label className='block ' htmlFor="fullName">Full name:</label>
                            <input
                                className='w-full p-2 border border-gray-300 rounded-md'
                                type="text"
                                id="fullName"
                                value={fullName}
                                onChange={e => setFullName(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-[15px]">
                            <label className='block ' htmlFor="email">Email:</label>
                            <input
                                className='w-full p-2 border border-gray-300 rounded-md'
                                type="email"
                                id="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="mb-[15px]">
                            <div className='flex items-center justify-between'>
                              <label className='block ' htmlFor="message">Message:</label>
                              {inValidMessage && <div className='text-xs text-red-400 pr-1'>Please enter 16 characters or more!</div>}
                            </div>
                            <textarea
                              className={`w-full p-2 border border-gray-300 rounded-md resize-y ${inValidMessage ? 'outline-2 outline-red-400' : ''}`}
                              id="message"
                              value={message}
                              onChange={e => {
                                setMessage(e.target.value);
                                setInValidMessage(false);
                              }}
                              rows={4}
                              required
                            ></textarea>
                        </div>
                        <button disabled={isContactFormLoading} className='bg-[#4caf50] hover:bg-[#45a049] text-white px-4 py-2 rounded-md w-full font-semibold active:scale-95 transition-all disabled:opacity-25' type="submit">Submit</button>
                    </form>
                </div>
            </div>
            <FooterStatic />
            {isFormSubmitted && (
              <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 100 }}
                  className='fixed bottom-0 left-0 top-0 h-full w-full right-0 z-[999999999999] flex items-center justify-center backdrop-blur-sm'
              >
                  <div className='sm:w-[480px] text-center w-[90vw] bg-white rounded-lg flex flex-col items-center justify-center shadow-2xl p-6 border-8 border-opacity-20 border-[#3AB7FC]'> 
                      <img 
                          src='/assets/images/logo.png'
                          className='p-2 h-20'
                          alt='logo'
                      />
                      <div className='text-center font-semibold text-2xl'>Thank you for contacting us!</div>
                      <div className='text-center text-gray-600'>We have recieved your message, we will get back to you shortly.</div>
                      <div onClick={() => navigate(Routes.LandingPage)} className='px-6 py-2 bg-[#3AB7FC] cursor-pointer rounded-lg text-white mt-4 text-[22px] font-semibold'>
                          Back To Homepage
                      </div>
                  </div>
              </motion.div>
            )}
        </div>
    );
};

export default ContactUs;
