import React from 'react'
import NavbarStatic from '../../components/navbar-static-pages'
import BlogsListViewModel from '../../view-models/blog-list-viewmodel';
import { Routes } from '../../navigation/routes';
import Footer from '../../components/landing-page-items/footer';
import Navbar from '../../components/landing-page-items/navbar';

const BlogsListPage = () => {
    const {
        isLoading,
        blogs,
        handleOpenBlogPost,
    } = BlogsListViewModel();

    return (
        <div className='h-screen flex flex-col'>
            <Navbar />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-6 md:px-16 mt-[88px]'>
                {blogs.map((blog) => (
                    <div 
                        key={blog?.id}
                        className='p-4 cursor-pointer rounded-md bg-slate-50 shadow-md shadow-slate-200'
                        onClick={() => handleOpenBlogPost(blog?.id)}
                    >
                        <img 
                            src={blog?.attributes?.previewImage?.data?.attributes?.url}
                            className='w-full h-64 object-cover rounded-lg cursor-pointer'
                        />
                        <div className=' rounded-lg p-4 cursor-pointer'>
                            <div className='flex flex-col'>
                                <div className='text-xl font-semibold'>{blog?.attributes?.title}</div>
                                <div className='text-gray-500 font-semibold text-sm line-clamp-3'>{blog?.attributes?.description}</div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Footer />
        </div>
    )
}

export default BlogsListPage