import { useNavigate, useParams } from "react-router-dom";
import { FeeService } from "../../services/fee-services";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/redux/store";



const PaymentStatusViewModel = () => {
    const {orderId} = useParams();
    const [orderStatus,setOrderStatus] = useState<CashfreeOrderDetails>()
    const navigate = useNavigate();
    const profile = useSelector((state: RootState) => state.activeProfile);
    useEffect(() => {
        if(orderId){
            FeeService.instance.getOrderDetails(orderId || '',profile?.activeClassData?.id).then((response) => {
                if (response.success) {
                    setOrderStatus(response.data?.data);
                }
            });
        }
    },[orderId])

    return {
        isLoading: false,
        orderStatus,
        navigate,
        activeClassId: profile?.activeClassData?.id
    };
}

export default PaymentStatusViewModel