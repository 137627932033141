import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ClassService } from '../../services/class-service';
import { CLASS_TRAINING_FEE_STRUCTURE_QUERY, STUDENT_CLASS_DETAILS_QUERY } from '../../utils/constants/globals';
import { useMutation, useQuery } from 'react-query';
import { FeeService } from '../../services/fee-services';
import { CreateFeeOrderPostData } from '../../models/fee-structure/create-fee-order-post-data';
import { CashfreeOrderData } from '../../models/fee-structure/cashfree-order-data';
import { FeeDetailsForOrder } from '../../models/fee-structure/fee-details-for-order';
import { Routes } from '../navigation/routes';


const FeesPaymentViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {classId,studentId} = useParams();
    const [isLoading,setIsLoading] = useState(false);

    const parent = useSelector((state: RootState) => state.activeProfile);

    const {
        isSuccess: isClassDetailsFetchSuccess,
        isLoading: isClassDetailsFetching,
        data: classDetails,
    } = useQuery({
        queryKey: [STUDENT_CLASS_DETAILS_QUERY],
        queryFn: () => ClassService.instance.getClassDetails(Number(classId)),
        refetchOnWindowFocus: false,
        enabled: (Number(classId) ?? 0) > 0,
    });

    const {
        isSuccess: isClassTraingFeeStructureFetchSuccess,
        isLoading: isClassTraingFeeStructureFetching,
        data: classTraingFeeStructure,
    } = useQuery({
        queryKey: [CLASS_TRAINING_FEE_STRUCTURE_QUERY],
        queryFn: () => FeeService.instance.getTrainingFeeStructureOfClass(Number(classId)),
        refetchOnWindowFocus: false,
        enabled:  (Number(classId) ?? 0) > 0,
    });

    const createFeeOrder = async (
        data: CreateFeeOrderPostData,
    ): Promise<{ data: CashfreeOrderData} | undefined> => {
        const response = await FeeService.instance.createFeeOrder(data);
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };


    const handleCheckoutClick = async(amount: number, feeType: 'Training' | 'Uniform/Gear' | 'Event/Competition' | 'Registration', feeDetails: FeeDetailsForOrder) => {
        setIsLoading(true)
        let orderId;
        try {
            let sessionId = await createFeeOrder({
                amount: amount, 
                classId: parent?.activeClassData?.id, 
                studentId: parent?.activeStudent?.id, 
                parentId: userState?.user?.id ?? 0,
                feeType: feeType,
                academyId: parent?.activeClassData?.attributes?.academies?.data?.id,
                ...feeDetails
            }).then((data) => {
                    if(data?.data?.payment_session_id){
                        orderId = data?.data?.order_id
                        return data?.data.payment_session_id
                    }else{
                        return null
                    }
                })

            if(sessionId === null){
                console.log("no session id");
                setIsLoading(false)
                return;
            }

            navigate(Routes.payment, {state: {sessionId: sessionId, orderId: orderId}})
            
        } catch (error) {
            console.error('Error during checkout', error);
            setIsLoading(false)
        }
    };

    

  return {
    isLoading: isClassDetailsFetching || isClassTraingFeeStructureFetching || isLoading,
    isSuccess: isClassDetailsFetchSuccess && isClassTraingFeeStructureFetchSuccess,
    classTraingFeeStructure: classTraingFeeStructure?.data?.data[0] || null,
    classDetails: classDetails?.data?.data,
    handleCheckoutClick,
  }
}

export default FeesPaymentViewModel
