


import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../assets/icons/link.svg"

const NavbarStatic = () => {
    const navigate = useNavigate();

    const handleNavigateToSignUpPage = () => {
        navigate('/sign-up');
    }

    return (
        <div>
            <nav className="flex gap-4 justify-between items-center sm:p-4 p-2 pb-10 sm:flex-row flex-col w-[100vw]">
                <div className="sm:h-auto h-4 mb-2">
                    <img src={logo} alt="SpArts Logo" className="h-10" />
                </div>
                <div className="flex sm:gap-8 gap-4 sm:justify-center sm:items-center justify-start sm:text-sm text-xs text-center">
                  <button
                    className="bg-yellow-500 text-white font-bold py-2 px-4 rounded cursor-pointer sm:block hidden"
                    style={{ fontFamily: 'Times New Roman, serif' }}
                  >
                    For Academies
                  </button>
                    {/* <button className="bg-yellow-500 text-white font-bold py-2 px-4 rounded cursor-pointer sm:block hidden">
                        For Academies
                    </button> */}
                    <button
                      className="bg-yellow-500 text-white font-bold py-2 px-4 rounded cursor-pointer sm:block hidden"
                      onClick={handleNavigateToSignUpPage}
                      style={{ fontFamily: 'Times New Roman, serif' }}
                    >
                      Sign Up
                    </button>
                    {/* <button className="bg-yellow-500 text-white font-bold py-2 px-4 rounded cursor-pointer sm:block hidden" onClick={handleNavigateToSignUpPage}>
                        Sign Up
                    </button> */}
                </div>
                <div className="sm:hidden flex justify-between gap-6">
                  <button
                    className="bg-yellow-500 w-auto text-white text-xs py-2 px-2 rounded cursor-pointer"
                    style={{ fontFamily: 'Times New Roman, serif' }}
                  >
                    For Academies
                  </button>

                  <button
                    className="bg-yellow-500 text-white text-xs py-2 px-4 rounded cursor-pointer"
                    onClick={handleNavigateToSignUpPage}
                    style={{ fontFamily: 'Times New Roman, serif' }}
                  >
                    Sign Up
                  </button>
                    {/* <button className="bg-yellow-500 text-white text-xs py-2 px-2 rounded cursor-pointer">
                        For Academies
                    </button>
                    <button className="bg-yellow-500 text-white text-xs py-2 px-4 rounded cursor-pointer" onClick={handleNavigateToSignUpPage}>
                       Sign Up  
                    </button> */}
                </div>
            </nav>
        </div>
    );
}

export default NavbarStatic;
