// import logo from "../../assets/link.svg"

import { useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";

const Footer = () => {
    const navigate = useNavigate();
  return (
      <>
          {/* Footer for desktop View */}
          <div className="sm:flex hidden footer bottom-0 relative h-[10vh] w-full justify-between items-center px-6 py-[4rem]">
              <div>
                  <img src="./assets/icons/link.svg" alt="link" />
              </div>
              <div className="w-[70%] flex justify-between items-center pr-10 font-bold text-[16px]">
                  <a onClick={() => navigate(Routes.cookiePolicy)}>
                      Cookie Policy
                  </a>
                  <a onClick={() => navigate(Routes.aboutUs)}>About Us</a>
                  <a onClick={() => navigate(Routes.contactUs)}>Contact Us</a>
                  <a onClick={() => navigate(Routes.termsAndConditions)}>
                      Terms & Conditions
                  </a>
                  <a onClick={() => navigate(Routes.privacyPolicy)}>
                      Privacy Policy
                  </a>
              </div>
          </div>
          {/* Footer for mobile view */}
          <div className="sm:hidden buttom-0 h-[10rem] w-full px-4 py-4 items-center">
              <div className="w-full flex justify-center h-[3rem]">
                  <img src="./assets/icons/link.svg" alt="link" />
              </div>
              <div className="flex justify-center gap-10 mt-4">
                  <a onClick={() => navigate(Routes.cookiePolicy)}>
                      Cookie Policy
                  </a>
                  <a onClick={() => navigate(Routes.aboutUs)}>About Us</a>
                  <a onClick={() => navigate(Routes.contactUs)}>
                      Contact Us
                  </a>
              </div>
              <div className="flex justify-center gap-10 mt-2">
                  <a onClick={() => navigate(Routes.termsAndConditions)}>
                      Terms & Conditions
                  </a>
                  <a onClick={() => navigate(Routes.privacyPolicy)}>
                      Privacy Policy
                  </a>
              </div>
          </div>
      </>
  );
}

export default Footer