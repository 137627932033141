import React, { useEffect, useState } from 'react';
// import Sidebar from '../../components/sidebar';
import { useNavigate } from 'react-router-dom';
import { IoChevronBack } from 'react-icons/io5';
import logo from "../../../assets/icons/link.svg"


import 'react-responsive-carousel/lib/styles/carousel.min.css';
import RowAction from '../../components/common/rowAction';
import Layout from '../../components/common/layout';
import { getJWTToken } from '../../../utils/helpers/helpers';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from '../../../utils/redux/store';
import RowActionEval from '../../components/common/rowActionEval';
import AssignmentPageViewModel from '../../view-models/assignment-viewmodel';

interface Result {
    Tips: string[];
    Score: string;
    Comments: string[];
    Findings: string[];
    Student_Image: string;
    Instructor_Image: string;
}

interface TechniqueResult {
    id: number;
    attributes: {
        email: string;
        name: string;
        techniqueName: string;
        timestamp: string;
        results: {
            data: Result[];
        };
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
    };
}

interface ResponseData {
    data: TechniqueResult[];
    meta: {
        pagination: {
            page: number;
            pageSize: number;
            pageCount: number;
            total: number;
        };
    };
}

const EvaluationPage = () => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [selectedProfileId, setSelectedProfileId] = useState<string>('');
    const navigate = useNavigate();

    const student = useSelector((state: RootState) => state.activeProfile);

    // const {loading, techniqueResults} = AssignmentPageViewModel()


    const handleBackClick = () => {
        navigate(-1); // Go back to the previous route
    };
    useEffect(() => {
        const handleResize = (): void => {
            if (window.innerWidth < 768) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // const [techniqueResults, setTechniqueResults] = useState<TechniqueResult[]>([]);
    // const [loading, setLoading] = useState<boolean>(true);

    // useEffect(() => {
    //     const fetchTechniqueResults = async () => {
    //         try {
    //             console.log(getJWTToken())
    //             console.log(student.activeStudent.id)
    //             const response = await axios.get<ResponseData>(
    //                 `https://strapiqa.sparts.app/api/technique-results?filters[student][id]=${student.activeStudent.id}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${getJWTToken()}`,                        },
    //                 }
    //             );
    //             setTechniqueResults(response.data.data);
    //         } catch (error) {
    //             console.error('Error fetching data:', error);
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchTechniqueResults();
    // }, [student]);



    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className="flex p-2 justify-start flex-col">
                <div className="sm:block hidden">
                    {<img src={logo} alt="logo" />}
                </div>

                <div className={`flex gap-8 sm:pl-3 sm:pt-4 pl-2`}>
                    <IoChevronBack
                        className="text-blue-500 text-3xl mt-3 sm:block hidden"
                        onClick={handleBackClick}
                    />

                    <span className="sm:text-4xl text-2xl font-light sm:mt-1">
                        Evaluations
                    </span>
                </div>
            </div>
            {/* <div className="sm:px-20 sm:py-6 flex flex-col gap-8">
                {loading && <div>Loading...</div>}
                {!loading && techniqueResults?.map(result => (
                    <RowActionEval
                        key={result.id}
                        text={`Technique: ${result.attributes.techniqueName}`}
                        dates={`Dated: ${new Date(result.attributes.timestamp).toLocaleString()}`}
                        id={result.id.toString()}
                        tryit={`${result.id}`}
                    />
                ))}
            </div> */}
        </Layout>
    );
};

export default EvaluationPage;
