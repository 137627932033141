import React from 'react'
import PaymentStatusViewModel from '../../view-models/payment-status-viewmodel';
import Layout from '../../components/common/layout';
import { formatDate, formatTime } from '../../../utils/helpers/helpers';
import { Routes } from '../../navigation/routes';

const PaymentStatusPage = () => {
    const {
        isLoading,
        orderStatus,
        navigate,
        activeClassId
    } = PaymentStatusViewModel();

  return (
    <Layout isProfileSwitchTabVisible={false} hideMobileBottomBar={true}>
        {orderStatus ? 
          <div className='fixed top-0 left-0 right-0 bottom-0 bg-[#F8C954] z-[10] flex flex-col items-center justify-center'>
            <div className='h-[calc(100vh-40px)] w-[90%] md:w-[460px] pt-[70px] flex flex-col'>
              <div className='flex-grow flex items-center'>
                <div className='bg-white w-full rounded-lg overflow-hidden overflow-y-auto flex flex-col py-[14px]'>
                  <div className='p-8 flex flex-col items-center justify-center w-full gap-2'>
                    <div className='h-14 w-14 rounded-full bg-green-200 flex items-center justify-center'>
                      <img
                        src='/assets/images/green-tick.png'
                        className='h-10 w-10'
                      />
                    </div>
                    <div>Payement Success!</div>
                    <div className='font-semibold text-2xl'>INR {orderStatus?.order_amount.toLocaleString()}</div>
                  </div>
                  <div className='flex items-center justify-between'>
                    <div className='h-8 w-8 bg-[#F8C954] rounded-full translate-x-[-16px]'></div>
                    <div className='h-8 w-8 bg-[#F8C954] rounded-full translate-x-[16px]'></div>
                  </div>
                  <hr className='border-dashed border-[2px] border-[#F8C954] translate-y-[-18px] opacity-60'/>
                  <div className='flex flex-col gap-6 p-4 justify-between'>
                    <div className='w-full p-3 border border-gray-300 rounded-lg flex flex-col gap-2'>
                      <div className='flex flex-col justify-between items-center gap-2'>
                        <div className='text-gray-500 font-medium'>Transaction ID</div>
                        <div className=' font-black truncate text-sm'>{orderStatus?.order_id.slice(6)}</div>
                      </div>
                      <hr/>
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-500 font-medium'>Date</div>
                        <div className=' font-black '>{formatDate(orderStatus?.created_at)}</div>
                      </div>
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-500 font-medium'>Time</div>
                        <div className=' font-black '>{formatTime(orderStatus?.created_at)}</div>
                      </div>
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-500 font-medium'>Status</div>
                        <div className=' font-black text-xs text-green-600 bg-green-200 rounded-2xl px-4 py-1'>{orderStatus?.order_status}</div>
                      </div>
                      <div className='flex justify-between items-center'>
                        <div className='text-gray-500 font-medium'>Sender</div>
                        <div className=' font-black '>{orderStatus?.customer_details?.customer_name}</div>
                      </div>
                    </div>
                    {/* <div className='w-full py-3 border border-gray-300 rounded-lg shadow-lg text-center text-sm font-semibold flex items-center justify-center gap-2 active:scale-95 transition-all'>
                      <img 
                        src='/assets/images/download.png'
                        className='h-5 w-5'
                      />
                      Get PDF Receipt
                    </div> */}
                  </div>
                </div>
              </div>
              <div className='w-full '>
                <div 
                  className='w-full rounded-lg bg-white font-semibold text-[#F8C954] text-center py-3'
                  onClick={() => navigate(Routes.class + `/${activeClassId}`,{replace: true})}
                >
                  Back to Home
                </div>
              </div>
            </div>
          </div>
          :
          <div className='h-[calc(100vh-144px)]'>
            <div className="flex flex-col gap-4 justify-center items-center h-full">
              <div className="w-32 h-32 relative">
                <div className="w-full h-full flex justify-center items-center rounded-full border-4 border-gray-300">
                  <div className="absolute w-5/4 h-1/4 bg-white rotate-animation"></div>
                  <h1 className="relative text-gray-500 font-raleway pulsate-animation text-2xl">₹</h1>
                </div>
              </div>
              <div className='font-semibold text-gray-600'>Processing payment...</div>
            </div>
          </div>
        }
    </Layout>
  )
}

export default PaymentStatusPage