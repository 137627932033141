import { AcademyListWrapper } from '../models/academy/academy-list-wrapper';
import ApiResponse from '../models/api/api-response';
import { AuthResponse } from '../models/user/auth-response';
import {invokeApi} from '../utils/helpers/invoke-api';


export class AcademyService {
    private static _instance: AcademyService;
    private constructor() {}

    public static get instance() {
        return this._instance ?? (this._instance = new AcademyService());
    }

    public getAllAcademiesToBeFeatured = async (): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?populate=images&filters[toBeFeatured]=true`,
            method: 'GET',
        });
    };
    public getAllAcademies = async (): Promise<ApiResponse<AcademyListWrapper>> => {
        return invokeApi<null, AcademyListWrapper>({
            route: `academies?fields[0]=name&populate[users][fields][0]=id`,
            method: 'GET',
        })
    }
}
