import React from 'react'
import { useNavigate } from 'react-router-dom';

type SquareCardProps = {
  title: string;
  description: string;
  path: string;
  count: number;
  icon: string;
  color: string;
};

const HomeOptionsCard: React.FC<SquareCardProps> = ({ title, path, description, count, icon, color }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(path);
  };

  return (
    <div 
      className={`h-[240px] w-full rounded-3xl p-4`} 
      style={{ backgroundColor: color }}
      onClick={handleClick}
    >
        <div className='text-xl font-semibold'>{title}</div>
        <div className='flex gap-4 mt-6 items-center'>
            <img 
                src={icon}
                alt="fitness"
                className='h-12 w-12'
            />
            <div className='text-5xl font-semibold'>{count}</div>
        </div>
        <div className='text-xs font-semibold mt-6'>{description}</div>
    </div>
  )
}

export default HomeOptionsCard