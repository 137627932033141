import LandingPageViewModel from '../../view-models/landing-page-view-model'
import AcademyCarousel from '../../components/landing-page-items/academy-carousal'
import Footer from '../../components/landing-page-items/footer'
import HeroSection from '../../components/landing-page-items/hero-section'
import Navbar from '../../components/landing-page-items/navbar'
import TestimonyCarousal from '../../components/landing-page-items/testimony-carousal'

import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react'
import SplashScreen from '../../components/landing-page-items/splash-screen'
import FullScreenLoader from '../../components/common/full-sreen-loader'
import PwaInstallPrompt from '../../components/common/pwa-install-prompt'



const Landingpage = () => {

    const {
        academiesToFeature,
        isAcademiesToFeatureFetching,
        handleNavigateToSignUpPage,
        handleSubscibeUser,
        isUserSubscribed,
        setSubscribeUserEmail,
        subscribeUserEmail,
        isUserAlreadySubscribed,
        isLoading,
        closedSubscribeSuccesPrompt,
        handlePromptClose
    } = LandingPageViewModel();

    return (
        <div className='w-full min-h-screen  overflow-x-hidden relative' >
            <Navbar/>
            <SplashScreen/>
            {isLoading && <FullScreenLoader/>}
            <div className='relative w-full h-[100vh] overflow-hidden'>
                <img src='./assets/icons/blue.svg' alt="blue"  className='absolute blue top-[-100%] left-[-100%]' />
                <div className='opacity-0 heading flex flex-col items-center relative top-[7rem] h-full  w-screen'>
                    <h1 className='sm:mt-0 mt-4 mb-4 sm:text-[60px] text-4xl w-full font-light sm:font-[300] sm:leading-[80px] font-[Manrope] sm:w-[34rem] text-center'>Find the Best  Activity for your Child!</h1>
                    <button className='bg-[#3AB7FC]  rounded-[50%] w-14 h-14 flex text-center items-center text-3xl justify-center text-white shadow-lg '> 
                        &gt;
                    </button>
                </div>
                <HeroSection/>
                <div className=' button relative text-[16px] font-[Manrope] font-[700] bottom-[-100%] flex items-center gap-14 justify-center w-screen '> 
                    <button className='bg-[#F6B40A] w-[15rem] h-[3rem] rounded-full shadow-xl  px-7 text-white cursor-pointer'><a className='cursor-pointer' href='https://academies.sparts.app/'>For Academies</a></button>
                    <button className='bg-[#F6B40A] w-[15rem] h-[3rem] rounded-full shadow-xl px-7 text-white sm:block hidden cursor-pointer' onClick={handleNavigateToSignUpPage}>Sign Up</button>
                </div>
                <img src='./assets/icons/yellow.svg' alt='yellow' className='absolute blob bottom-[-100%] right-[-100%]' />
            </div>
            <div className='academy relative bg-[#3AB7FC] h-screen w-full overflow-hidde p-10 py-[3rem]'>
                <h1 className='text-white academy-text font-[Manrope] sm:text-[66px] sm:w-[30rem] w-3/4 text-[35px] font-[700]'>Explore Our Top Academies</h1>
                <AcademyCarousel
                    academies={academiesToFeature?.data?.data}
                />
            </div>
            <div className='testimonials relative bg-[#ddd] h-screen sm:p-[4rem] p-4 sm:py-[5rem]'>
                <h1 className='testimomialtext opacity-0 text-right sm:w-full sm:text-[60px] text-[35px] font-[Manrope] font-[700] leading-[60px] text-[#F6B40A]'>Happy Kids,<br /> Satisfied Parents!</h1>
                <TestimonyCarousal/>
            </div>
            <div className='newsletter font-[Manrope] bg-[#F6B40A] h-[60vh] w-full flex text-white justify-center items-center'>
                <div className='news relative flex flex-col items-center justify-center p-2'>
                    <h1 className='font-[Manrope] sm:text-[60px] text-[35px] font-[700] leading-[60px]'>Stay Updated</h1>
                    <p className='mt-4 sm:text-[24px] text-[20px] sm:font-bold'>Get the latest updates on academies near you!</p>
                    <form 
                        className='mt-[4rem] flex sm:flex-row flex-col sm:gap-0 gap-4  justify-between items-center w-[90%]'
                        onSubmit={handleSubscibeUser}
                    >
                        <input 
                            type="email" 
                            required 
                            className='sm:w-[60%] w-full bg-[#D9D9D9] text-black p-5 rounded-xl outline-none shadow-lg' 
                            placeholder='Email'
                            value={subscribeUserEmail}
                            onChange={e => setSubscribeUserEmail(e.target.value)}
                            disabled={isUserSubscribed || isUserAlreadySubscribed}
                        />
                        <button 
                            type='submit' 
                            className='sm:w-[30%] w-[60%] bg-[#3AB7FC] sm:p-4 p-2 rounded-xl shadow-lg font-bold text-[20px] active:scale-95'
                            disabled={isUserSubscribed || isUserAlreadySubscribed}
                        >
                            {isUserSubscribed ? 'Subscribed' : isUserAlreadySubscribed ? 'Already Subscribed' : 'Subscribe'}
                        </button>
                    </form>
                </div>
            </div>
            <Footer/>
           
            <PwaInstallPrompt delay={8}/>

            {(isUserSubscribed && !closedSubscribeSuccesPrompt) &&
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: 100 }}
                    className='fixed bottom-0 left-0 top-0 h-full w-full right-0 z-[999999999999] flex items-center justify-center'
                >
                    <div className='sm:w-[480px] text-center w-[90vw] bg-white rounded-lg flex flex-col items-center justify-center shadow-2xl p-6 border-8 border-opacity-20 border-[#3AB7FC]'> 
                        <img 
                            src='/assets/images/logo.png'
                            className='p-2 h-20'
                        />
                        <div className='text-center font-semibold text-2xl'>Thank you for subscribing!</div>
                        <div className='text-center text-gray-600'>You have successfully subscribed to our list. We will let you know about our partner academies through your registered email.</div>
                        <div onClick={handlePromptClose} className='px-6 py-2 bg-[#3AB7FC] cursor-pointer rounded-lg text-white mt-4 text-[22px] font-semibold'>
                            Close
                        </div>
                    </div>
                </motion.div>
            }

        </div>
    )
}

export default Landingpage