import React, { useEffect, useState } from 'react';
import { isPWAInstalled } from '../../../../utils/helpers/helpers';
import { USER_STATE } from '../../../../utils/constants/globals';

const isSupported = () => 
    'Notification' in window && 
    'serviceWorker' in navigator && 
    'PushManager' in window;
const getNotificationPermission = () => {
    if (isSupported()) {
      return Notification.permission;
    }
    return 'not-determined';
};

const NotificationPermissionModal = () => {

  // Initialize state variables
  const [pwaInstalled, setPwaInstalled] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(getNotificationPermission());
  const [isModalOpen, setIsModalOpen] = useState(true);
  const isLoggedIn = !!localStorage.getItem(USER_STATE);

  // Check if PWA is installed on component mount
  useEffect(() => {
    setPwaInstalled(isPWAInstalled());
  }, []);

  // Request notification permission
  const requestNotificationPermission = async () => {
    if (isSupported()) {
      try {
        const permission = await Notification.requestPermission();
        setNotificationPermission(permission);
        if (permission !== 'granted') {
          throw new Error("Notification permission not granted");
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error);
      }
    } else {
      console.error('Notification API not supported in this browser.');
    }
  };

  // Render modal if conditions are met
  if (pwaInstalled && notificationPermission === 'default' && isModalOpen && isLoggedIn) {
    return (
      <div className="fixed inset-0 flex items-center justify-center z-[1000000000] p-2">
        <div className="absolute inset-0 bg-black opacity-90"></div>
        <div className="relative bg-white rounded-md p-4 z-10">
          <div className="text-xl text-[#ffc941] font-semibold">Allow Notifications to stay up to date.</div>
          <div className="flex justify-between mt-4 font-semibold text-xl">
            <button
              className="bg-[#F9CA54] text-white px-6 py-1 rounded active:scale-95 transition-all"
              onClick={() => {
                requestNotificationPermission();
                setIsModalOpen(false);
              }}
            >
              Allow
            </button>
            <button
              className="border-[3px] border-[#F9CA54] text-[#F9CA54] px-6 py-1 rounded active:scale-95 transition-all"
              onClick={() => {
                setIsModalOpen(false);
              }}
            >
              Deny
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default NotificationPermissionModal;
