import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { Routes } from '../routes';
import Landingpage from '../../pages/landing-page';
import SignIn from '../../pages/signIn-page';
import SignUp from '../../pages/signUp-page';
import TechniquePage from '../../pages/technique-page';
import ClassesPage from '../../pages/classes-page';
import AssignmentPage from '../../pages/assignment-page';
import EvaluationPage from '../../pages/evaluation-page';
import TransactionsPage from '../../pages/transactions-page';
import NotificationsPage from '../../pages/notifications-page';
import ErrorPage from '../../components/common/error-page';
import AuthWrapper from '../../components/common/auth-wrapper';
import AttendancePage from '../../pages/attendance-page';
import SettingsPage from '../../pages/settings-page';
import ProfileClaimPage from '../../pages/profile-claim-page';
import FitnessPage from '../../pages/fitness-page';
import ForgotPasswordPage from '../../pages/forgot-password';
import ResetPasswordPage from '../../pages/reset-password-page';
import ContactUs from '../../pages/contact-us-page';
import PrivacyPolicyPage from '../../pages/privacy-policy-page';
import Aboutpage from '../../pages/about-us-page';
import CookiePolicy from '../../pages/cookie-policy-page';
import TermsOfUse from '../../pages/terms-conditions-page';
import EnrolmentFormPage from '../../pages/enrolment-form-page';
import ComingSoonPage from '../../pages/comingSoon-page';
import FeesPaymentPage from '../../pages/fees-payment-page';
import PaymentPage from '../../pages/payment-page';
import PaymentStatusPage from '../../pages/payment-status-page';
import TechniqueTryPage from '../../pages/technique-try-page';
import StudentEvalResults from '../../pages/studentEvalResults';
import SelectProfilePage from '../../pages/select-profile-page';
import ProfilePage from '../../pages/profile-page';
import FitnessTestsPage from '../../pages/fitness-tests-page';

export const router = createBrowserRouter([
    {
        path: Routes.LandingPage,
        element: <Landingpage />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.Login,
        element: <SignIn />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.SignUp,
        element: <SignUp />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.forgotPassword,
        element: <ForgotPasswordPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.resetPassword,
        element: <ResetPasswordPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.SelectStudentProfile,
        element: (
            <AuthWrapper>
                <SelectProfilePage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.Profile,
        element: (
            <AuthWrapper>
                <ProfilePage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.profileClaim,
        element: (
            <AuthWrapper>
                <ProfileClaimPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.selectClass,
        element: (
            <AuthWrapper>
                <ClassesPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.class + '/:id',
        element: (
            <AuthWrapper>
                <TechniquePage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.assignments,
        element: (
            <AuthWrapper>
                <AssignmentPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.evals,
        element: (
            <AuthWrapper>
                <EvaluationPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
      path: Routes.evals +"/:id",
      element: (
          <AuthWrapper>
              <StudentEvalResults/>
          </AuthWrapper>
      ),
      errorElement: <ErrorPage />,
  },
    {
        path: Routes.payments,
        element: (
            <AuthWrapper>
                <TransactionsPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.notifications,
        element: (
            <AuthWrapper>
                <NotificationsPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.attendance,
        element: (
            <AuthWrapper>
                <AttendancePage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.settings,
        element: (
            <AuthWrapper>
                <SettingsPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.fitness,
        element: (
            <AuthWrapper>
                <FitnessPage />
            </AuthWrapper>
        ),
        errorElement: <ErrorPage />,
    },
    {
      path: Routes.fitnessTests,
      element: (
          <AuthWrapper>
              <FitnessTestsPage />
          </AuthWrapper>
      ),
      errorElement: <ErrorPage />,
    },
    {
        path: Routes.aboutUs,
        element: <Aboutpage />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.contactUs,
        element: <ContactUs />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.cookiePolicy,
        element: <CookiePolicy />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.privacyPolicy,
        element: <PrivacyPolicyPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: Routes.termsAndConditions,
        element: <TermsOfUse />,
        errorElement: <ErrorPage />,
    },
  {
    path: Routes.LandingPage,
    element: <Landingpage />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.Login,
    element: <SignIn />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.SignUp,
    element: <SignUp />,
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.forgotPassword,
    element: <ForgotPasswordPage/>,
    errorElement: <ErrorPage /> 
  },
  {
    path: Routes.resetPassword,
    element: <ResetPasswordPage/>,
    errorElement: <ErrorPage /> 
  },
  {
    path: Routes.SelectStudentProfile,
    element: (
      <AuthWrapper>
        <ProfilePage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.profileClaim,
    element: (
      <AuthWrapper>
        <ProfileClaimPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.selectClass,
    element: (
      <AuthWrapper>
        <ClassesPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.class + '/:id',
    element: (
      <AuthWrapper>
        <TechniquePage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.assignments,
    element: (
      <AuthWrapper>
        <AssignmentPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.evals,
    element: (
      <AuthWrapper>
        <EvaluationPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.payments,
    element: (
      <AuthWrapper>
        <TransactionsPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.notifications,
    element: (
      <AuthWrapper>
        <NotificationsPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.attendance,
    element: (
      <AuthWrapper>
        <AttendancePage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: Routes.settings,
    element: (
      <AuthWrapper>
        <SettingsPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: Routes.fitness,
    element: (
      <AuthWrapper>
        <FitnessPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />
  },
  {
    path: Routes.enrolment,
    element: (
      <AuthWrapper>
        <EnrolmentFormPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.commingSoon,
    element: (
      <AuthWrapper>
        <ComingSoonPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.feesPayment + '/:classId/:studentId',
    element: (
      <AuthWrapper>
        <FeesPaymentPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.technique + '/:techniqueId',
    element: (
      <AuthWrapper>
        <TechniqueTryPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.payment,
    element: (
      <AuthWrapper>
        <PaymentPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: Routes.paymentStatus + '/:orderId',
    element: (
      <AuthWrapper>
        <PaymentStatusPage />
      </AuthWrapper>
    ),
    errorElement: <ErrorPage />,
  },
]);
