import React from 'react'
import logo from "../../../assets/icons/link.svg"
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
const FooterStatic = () => {
    const navigate = useNavigate();
    return (
        <div>
            <footer className="flex gap-4 justify-between items-center p-4 pb-10 border-b mt-8 sm:flex-row flex-col">
                <div>
                    <img src={logo} alt="SpArts Logo" className="h-6" />
                </div>
                <div className="flex sm:gap-8 gap-2 justify-center items-center sm:text-sm text-xs sm:font-semibold">
                    <a
                        className="text-gray-600 hover:text-blue-500 cursor-pointer text-center"
                        onClick={() => navigate(Routes.aboutUs)}>
                        ABOUT US
                    </a>
                    <a
                        className="text-gray-600 hover:text-blue-500 cursor-pointer text-center"
                        onClick={() => navigate(Routes.contactUs)}>
                        CONTACT US
                    </a>
                    <a
                        className="text-gray-600 hover:text-blue-500 cursor-pointer text-center"
                        onClick={() => navigate(Routes.termsAndConditions)}>
                        TERMS AND CONDITIONS
                    </a>
                    <a
                        className="text-gray-600 hover:text-blue-500 cursor-pointer text-center"
                        onClick={() => navigate(Routes.privacyPolicy)}>
                        PRIVACY POLICY
                    </a>
                    <a
                        className="text-gray-600 hover:text-blue-500 cursor-pointer text-center"
                        onClick={() => navigate(Routes.cookiePolicy)}>
                        COOKIE POLICY
                    </a>
                </div>
            </footer>
            <div className="p-4 text-blue-200">
                © 2022 SpArts - All right reserved.
            </div>
        </div>
    );
};

export default FooterStatic;
