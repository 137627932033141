// ErrorPage.jsx
import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import ErrorPage1 from "../../../../assets/icons/ErrorPage1 copy.jpg"




const ErrorPage = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const imglist = [
    ErrorPage1
  ]

  function getRandomElement() {
    const randomIndex = Math.floor(Math.random() * imglist.length);
    return imglist[randomIndex];
  }

  // Type guard to check if the error is an instance of Error
  const isError = (err: unknown): err is Error => err instanceof Error;

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous route
  };

  return (
      <div role="alert">
          {/* <h2>Something went wrong:</h2>
      {isError(error) ? (
        <pre>{error.message}</pre>
      ) : (
        <pre>Unknown error occurred</pre>
      )} */}

          <div className="flex flex-col items-center sm:justify-center justify-evenly sm:gap-8 sm:max-h-[100vh] h-[100vh] overflow-hidden">
              <img
                  src={getRandomElement()}
                  className="max-h-[60vh]"
                  alt="error"
              />
              <div className='flex flex-col gap-8 justify-center text-center'>
                  <h1 className="sm:text-6xl sm:font-bold text-4xl font-normal">
                      Oops!
                  </h1>
                  <div className="flex flex-col items-center justify-center gap-2">
                      <div className="font-bold text-slate-700 text-lg">
                          Something went wrong
                      </div>
                      <div className="font-bold text-slate-700 text-lg">
                          Please try again
                      </div>
                  </div>
                  <div
                      className="bg-[#FFB721] text-white rounded-full p-4"
                      onClick={handleBackClick}>
                      TRY AGAIN
                  </div>
              </div>
          </div>
      </div>
  );
};

export default ErrorPage;
