import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Navigation, FreeMode, Autoplay } from 'swiper/modules';
import SlideUtility from '../../../../utils/slider-utils';
import { AcademyData } from '../../../../models/academy/academy-data';

interface FeaturingAcademiesProps {
  academies: AcademyData[] | undefined
}

const AcademyCarousel = ({
  academies,
}: FeaturingAcademiesProps) => {
  const [data, setData] = useState<AcademyData[]>([]);
  const [swiperInstance, setSwiperInstance] = useState<any>(null);

  const sliderUtility = new SlideUtility(data);

  const breakpoints = {
    768: {
        slidesPerView: 3, 
    },
    
    320: {
        slidesPerView: 1,
    },
  };
  
  return (
    <div className='carousel m-4 absolute overflow-hidden items-center w-full h-[60%]'>
      <Swiper
        modules={[Navigation, FreeMode, Autoplay]}
        spaceBetween={15}
        slidesPerView={4}
        autoFocus={true}
        breakpoints={breakpoints}
        freeMode={true}
        loop={true}
        className='w-[calc(100vw-5rem)] h-full p-6'
        onSlideChange={sliderUtility.handleSlideChange}
        onSwiper={setSwiperInstance}
      >
        {academies?.map((d: AcademyData, index: number) => {
          if (d && d.attributes && d.attributes.images && d.attributes?.images.data && d.attributes.images.data[0] && d.attributes.images.data[0].attributes) {
            const url = d.attributes.images.data[0].attributes.url
            return (
              <SwiperSlide key={index} className='flex items-center '>
                <div className='relative w-[90%] h-[80%]  bg-slate-300 rounded-lg shadow-lg transition-all' style={sliderUtility.calculateSlideSize(index + 1)}>
                  <div className='absolute inset-0 bg-cover bg-no-repeat bg-center rounded-lg' style={{ backgroundImage: `url(${url})` }}></div>
                  <div className='absolute inset-0 bg-black opacity-10 group-hover:opacity-50 rounded-lg' />
                </div>
              </SwiperSlide>
            );
          }
          return null;
        })}
        <div className='z-[999999] absolute right-20 top-[50%]'>
            <button 
              className={`shadow-sm bg-[#F6B40A] text-white w-[3rem] h-[3rem] text-2xl rounded-full flex items-center justify-center`} 
              onClick={()=>{
                if (swiperInstance) {
                  swiperInstance.slideNext();
                }
              }}
            >
              {'>'}
            </button>
        </div>
      </Swiper>
    </div>
  );
};

export default AcademyCarousel;
