import React, { useEffect, useState } from 'react';
// import Sidebar from '../../components/sidebar';
import logo from "../../../assets/icons/link.svg"
import { motion } from "framer-motion";
import { NoClassInfoTexts } from './data';
import noImage from "../../../assets/icons/noImage.png"
import ClassSelectPageViewModel from '../../view-models/class-select-viewmodel';
import Layout from '../../components/common/layout';

const ClassesPage = () => {
    const {
      isLoading,
      classesData,
      handleSelectClass,
      parentProfile
      
    } = ClassSelectPageViewModel();

    
    
    const [selectedProfileId, setSelectedProfileId] = useState<string>("");


    const cardContainerVarient = {
      hidden: { opacity: 0},
      show: {
        opacity: 1,
        transition: {
          staggerChildren: 0.25
        }
      }
    }

    const cardItemVarient = {
      hidden: { opacity: 0 },
      show: { opacity: 1 }
    }

    const randomIndex = Math.floor(Math.random() * NoClassInfoTexts.length);
    

    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className="">
                <div className="md:block hidden pt-8 pl-2">
                    <img src={logo} alt="link" />
                </div>
                {parentProfile?.activeStudent?.attributes?.classes?.data.length ===
                0 ? (
                    <div className="flex flex-col mt-20 items-center justify-center">
                        <h1 className="text-2xl font-bold">
                            {NoClassInfoTexts[randomIndex].title}
                        </h1>
                        <div>{NoClassInfoTexts[randomIndex].subTitle}</div>
                    </div>
                ) : (
                    <div className="md:text-center flex flex-col justify-center md:mt-28 mt-4">
                        <span className="md:text-5xl text-2xl font-light">
                            Your Classes
                        </span>
                        <motion.div
                            className="flex md:gap-8 gap-4 md:flex-row flex-col text-center justify-center mt-12  md:flex-wrap md:max-h-[75vh] md:overflow-y-scroll"
                            variants={cardContainerVarient}
                            initial="hidden"
                            animate="show">
                            {parentProfile?.activeStudent?.attributes?.classes?.data?.map(
                                classItem => 
                                  (
                                    <motion.div
                                        variants={cardItemVarient}
                                        className="cursor-pointer md:h-[330px] w-[full] h-[80px] bg-slate-200 rounded-xl flex md:flex-col flex-row gap-4 px-4 items-center justify-start md:justify-center md:w-80"
                                        key={classItem?.id}
                                        onClick={() =>
                                            handleSelectClass(classItem)
                                        }
                                        whileTap={{scale: 0.96}}>
                                        <div className="md:w-[190px] md:h-[190px] w-[60px] h-[60px] bg-white rounded-full overflow-hidden">
                                          <img src={classItem?.attributes?.academies?.data?.attributes?.images?.data !== null ? classItem?.attributes?.academies?.data?.attributes?.images?.data[0].attributes.url : ''} alt="" className='w-full h-full'/>
                                        </div>
                                        <div className="flex flex-col md:justify-center justify-start md:text-center text-left ">
                                            <span className="md:text-2xl">
                                              
                                                {
                                                    classItem?.attributes
                                                        ?.class_discipline?.data?.attributes?.name ?? ''
                                                }
                                            </span>
                                            <span className="">
                                                {
                                                    classItem?.attributes
                                                        ?.class_name ?? ''
                                                }
                                            </span>
                                        </div>
                                    </motion.div>
                                ),
                            )}
                        </motion.div>
                    </div>
                )}
            </div>
        </Layout>
    );
}

export default ClassesPage