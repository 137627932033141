const HeroSection = () => {
  return (
    <div className='flex heroimageoverflow-hidden absolute top-[50%] w-full sm:gap-10 sm:h-[16rem] h-[10rem] justify-center'>
        <img src='./assets/icons/teakwondo.svg' alt="taekwondo"className='taekwondo relative -auto sm:block hidden left-[-100%] opacity-0' />
        <img src='./assets/icons/violin.svg' alt="violin"className='violin relative md:left-auto top-[-100%] opacity-0' />
        <img src='./assets/icons/soccer.svg' alt="soccer" className='soccer relative md:left-auto  scale-0 opacity-0'/>
        <img src='./assets/icons/kick.svg' alt="kick" className='kick relative md:left-auto bottom-[-100%] opacity-0'/>
        <img src='./assets/icons/basketball.svg' alt="basketball"className='basketball md:left-auto relative sm:block hidden right-[-100%] opacity-0' />
    </div>
  )
}

export default HeroSection