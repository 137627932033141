import React, {useEffect, useState} from 'react';
import logo from '../../../assets/icons/link.svg';
import {motion} from 'framer-motion';
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import Layout from '../../components/common/layout';
import PwaInstallPrompt from '../../components/common/pwa-install-prompt';
import SelectProfilePageViewModel from '../../view-models/select-profile-page-view-model';

const SelectProfilePage = () => {
    const {
        isLoading, 
        studentsData, 
        handleSelectStudentProfile,
        enrollRequests,
        isClaimableProfilesAvailable,
        handleStudentViewClick,
    } = SelectProfilePageViewModel();

    const navigate = useNavigate();
    const cardContainerVarient = {
        hidden: {opacity: 0},
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.25,
            },
        },
    };
    const handleCreateNewClick = () => {
        navigate(Routes.enrolment);
    }

    const cardItemVarient = {
        hidden: {opacity: 0},
        show: {opacity: 1},
    };

    return (
        <Layout isProfileSwitchTabVisible={false}>
            {isLoading && <FullScreenLoader />}
            <div className="">
                <div className="md:block hidden pt-8 pl-2">
                    <img src={logo} alt="link" />
                </div>
                {studentsData?.length > 0 ? (
                    <div className="md:text-center h-full flex flex-col justify-center md:mt-6 mt-2 md:pl-0">
                        <span className="md:text-5xl text-2xl font-light">
                            Select Profile
                        </span>
                        <motion.div
                            className="flex md:gap-8 gap-4 md:flex-row flex-col text-center justify-center mt-12  md:flex-wrap md:max-h-[75vh] md:overflow-y-scroll pb-10"
                            variants={cardContainerVarient}
                            initial="hidden"
                            animate="show">
                            {studentsData?.map(student => (
                                <motion.div
                                    variants={cardItemVarient}
                                    className=" cursor-pointer md:h-[330px] w-[full] relative h-[80px] bg-slate-200 bg-gradient-to-r from-slate-300 rounded-xl overflow-hidden flex md:flex-col flex-row gap-4 px-4 items-center justify-between md:justify-center md:w-80"
                                    onClick={() =>
                                        handleSelectStudentProfile(student)
                                    }
                                    key={student?.id}
                                    whileTap={{scale: 0.96}}
                                >
                                    <div className='flex md:flex-col flex-row items-center justify-start gap-4'>
                                        <div className="md:w-[190px] md:h-[190px] w-[60px] h-[60px] bg-white rounded-full overflow-hidden">
                                            <img
                                                src={
                                                    student?.attributes?.photo?.data
                                                        ?.attributes?.url
                                                }
                                                className="h-full w-full rounded-full p-[2px] border-0"
                                            />
                                        </div>
                                        <div className="flex flex-col md:justify-center justify-start md:text-center text-left">
                                            <span className="md:text-2xl">{`${student?.attributes?.firstName} ${student?.attributes?.middleName ?? ''} ${student?.attributes?.lastName ?? ''}`}</span>
                                            <span className=""></span>
                                        </div>
                                    </div>
                                    <div className='mr-2 md:mr-0 md:absolute md:top-3 md:right-3'>
                                        <img
                                            src='/assets/images/eye.png'
                                            className='h-8 w-8 cursor-pointer active:scale-125 transition-all'
                                            alt='eye'
                                            onClick={(e) => handleStudentViewClick(e,student.id)}
                                        />
                                    </div>
                                </motion.div>
                            ))}
                        </motion.div>
                        <div className="flex-grow flex flex-col justify-end md:px-[300px] items-end w-full gap-2">
                            {enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length > 0 &&
                                <div className='md:px-12 px-4 my-2 w-full py-3 text-center rounded-lg border-4 text-gray-500 font-semibold border-orange-300 animate-pulse'>
                                    {`${enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length} 
                                    ${enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length === 1 ? 'profile is' : 'profiles are'} pending for approval from academy`}
                                </div>
                            }
                            {isClaimableProfilesAvailable &&
                                <div
                                    className="md:px-12 px-4 my-2 w-full py-3 bg-yellow-200 rounded-lg font-semibold cursor-pointer  text-center"
                                    onClick={() => navigate(Routes.profileClaim)}>
                                    Claim profile
                                </div>
                            }
                            <div
                                className="md:px-12 px-4 my-2 py-2 w-full text-white border-4 border-blue-300 rounded-lg font-semibold cursor-pointer text-center bg-blue-300"
                                onClick={handleCreateNewClick}>
                                Create new profile
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center flex-col">
                        <div className="flex flex-col justify-center font-semibold text-gray-600 pt-16 text-center gap-8">
                            <span className="md:text-4xl text-xl">
                                Looks like you haven't confirmed any student
                                profile
                            </span>
                            {/* <span className='md:text-2xl text-base'>
                                Click below to confirm linked accounts or create
                                a new profile!
                            </span> */}
                        </div>
                        <div className="md:flex flex-grow justify-between md:px-[300px] pt-20 items-center w-full gap-4">
                            {enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length > 0 &&
                                <div className='md:px-12 px-4 my-2 w-full py-3 text-center rounded-lg border-4 text-gray-500 font-semibold border-orange-300 animate-pulse'>
                                    {`${enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length} 
                                    ${enrollRequests?.filter(item => (item?.attributes?.status === 'FORM_SUBMITTED'))?.length === 1 ? 'profile is' : 'profiles are'} pending for approval from academy`}
                                </div>
                            }
                            {isClaimableProfilesAvailable &&
                                <div
                                    className="md:px-12 px-4 my-6 py-3 bg-yellow-200 rounded-lg font-semibold cursor-pointer  text-center"
                                    onClick={() => navigate(Routes.profileClaim)}>
                                    Claim profile
                                </div>
                            }
                            <div
                                className="md:px-12 px-4 my-6 py-2 text-white border-4 border-blue-300 rounded-lg font-semibold cursor-pointer text-center bg-blue-300"
                                onClick={handleCreateNewClick}>
                                Create new profile
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <PwaInstallPrompt delay={0} message='To stay updated on your enrollment status, install the SpArts app now'/>
        </Layout>
    );
};

export default SelectProfilePage;
