
import ApiResponse from "../models/api/api-response";
import { OwnerDetails } from "../models/utility/owner-details";
import { invokeApi } from "../utils/helpers/invoke-api";
import { ContactUsFormData } from "../utils/types/contact-us-form-data";


export class UtilityService {
    private static _instance: UtilityService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new UtilityService());
    }
    public getOwnerOfClass = async (classId: number): Promise<ApiResponse<OwnerDetails>> => {
        return invokeApi<null, OwnerDetails>({
            route: `get-owner/class/${classId}`,
            method: 'GET',
            privateRoute: true,
        });
    }

    public postContactUsSubmission = async (data: ContactUsFormData): Promise<ApiResponse<ContactUsFormData>> => {
        return invokeApi<{data: ContactUsFormData}, ContactUsFormData>({
            route: `contact-us-submissions`,
            method: 'POST',
            privateRoute: false,
            data: {data}
        });
    }

}