import ApiResponse from "../models/api/api-response";
import { FeePaymentListWrapper } from "../models/fee/fee-payment-list-wrapper";
import { invokeApi } from "../utils/helpers/invoke-api";

export class LandingPageService {
    private static _instance: LandingPageService;
    private constructor() {}
    
    public static get instance() {
        return this._instance ?? (this._instance = new LandingPageService());
    }
    public postNewSubscriber = async (data: {email: string}): Promise<ApiResponse<{data: {email: string}}>> => {
        return invokeApi<{data: {email: string}}, {data: {email: string}}>({
            route: `subscribed-emails`,
            method: 'POST',
            privateRoute: false,
            data: {data}
        });
    }
}