import React from 'react';
import logo from '../../../assets/icons/link.svg'
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';

const PrivacyPolicyPage: React.FC = () => {
    return (
        <div>
            <NavbarStatic />
            {/* <div className={style.container}> */}
            <div className="px-4">
                <header>
                    <h1 className="text-4xl font-semibold mb-4">
                        Privacy Policy
                    </h1>
                    <p>Last updated on July 21, 2023</p>
                </header>
                <main className="tracking-wide">
                    <section>
                        <p>
                            This Privacy Policy ("Policy") describes the
                            policies and procedures on the collection, use,
                            disclosure and protection of your information when
                            you use our website located at sparts.app, or the
                            SpArts mobile application (collectively, "SpArts
                            Platform") made available by Borsaikias Technologies
                            Private Limited ("SpArts", "Company", "we", "us" and
                            "our"), a private company established under the laws
                            of India having its registered office at H.no-16,
                            Krishnapur, DHarmeshwar Taro Path, Beltola,
                            Guwahati, Assam - 781028.
                        </p>
                        <p>
                            The terms "you" and "your" refer to the user of the
                            SpArts Platform. The term "Services" refers to any
                            services offered by SpArts whether on the SpArts
                            Platform or otherwise.
                        </p>
                        <br />
                        <p>
                            Please read this Policy before using the SpArts
                            Platform or submitting any personal information to
                            SpArts. This Policy is a part of and incorporated
                            within, and is to be read along with, the Terms of
                            Use.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-gray-700">YOUR CONSENT</h2>
                        <p>
                            By using the SpArts Platform and the Services, you
                            agree and consent to the collection, transfer, use,
                            storage, disclosure and sharing of your information
                            as described and collected by us in accordance with
                            this Policy. If you do not agree with the Policy,
                            please do not use or access the SpArts Platform.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-gray-700">POLICY CHANGES</h2>
                        <p>
                            We may occasionally update this Policy and such
                            changes will be posted on this page. If we make any
                            significant changes to this Policy we will endeavour
                            to provide you with reasonable notice of such
                            changes, such as via prominent notice on the SpArts
                            Platform or to your email address on record and
                            where required by applicable law, we will obtain
                            your consent. To the extent permitted under the
                            applicable law, your continued use of our Services
                            after we publish or send a notice about our changes
                            to this Policy shall constitute your consent to the
                            updated Policy.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-gray-700">
                            LINKS TO OTHER WEBSITES
                        </h2>
                        <p>
                            The SpArts Platform may contain links to other
                            websites. Any personal information about you
                            collected whilst visiting such websites is not
                            governed by this Policy. SpArts shall not be
                            responsible for and has no control over the
                            practices and content of any website accessed using
                            the links contained on the SpArts Platform. This
                            Policy shall not apply to any information you may
                            disclose to any of our service providers/service
                            personnel which we do not require you to disclose to
                            us or any of our service providers under this
                            Policy.
                        </p>
                    </section>
                    <section>
                        <h2 className="text-gray-700">
                            INFORMATION WE COLLECT FROM YOU
                        </h2>
                        <p>
                            Device Information: In order to improve your app
                            experience and lend stability to our services to
                            you, we may collect information or employ third
                            party plugins that collect information about the
                            devices you use to access our Services, including
                            the hardware models, operating systems and versions,
                            software, file names and versions, preferred
                            languages, unique device identifiers, advertising
                            identifiers, serial numbers, device motion
                            information, mobile network information, installed
                            applications on device and phone state. The
                            information collected thus will be disclosed to or
                            collected directly by these plugins and may be used
                            to improve the content and/or functionality of the
                            services offered to you. Analytics companies may use
                            mobile device IDs to track your usage of the SpArts
                            Platform;
                        </p>
                    </section>
                    <section>
                        <h2 className="text-gray-700">COOKIES</h2>
                        <p>
                            Our SpArts Platform and third parties with whom we
                            partner, may use cookies, pixel tags, web beacons,
                            mobile device IDs, "flash cookies" and similar files
                            or technologies to collect and store information
                            with respect to your use of the SpArts Platform and
                            third-party websites.
                        </p>
                        <p>
                            Cookies are small files that are stored on your
                            browser or device by websites, apps, online media
                            and advertisements. We use cookies and similar
                            technologies for purposes such as:
                        </p>

                        <div className="sm:pl-20 pl-12 pb-4">
                            <ul
                                style={{listStyleType: 'disc'}}
                                className="flex flex-col gap-2">
                                <li>Authenticating users;</li>
                                <li>
                                    Remembering user preferences and settings;
                                </li>
                                <li>Determining the popularity of content;</li>
                                <li>
                                    Delivering and measuring the effectiveness
                                    of advertising campaigns;
                                </li>
                                <li>
                                    Analysing site traffic and trends, and
                                    generally understanding the online
                                    behaviours and interests of people who
                                    interact with our services.
                                </li>
                            </ul>
                        </div>
                        <p>
                            A pixel tag (also called a web beacon or clear GIF)
                            is a tiny graphic with a unique identifier, embedded
                            invisibly on a webpage (or an online ad or email),
                            and is used to count or track things like activity
                            on a webpage or ad impressions or clicks, as well as
                            to access cookies stored on users’ computers. We use
                            pixel tags to measure the popularity of our various
                            pages, features and services. We also may include
                            web beacons in e-mail messages or newsletters to
                            determine whether the message has been opened and
                            for other analytics.
                        </p>
                        <p>
                            To modify your cookie settings, please visit your
                            browser’s settings. By using our Services with your
                            browser settings to accept cookies, you are
                            consenting to our use of cookies in the manner
                            described in this section. We may also allow third
                            parties to provide audience measurement and
                            analytics services for us, to serve advertisements
                            on our behalf across the Internet, and to track and
                            report on the performance of those advertisements.
                            These entities may use cookies, web beacons, SDKs
                            and other technologies to identify your device when
                            you visit the SpArts Platform and use our Services,
                            as well as when you visit other online sites and
                            services. Please see our{' '}
                            <span className="text-blue-600">Cookie Policy</span>{' '}
                            for more information regarding the use of cookies
                            and other technologies described in this section,
                            including regarding your choices relating to such
                            technologies.
                        </p>
                    </section>
                    <section>
                        <h2>USES OF YOUR INFORMATION</h2>
                        <p>
                            We use the information we collect for following
                            purposes, including:
                        </p>
                        <ul className="sm:pl-20 pl-12 flex flex-col gap-2">
                            <li>
                                1.To provide, personalise, maintain and improve
                                our products and services, such as to enable
                                deliveries and other services, enable features
                                to personalise your SpArts account;{' '}
                            </li>
                            <li>
                                2.To carry out our obligations arising from any
                                contracts entered into between you and us and to
                                provide you with the relevant information and
                                services;
                            </li>
                            <li>
                                3.To administer and enhance the security of our
                                SpArts Platform and for internal operations,
                                including troubleshooting, data analysis,
                                testing, research, statistical and survey
                                purposes;
                            </li>
                            <li>
                                4.To provide you with information about services
                                we consider similar to those that you are
                                already using, or have enquired about, or may
                                interest you. If you are a registered user, we
                                will contact you by electronic means (e-mail or
                                SMS or telephone or other internet based instant
                                messaging systems) with information about these
                                services;
                            </li>
                            <li>
                                5.To understand our users (what they do on our
                                Services, what features they like, how they use
                                them, etc.), improve the content and features of
                                our Services (such as by personalizing content
                                to your interests), process and complete your
                                transactions, make special offers, provide
                                customer support, process and respond to your
                                queries;
                            </li>
                            <li>
                                6.To generate and review reports and data about,
                                and to conduct research on, our user base and
                                Service usage patterns;
                            </li>
                            <li>
                                7.To allow you to participate in interactive
                                features of our Services, if any; or
                            </li>
                            <li>
                                8.To measure or understand the effectiveness of
                                advertising we serve to you and others, and to
                                deliver relevant advertising to you.
                            </li>
                            <li>
                                9.If you are a partner restaurant or merchant or
                                delivery partner, to track the progress of
                                delivery or status of the order placed by our
                                customers.
                            </li>
                            <li>
                                10.to carry out academic research with academic
                                partners.
                            </li>
                        </ul>

                        <p>
                            We may combine the information that we receive from
                            third parties with the information you give to us
                            and information we collect about you for the
                            purposes set out above. Further, we may anonymize
                            and/or de-identify information collected from you
                            through the Services or via other means, including
                            via the use of third-party web analytic tools. As a
                            result, our use and disclosure of aggregated and/or
                            de-identified information is not restricted by this
                            Policy, and it may be used and disclosed to others
                            without limitation. We analyse the log files of our
                            SpArts Platform that may contain Internet Protocol
                            (IP) addresses, browser type and language, Internet
                            service provider (ISP), referring, app crashes, page
                            viewed and exit websites and applications, operating
                            system, date/time stamp, and clickstream data. This
                            helps us to administer the website, to learn about
                            user behavior on the site, to improve our product
                            and services, and to gather demographic information
                            about our user base as a whole.
                        </p>
                    </section>
                    <section>
                        <h2>DISCLOSURE AND DISTRIBUTION OF YOUR INFORMATION</h2>
                        <p>
                            We may share your information that we collect for
                            following purposes:
                        </p>
                        <div className="sm:pl-20 pl-12">
                            <ul
                                style={{listStyleType: 'disc'}}
                                className="flex flex-col gap-2">
                                <li>
                                    With Service Providers: We may share your
                                    information with our vendors, consultants,
                                    marketing partners, research firms and other
                                    service providers or business partners, such
                                    as Payment processing companies, to support
                                    our business. For example, your information
                                    may be shared with outside vendors to send
                                    you emails and messages or push
                                    notifications to your devices in relation to
                                    our Services, to help us analyze and improve
                                    the use of our Services, to process and
                                    collect payments. We also may use vendors
                                    for other projects, such as conducting
                                    surveys or organizing sweepstakes for us.
                                </li>
                                <li>
                                    With Partner Restaurants/Merchant: While you
                                    place a request to order food through the
                                    SpArts Platform, your information is
                                    provided to us and to the
                                    restaurants/merchants with whom you may
                                    choose to order. In order to facilitate your
                                    online food order processing, we provide
                                    your information to that restaurant/merchant
                                    in a similar manner as if you had made a
                                    food order directly with the restaurant. If
                                    you provide a mobile phone number, SpArts
                                    may send you text messages regarding the
                                    order’s delivery status.
                                </li>
                                <li>
                                    With Academic Partners: We may share your
                                    information with our academic partners for
                                    the purpose of carrying out academic
                                    research.
                                </li>
                                <li>
                                    With Other Users: If you are a delivery
                                    partner, we may share your name, phone
                                    number and/or profile picture (if
                                    applicable), tracking details with other
                                    users to provide them the Services.{' '}
                                </li>
                                <li>
                                    For Crime Prevention or Investigation: We
                                    may share this information with governmental
                                    agencies or other companies assisting us,
                                    when we are:
                                </li>
                                <ul style={{listStyleType: 'disc'}}>
                                    <li>
                                        Obligated under the applicable laws or
                                        in good faith to respond to court orders
                                        and processes; or
                                    </li>
                                    <li>
                                        Detecting and preventing against actual
                                        or potential occurrence of identity
                                        theft, fraud, abuse of Services and
                                        other illegal acts;
                                    </li>
                                    <li>
                                        Responding to claims that an
                                        advertisement, posting or other content
                                        violates the intellectual property
                                        rights of a third party;
                                    </li>
                                    <li>
                                        Under a duty to disclose or share your
                                        personal data in order to enforce our
                                        Terms of Use and other agreements,
                                        policies or to protect the rights,
                                        property, or safety of the Company, our
                                        customers, or others, or in the event of
                                        a claim or dispute relating to your use
                                        of our Services. This includes
                                        exchanging information with other
                                        companies and organisations for the
                                        purposes of fraud detection and credit
                                        risk reduction.
                                    </li>
                                </ul>
                                <li>
                                    For Internal Use: We may share your
                                    information with any present or future
                                    member of our “Group” (as defined below)or
                                    affiliates for our internal business
                                    purposes The term “Group” means, with
                                    respect to any person, any entity that is
                                    controlled by such person, or any entity
                                    that controls such person, or any entity
                                    that is under common control with such
                                    person, whether directly or indirectly, or,
                                    in the case of a natural person, any
                                    Relative (as such term is defined in the
                                    Companies Act, 1956 and Companies Act, 2013
                                    to the extent applicable) of such person.
                                </li>
                                <li>
                                    With Advertisers and advertising networks:
                                    We may work with third parties such as
                                    network advertisers to serve advertisements
                                    on the SpArts Platform and on third-party
                                    websites or other media (e.g., social
                                    networking platforms). These third parties
                                    may use cookies, JavaScript, web beacons
                                    (including clear GIFs), Flash LSOs and other
                                    tracking technologies to measure the
                                    effectiveness of their ads and to
                                    personalize advertising content to you.{' '}
                                </li>
                                <li>
                                    While you cannot opt out of advertising on
                                    the SpArts Platform, you may opt out of much
                                    interest-based advertising on third party
                                    sites and through third party ad networks
                                    (including DoubleClick Ad Exchange, Facebook
                                    Audience Network and Google AdSense). For
                                    more information, visit
                                    www.aboutads.info/choices. Opting out means
                                    that you will no longer receive personalized
                                    ads by third parties ad networks from which
                                    you have opted out, which is based on your
                                    browsing information across multiple sites
                                    and online services. If you delete cookies
                                    or change devices, your opt out may no
                                    longer be effective.
                                </li>
                                <li>
                                    To fulfill the purpose for which you provide
                                    it.
                                </li>
                                <li>
                                    We may share your information other than as
                                    described in this Policy if we notify you
                                    and you consent to the sharing.
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="pb-10">
                        <h2>DATA SECURITY PRECAUTIONS</h2>
                        <p>
                            We have in place appropriate technical and security
                            measures to secure the information collected by us.
                        </p>
                        <p>
                            We use vault and tokenization services from third
                            party service providers to protect the sensitive
                            personal information provided by you. The
                            third-party service providers with respect to our
                            vault and tokenization services and our payment
                            gateway and payment processing are compliant with
                            the payment card industry standard (generally
                            referred to as PCI compliant service providers). You
                            are advised not to send your full credit/debit card
                            details through unencrypted electronic platforms.
                            Where we have given you (or where you have chosen) a
                            username and password which enables you to access
                            certain parts of the SpArts Platform, you are
                            responsible for keeping these details confidential.
                            We ask you not to share your password with anyone.
                        </p>
                        <p>
                            Please we aware that the transmission of information
                            via the internet is not completely secure. Although
                            we will do our best to protect your personal data,
                            we cannot guarantee the security of your data
                            transmitted through the SpArts Platform. Once we
                            have received your information, we will use strict
                            physical, electronic, and procedural safeguards to
                            try to prevent unauthorised access.
                        </p>
                    </section>
                </main>
            </div>
            <FooterStatic />
        </div>
    );
};

export default PrivacyPolicyPage;
