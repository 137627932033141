import React, {ChangeEvent, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IoChevronBack} from 'react-icons/io5';
import logo from '../../../assets/icons/link.svg';
import AttendancePageViewModel from '../../view-models/attendance-viewmodel';
import Calendar from 'react-calendar';
import Timeline from '../../components/common/horizontal-timeline';
import 'react-calendar/dist/Calendar.css';
import Layout from '../../components/common/layout';

const AttendancePage = () => {
    
    const navigate = useNavigate();

    const {
        student
    } = AttendancePageViewModel();

    

    const handleBackClick = () => {
        navigate(-1);
    };

    const [selectedFeeCycle,setSelectedFeeCycle] = useState<string>('')
    const [activeStartDate,setActiveStartDate] = useState<Date>()
    const [activeEndDate,setActiveEndDate] = useState<Date>()

    const handleFeeCycleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        if(e.target.value !== '' && e.target.value.split(',').length === 2){
            setSelectedFeeCycle(e.target.value)
            setActiveStartDate(new Date(e.target.value.split(',')[0]))
            setActiveEndDate(new Date(e.target.value.split(',')[1]))
        }
    }


    const getTotalClassesCountForCycle = () => {
        const sessions = student?.activeClassFeeData?.attributes?.sessions
        
        if(activeEndDate && activeStartDate){
            const millisecondsPerWeek = 1000 * 60 * 60 * 24 * 7;
    
            // Calculate the difference in milliseconds between the start and end dates
            const differenceInMilliseconds = activeEndDate?.getTime() - activeStartDate?.getTime();
    
            // Calculate the number of weeks between the start and end dates
            const numberOfWeeks = differenceInMilliseconds / millisecondsPerWeek;
    
            // Calculate the total number of classes
            const totalClasses = numberOfWeeks * sessions;
    
            return Math.ceil(totalClasses);
        }
        return 0;
    }

    const transformAttendanceData = (): Record<string, string> => {
        // Ensure that attendanceData is an array
        const attendanceData = Array.isArray(student?.activeClassAttendanceData) 
            ? [...student.activeClassAttendanceData] // Create a shallow copy of the array
            : [];
        
        return attendanceData.reverse().reduce((result, item) => {
            const attendanceDate = item.attributes?.attendance_date;
            if (!attendanceDate) {
                return result; // Skip if attendance_date is invalid
            }
    
            const formattedDate = new Date(attendanceDate).toISOString().split('T')[0];
    
            const status = item?.attributes.present
                ? 'present'
                : item.attributes.on_leave
                ? 'on_leave'
                : 'absent';
    
            return {
                ...result,
                [formattedDate]: status,
            };
        }, {});
    };
    

    const [formattedStudentAttendance, setFormattedStudentAttendance] = useState<Record<string, string>>(transformAttendanceData())
    
    useEffect(() => {
        setFormattedStudentAttendance(transformAttendanceData())
    },[student.activeClassAttendanceData,student.activeClassAttendanceData])

    const getDayName = (date: Date) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return days[date.getDay()];
    };

    const getDayClassName = ({ date, view }: { date: Date; view: string }) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        const isCurrentDay = date.toDateString() === new Date().toDateString();

        const status = formattedStudentAttendance[formattedDate];
        if (view === 'month' && isCurrentDay && status === undefined) {
            return '!text-black sm:!py-6 !bg-white rounded-lg !shadow-inner !shadow-gray-600 !relative'; 
        }else{
            switch (status) {
                case 'present':
                    return `!text-black !bg-green-300 rounded-lg sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                case 'absent':
                    return `!text-black !bg-red-100 rounded-lg sm:!py-6 !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
                default:
                    return `!text-black sm:!py-6 rounded-lg !relative ${isCurrentDay ? '!shadow-inner !shadow-black' : '!shadow-sm !shadow-gray-300'}`;
            }
        }
    };

    const tileContent = ({ date, view }: { date: Date; view: string }) => {
        if (view === 'month') {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const formattedDate = `${year}-${month}-${day}`;

            const status = formattedStudentAttendance[formattedDate];

            const isInClassTiming = student.activeClassData?.attributes?.class_timings?.some((timing) => timing.day === getDayName(date));

            if(isInClassTiming && status === 'on_leave'){
                return (
                    <>
                        <span className='p-1 rounded m-1 bg-red-100 text-white text-[8px] sm:text-xs'>L</span>
                        <div className='rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2' />
                    </>
                );
            } else if(isInClassTiming && status !== 'on_leave'){
                return (
                    <div className='rounded-full bg-yellow-400 h-2 w-2 sm:h-3 sm:w-3 absolute right-1 sm:right-2' />
                );
            }
        }
        return null;
    };


    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className="flex justify-start flex-col sm:pt-8">
                <div className="">
                    <div className="sm:block hidden">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
                <div className={`flex gap-8 sm:pl-3 sm:pt-4 pl-2`}>
                    <IoChevronBack
                        className="text-blue-500 text-3xl mt-3 sm:block hidden"
                        onClick={handleBackClick}
                    />

                    <span className="sm:text-4xl text-2xl font-light sm:mt-1">
                        Attendance
                    </span>
                </div>
                <div className="">
                    <div className="sm:px-20 sm:py-6 p-2 flex flex-col gap-8 mt-4">
                        <select
                            className="w-full text-center sm:text-left sm:w-1/2 h-[40px] bg-slate-200 rounded p-2"
                            value={selectedFeeCycle}
                            onChange={handleFeeCycleChange}>
                            <option value="">Select Fees Cycle</option>{' '}
                            {student?.activeClassFeeData?.attributes?.fee_dates?.map(
                                fee => (
                                    <option
                                        value={[
                                            new Date(
                                                fee.startDate,
                                            ).toISOString(),
                                            new Date(fee.dueDate).toISOString(),
                                        ]}
                                    >
                                        {new Date(fee.startDate)
                                            .toDateString()
                                            .substring(4)}{' '}
                                        -{' '}
                                        {new Date(fee.dueDate)
                                            .toDateString()
                                            .substring(4)}
                                    </option>
                                ),
                            )}
                        </select>
                    </div>
                    {selectedFeeCycle !== '' && 
                        <>
                            <div className="flex sm:gap-24 gap-4 sm:flex-row flex-col text-center justify-center mt-12 mb-6 p-2">
                                <div className="sm:w-[200px] sm:h-[250px] w-[full] h-[80px] bg-slate-200 rounded-xl flex sm:flex-col flex-row gap-4 px-4  items-center justify-start sm:justify-center">
                                    <div className=" sm:w-[120px] sm:h-[120px] w-[60px] h-[60px] rounded-full bg-white flex items-center justify-center text-gray-500 text-xl sm:text-4xl font-semibold">
                                        {getTotalClassesCountForCycle()}
                                    </div>
                                    <div className="flex flex-col sm:justify-center justify-start sm:text-center text-left ">
                                        {' '}
                                        <span className="sm:text-xl font-semibold">
                                            Total Classes
                                        </span>
                                    </div>
                                </div>

                                <div className="sm:w-[200px] sm:h-[250px] w-[full]  h-[80px] bg-slate-200 rounded-xl flex sm:flex-col flex-row gap-4 px-4  items-center justify-start sm:justify-center">
                                    <div className=" sm:w-[120px] sm:h-[120px] w-[60px] h-[60px] rounded-full bg-white flex items-center justify-center text-gray-500 text-xl sm:text-4xl font-semibold">
                                        { Array.isArray(student?.activeClassAttendanceData) ? student?.activeClassAttendanceData?.filter(item => new Date(item?.attributes?.attendance_date) >= new Date(activeStartDate ?? '') && new Date(item?.attributes?.attendance_date) <= new Date(activeEndDate ?? '')).length : 0}
                                    </div>
                                    <div className="flex flex-col sm:justify-center justify-start sm:text-center text-left ">
                                        {' '}
                                        <span className="sm:text-xl font-semibold">
                                            Attended
                                        </span>                            
                                    </div>
                                </div>

                                <div className="sm:w-[200px] sm:h-[250px] w-[full]  h-[80px] bg-slate-200 rounded-xl flex sm:flex-col flex-row gap-4 px-4  items-center justify-start sm:justify-center">
                                    <div className=" sm:w-[120px] sm:h-[120px] w-[60px] h-[60px] rounded-full bg-white flex items-center justify-center text-gray-500 text-xl sm:text-4xl font-semibold">
                                        {getTotalClassesCountForCycle() - (Array.isArray(student?.activeClassAttendanceData) ? student?.activeClassAttendanceData?.filter(item => new Date(item?.attributes?.attendance_date) >= new Date(activeStartDate ?? '') && new Date(item?.attributes?.attendance_date) <= new Date(activeEndDate ?? '')).length : 0)}
                                    </div>
                                    <div className="flex flex-col sm:justify-center justify-start sm:text-center text-left ">
                                        {' '}
                                        <span className="sm:text-xl font-semibold">
                                            Remaining classes
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <hr/>

                            {/* <div className="p-4">
                                <Timeline
                                    startDate={activeStartDate ?? new Date()}
                                    endDate={activeEndDate ?? new Date()}
                                    currentDate={new Date()}
                                    attendanceData={student?.activeClassAttendanceData}
                                    classData={student.activeClassData}
                                />
                            </div> */}

                            <hr/>
                            <div className="flex w-full items-center text-center mt-8 justify-center">
                                <Calendar 
                                    className="text-center shadow-lg gap-4 flex flex-col mb-8 !border-2 !border-gray-100 rounded-3xl sm:p-5 px-2 py-5 sm:mx-4 font-semibold !w-full !max-w-[800px]" 
                                    tileContent={tileContent}
                                    tileClassName={({date, view}) =>
                                        getDayClassName({date, view})
                                    }
                                />
                            </div>
                        </>
                    }
                </div>
            </div>
        </Layout>
    );
};

export default AttendancePage;
