import React from 'react'

const LoaderTillCamera = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="border-4 border-white border-opacity-30 rounded-full h-20 w-20 animate-spin"></div>
    </div>
  )
}

export default LoaderTillCamera