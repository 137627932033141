
// import logo  from "../assets/link.svg"

import { useState } from "react"
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../navigation/routes";
import { motion, AnimatePresence } from "framer-motion";

const NavbarItems = ({ isToggled }: { isToggled: boolean }) => {
    const navigate = useNavigate();
    const handleLoginClick = () => {
        
    };

    const items = [
        { label: "Login", link: () => navigate(Routes.Login) },
        { label: "Sign Up", link: () => navigate(Routes.SignUp) },
    ];
  
    const navList = {
      visible: {
        opacity: 1,
        transition: {
          delayChildren: 0.2,
          staggerChildren: 0.07
        }
      },
      hidden: {
        opacity: 0,
        transition: {
          staggerChildren: 0.05,
          staggerDirection: -1
        }
      }
    };
  
    const navItem = {
      visible: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      },
      hidden: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1000, velocity: -100 }
        }
      }
    };
  
    return (
      <>
        <motion.ul
          className="w-full p-4"
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={navList}
        >
          {items.map(item => (
            <motion.li className="text-white text-[34px] font-semibold cursor-pointer text-center" variants={navItem} key={item.label} onClick={item.link}>
              <p>{item.label}</p>
            </motion.li>
          ))}
        </motion.ul>
      </>
    );
  };

const Navbar = () => {

  const [isNavOpen, setIsNavOpen] = useState(false);

  const navContainer = {
    visible: {
      //x: 0,
      opacity: 1,
      transition: {
        x: { velocity: 100 },
        duration: 0.3
      }
    },
    hidden: {
      //x: -250,
      opacity: 0,
      transition: {
        x: { velocity: 100 },
        duration: 0.3
      }
    }
  };

  const handleToggleClick = () => {
    setIsNavOpen(prev => !prev);
  }
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate(Routes.Login)
  };

  return (
    <>
        <div className="nav bg-[#F6B40AB2] flex justify-between items-center fixed top-0 w-full h-16 sm:h-18 p-2 sm:px-10 z-10 backdrop-blur-lg shadow-md">
            <div className="w-[30%] sm:w-[35%]">
                <img src="./assets/icons/link.svg" alt="link" />
            </div>
            <div className="font-[Manrope] font-bold text-white sm:text-xl text-base leading-5 sm:flex hidden justify-between align-middle sm:gap-10">
                <span onClick={handleLoginClick} className="cursor-pointer">Login</span>
            </div>
            <div
                className="sm:hidden flex flex-col gap-1.5 px-2"
                onClick={handleToggleClick}
            >
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
                <div className=" w-8 z-100 border-b-2 border-black "></div>
            </div> 
        </div>
        <AnimatePresence>
                {isNavOpen && (
                    <motion.div
                        className="w-full h-[calc(100vh-64px)] fixed bg-[#F6B40A] top-[64px] left-0 z-10 flex flex-col items-center justify-center"
                        initial="hidden"
                        animate={isNavOpen ? "visible" : "hidden"}
                        exit="hidden"
                        variants={navContainer}
                    >
                        <NavbarItems isToggled={isNavOpen} />
                    </motion.div>
                )}
            </AnimatePresence>
    </>
  );
}

export default Navbar