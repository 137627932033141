/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import urls from '../../../../assets/demo.mp4'

interface props  {
    capturing: boolean;
    recordingPaused: boolean;
    setCapturing:(value: boolean) => void;
    handlePauseCaptureClick:() => void;
    setRecordingPaused:(value: boolean) => void;
    setIsKeyFrame:(value: boolean) => void;
    setCurrentKeyframe:(value: number) => void;
    handleStopCaptureClick:() => void;
    url: string;
    fps: number;
    keyframes:  number[];
    start: boolean;
    setStart:(value: boolean) => void;
    setPlayed: (value: number) => void;
    height_new: string;
    startTime:number
    beginPause:boolean
}
interface State {
    url: string;
    pip: boolean;
    playing: boolean;
    controls: boolean;
    light: boolean;
    volume: number;
    muted: boolean;
    played: number;
    loaded: number;
    duration: number;
    playbackRate: number;
    loop: boolean;
    capturing: boolean;
    recordingPaused: boolean;
    frame: number;
    currentTime: number;
    isKeyFrame: boolean;
    timer: boolean;
    countdown: number;
    fps: number;
    pauseCheck: boolean;
    allowbypass: boolean;
    eleRemove: number[]; // Add the eleRemove property here
    seeking: boolean|null;
    
}
class VideoPlayer extends Component<props,State> {
    private player:ReactPlayer|null=null
    private interval: NodeJS.Timeout | null = null;
    state:State = {
        url: urls,
        pip: false,
        playing: false,
        controls: true,
        light: false,
        volume: 0.8,
        muted: false,
        played: 0,
        loaded: 0,
        duration: 0,
        playbackRate: 1.0,
        loop: false,
        capturing: false,
        recordingPaused: true,
        frame: 0,
        currentTime: 0,
        isKeyFrame: false,
        timer: false,
        countdown: 3,
        fps: 30,
        pauseCheck: false,
        allowbypass: false,
        eleRemove:[],
        seeking:null
    }

    load = (url: string) => {
        this.setState({
            url,
            played: 0,
            loaded: 0,
            pip: false
        })
    }

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing })
    }

    handleStop = () => {
        this.setState({ url: "", playing: false })
    }

    handleToggleControls = () => {
        const url = this.state.url
        this.setState({
            controls: !this.state.controls,
            url: ""
        }, () => this.load(url))
    }

    handleToggleLight = () => {
        this.setState({ light: !this.state.light })
    }

    handleToggleLoop = () => {
        this.setState({ loop: !this.state.loop })
    }

    handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ volume: parseFloat(e.target.value) })
    }

    handleToggleMuted = () => {
        this.setState({ muted: !this.state.muted })
    }

    handleSetPlaybackRate = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ playbackRate: parseFloat(e.target.value) })
    }

    handleOnPlaybackRateChange = (speed: number) => {
        this.setState({ playbackRate: parseFloat(speed.toFixed(2)) })
    }

    handleTogglePIP = () => {
        this.setState({ pip: !this.state.pip })
    }

    handlePlay = () => {

        this.setState({ playing: true })
    }

    handleEnablePIP = () => {
        this.setState({ pip: true })
    }

    handleDisablePIP = () => {
        this.setState({ pip: false })
    }

    handlePause = () => {
        this.setState({ playing: false })
    }

    handleSeekMouseDown = (e: React.MouseEvent<HTMLInputElement>) => {
        this.setState({ seeking: true })
    }

    handleSeekChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ played: parseFloat(e.target.value) });
    };

    handleSeekMouseUp = (e: React.MouseEvent<HTMLInputElement>) => {
        this.setState({ seeking: false })
        this.player?.seekTo(parseFloat(e.currentTarget.value));
    }

    handleProgress = (state: { playedSeconds: number }) => {
        this.setState({ currentTime: state.playedSeconds })
        console.log({"this.state.played":this.state.played})
        // console.log({"this.state.currentTime":this.state.currentTime})
        this.props.setPlayed(this.state.currentTime/this.state.duration)
        if (!this.state.seeking) {
            this.setState(prevState => ({
                ...prevState,
                currentTime: state.playedSeconds,
            }));
        }
    };

    handleEnded = () => {
        this.setState({ playing: this.state.loop })
    }

    handleDuration = (duration: number) => {
        this.setState({ duration });
        console.log(duration)
    };

    handleClickFullscreen = () => {
        screenfull.request(document.querySelector('.react-player') as HTMLElement)
    }

    renderLoadButton = (url: string, label: string) => {
        return (
            <button onClick={() => this.load(url)}>
                {label}
            </button>
        )
    }
    ref = (player: ReactPlayer | null) => {
        this.player = player;
    };

    removeElements = (restarray: number[]) => {
        const filteredArray =  restarray.filter(element => !this.state.eleRemove.includes(element));
        return filteredArray;
    }
    

    componentDidMount() {
        // console.log(this.interval)
        this.interval = setInterval(() => {
            if (this.props.capturing) {
                let currentFrameCount = Math.floor(this.state.currentTime * this.state.fps);
                let array = this.props.keyframes
                let [num1, ...restarray] = array;
                let numlst = restarray.pop();

                restarray = this.removeElements(restarray)
                this.props.setCurrentKeyframe(currentFrameCount)

                if (this.props.beginPause){
                    this.setState({ isKeyFrame: true });
                    this.handlePause()
                    this.props.handlePauseCaptureClick()
                    this.props.setIsKeyFrame(true)
                    this.props.setCurrentKeyframe(currentFrameCount)
                }

                

                if ((currentFrameCount === num1 || currentFrameCount === numlst) && this.props.capturing) {
                    console.log("((currentFrameCount === num1 || currentFrameCount === numlst) && this.props.capturing) ")
                    this.setState({ isKeyFrame: true });
                    this.handlePause()
                    this.props.handlePauseCaptureClick()
                    this.props.setIsKeyFrame(true)
                    this.props.setCurrentKeyframe(currentFrameCount)
                }

                // if (restarray.length !== 0){
                //     // console.log("restarray.length !== 0", restarray[restarray.length - 1])
                //     // console.log((restarray[0] <= currentFrameCount) && (currentFrameCount <= restarray[restarray.length - 1]))
                //     if ((restarray[0] <= currentFrameCount) && (currentFrameCount <= restarray[restarray.length - 1])){
                //         // console.log("restarray[0] < currentFrameCount && currentFrameCount < restarray[restarray.length - 1]")
                //         let target = 0 
                //         let targetidx:number|null = null
                //         for (let idx = 0; idx < restarray.length; idx++) {
                //             const f = restarray[idx];
                //             if (f <= currentFrameCount){
                //                 target = (currentFrameCount+1)/this.state.fps
                //                 targetidx = idx
                //             }
                //         }
                //         if(target!==0){
                //             const newEleRemove = [...this.state.eleRemove];
                //             newEleRemove.push(restarray[targetidx as number]);
                //             this.setState({ eleRemove: newEleRemove });
                //             if(this.player)this.player.seekTo(parseFloat(`${target}`))
                //             this.setState({ isKeyFrame: true });
                //             // this.handlePause()
                //             // this.props.handlePauseCaptureClick()
                //             this.props.setIsKeyFrame(true)
                //             this.props.setCurrentKeyframe(restarray[targetidx as number])
                //         }
                        
                //     }
                //     if (restarray.length === 1){
                //         // console.log("restarray.length === 1")
                //         if (restarray[0] <= currentFrameCount){
                //             // console.log("restarray[0] <= currentFrameCount")
                //             this.setState(prevState => ({
                //                 eleRemove: [...(prevState.eleRemove), restarray[0]]
                //             }));
                //             let target = (currentFrameCount+1)/this.state.fps
                //            if(this.player) this.player.seekTo(parseFloat(`${target}`))
                //             this.setState({ isKeyFrame: true });
                //             // this.handlePause()
                //             // this.props.handlePauseCaptureClick()
                //             this.props.setIsKeyFrame(true)
                //             this.props.setCurrentKeyframe(restarray[0])
                //         }
                //     }
                // }
                // else{
                //     console.log("restarray is 0")
                // }
            }
        }, 500 / this.state.fps);
    }

    componentDidUpdate() {
        const { capturing, recordingPaused } = this.props;
        if ((capturing !== this.state.capturing) || (recordingPaused !== this.state.recordingPaused)) {
            this.setState({ capturing: capturing })
            this.setState({ recordingPaused: recordingPaused })
            if (!capturing || recordingPaused) {
                this.handlePause()
            }
            if (capturing) {
                this.handlePlay()
            }
        }

        if (this.state.played === 0.85) {
            this.props.handleStopCaptureClick()
        }
    }

    private isMobile=(window.innerWidth<768)?true:false
    render() {
        const { url, playing } = this.state

        const {
            capturing,
            recordingPaused,

        } = this.props;

        if (this.props.url !== undefined && this.props.url !== "" && this.props.url !== this.state.url) {
            this.setState({ url: this.props.url });
        }
        if (this.props.fps !== undefined && this.props.fps !== 0 && this.props.fps !== this.state.fps) {
            this.setState({ fps: this.props.fps });
        }

        if (this.props.start === true) {
            this.setState({ allowbypass: true })
            this.player?.seekTo(this.props.startTime)
            this.props.setStart(false)
        }

        return (
            <div className='app'>
                <section className='section'>
                    <div className='player-wrapper flex items-center justify-center'>
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'

                            height={this.props.height_new}   //'38em'
                            width={"100%"}

                            url={url}
                            playing={playing}
                            volume={0}
                            muted={true}
                            controls={true}

                            onReady={() => console.log('onReady')}
                            onStart={() => console.log('onStart')}

                            onPlay={this.handlePlay}
                            onPause={this.handlePause}
                            onBuffer={() => console.log('onBuffer')}
                            onPlaybackRateChange={this.handleOnPlaybackRateChange}

                            onEnded={this.handleEnded}
                            onError={e => console.log('onError', e)}
                            onProgress={this.handleProgress}
                            onDuration={this.handleDuration}
                            // onPlaybackQualityChange={e => console.log('onPlaybackQualityChange', e)}
                        />
                    </div>
                </section>
            </div>
        )
    }
}

export default VideoPlayer
