import React, { useState } from 'react'
import SettingsPageViewModel from '../../view-models/settings-page-view-model'
import { motion } from "framer-motion";
import SliderToggle from '../../components/common/toggle-button';
import { handleLogout } from '../../../utils/helpers/helpers';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../navigation/routes';
import Layout from '../../components/common/layout';


const SettingsPage = () => {
    const {
        isLoading,
    } = SettingsPageViewModel();

    const [isNotificationPermited,setIsNotificationPermited] = useState(false)
    const setSelected = (val: boolean) => {
        setIsNotificationPermited(val)
    }

    const navigate = useNavigate();

    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className='flex h-app-view-mobile md:h-full justify-center'>
                <div className='py-4 flex flex-col justify-center h-full sm:w-[600px] w-full'>
                    {/* <div className='flex justify-between items-center sm:w-[600px] sm:m-6'>
                        <div className='font-semibold text-xl text-gray-700'>Allow Notifications</div>
                        <SliderToggle selected={isNotificationPermited} setSelected={setSelected} />
                    </div> */}
                    <div className='flex flex-1 justify-between items-end md:w-[600px]'>
                        <motion.div
                            className='inline-flex px-4 mb-20 py-2 bg-blue-400 rounded-lg items-center text-lg font-semibold text-white w-full justify-center'
                            onClick={() => {
                                handleLogout();
                                navigate(Routes.Login, {replace: true})
                            }}
                        >
                            <img
                                src='/assets/images/logout.png'
                                className='h-6 w-6'
                            />
                            <div>Logout</div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default SettingsPage




