import React from 'react';

interface InstrucionModalProps {
    show: boolean;
    message: React.ReactNode;
    onCancel: () => void;
}

const InstrucionModal: React.FC<InstrucionModalProps> = ({ show, message, onCancel }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[101]">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <p className="mb-4">{message}</p>
                <div className="flex justify-around">
                    <button
                        className="p-4 w-40 text-white font-bold bg-[#3AB7FC] rounded-xl"
                        onClick={onCancel}
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InstrucionModal;