import React from 'react';

interface ModalProps {
    show: boolean;
    message: string|React.ReactNode;
    onConfirm: () => void;
    onCancel: () => void;
}

const Modal: React.FC<ModalProps> = ({ show, message, onConfirm, onCancel }) => {
    if (!show) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[101]">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
                <p className="mb-4">{message}</p>
                <div className="flex justify-around">
                    <button
                        className="bg-red-600 text-white px-4 py-2 rounded-md"
                        onClick={onConfirm}
                    >
                        Yes
                    </button>
                    <button
                        className="bg-gray-600 text-white px-4 py-2 rounded-md"
                        onClick={onCancel}
                    >
                        No
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;