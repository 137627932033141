import {useSelector} from 'react-redux';
import {RootState} from '../../utils/redux/store';
import Webcam from 'react-webcam';

import {Camera} from '@mediapipe/camera_utils';
import {drawConnectors, drawLandmarks} from '@mediapipe/drawing_utils';
import {Pose, Results} from '@mediapipe/pose';
import * as mediapipePose from '@mediapipe/pose';

import {stopSpeech, t2s} from '../../utils/ai/t2s';

import {useMutation, useQuery} from 'react-query';
import {
    ApiResponse_here,
    FitnessService,
} from '../../services/fitness-services';
import {
    TechniqueScoresType,
    calculateLungeCount,
    calculatePlankTime,
    calculatePushUpCount,
    calculateSquatCount,
} from '../../utils/ai/poseScoreCalc';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Routes} from '../navigation/routes';

type TechniqueModeType =
    | 'Pushup'
    | 'Pause'
    | 'Squats'
    // | 'Pause'
    // | 'Lunges'
    // | 'Pause'
    // | 'Plank'
    | null;

const FitnessPageViewModel = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const webcamRef = useRef<Webcam>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [techniqueScores, setTechniqueScores] = useState<TechniqueScoresType>(
        {
            Pushup: 0,
            Squats: 0,
            Lunges: 0,
            Plank: 0,
        },
    );
    const [techniqueMode, setTechniqueMode] = useState<string | null>(null);
    const [currentLandMarks, setCurrentLandMarks] =
        useState<mediapipePose.NormalizedLandmarkList | null>(null);
    const techniqueList: TechniqueModeType[] = [
        'Pushup',
        'Pause',
        'Squats',
        // 'Pause',
        // 'Lunges',
        // 'Pause',
        // 'Plank',
    ];
    const [techniqueIndex, setTechniqueIndex] = useState<number | null>(null);
    const [pause, setPause] = useState<boolean>(false);
    const [isDown, setIsDown] = useState<boolean>(false);
    const [countdown, setCountdown] = useState<number | null>(null);
    const [onGoing, setOnGoing] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [showOverlay, setShowOverlay] = useState<boolean>(false);
    const RIGHT_KEYPOINTS = [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32];
    const [techniqueTiming, settechniqueTiming] = useState<number | null>(null);
    const [pauseTiming, setPauseTiming] = useState<number | null>(null);
    const totalPlankTimeRef = useRef(0);
    const [showRes, setShowRes] = useState(false);

    const navigate = useNavigate();

    const TechniqueTime = 45;
    const PauseTime = 20;
   



    const handelStop = () => {
        setShowModal(true);
        setShowOverlay(false);
    };

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         displayInstructionStop();
    //     }, 1000); // 2 seconds = 2000 milliseconds

    //     return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    // }, []);

    const confirmStop = () => {
        setTechniqueScores({
            Pushup: 0,
            Squats: 0,
            Lunges: 0,
            Plank: 0,
        });
        window.location.reload();
    };

    const cancelStop = () => {
        setShowModal(false);
    };
    const goHome = () => {
        navigate(Routes.SelectStudentProfile);
    };
    const cancelInstructionStop = () => {
        stopSpeech();
        setShowInstructions(false);
    };

    const displayWelcomeMessage = () => {
        setShowWelcomeModal(false);
        // t2s(
        //     'Hello, Welcome to the SpArts Exclusive AI module.',
        //     1
        // );
        // t2s(
        //     'Our all new SpArts Exclusive Exercise Module is designed specifically for students of SpArts partner academies!',
        //     1
        // );
        // t2s(
        //     'This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across SpArts.',
        //     1
        // );
    
        // t2s(
        //     'Get started today and take your skills to the next level!',
        //     1
        // );
    
        setTimeout(() => {
            setShowWelcomeModal(false);
            setShowInstructions(true); // Show instruction popup after welcome popup
        }, 45000); // Adjust the timeout duration as needed
    };
    

    
    const displayInstructionStop = () => {
        setShowInstructions(true);
        t2s(
            'Please follow the following instructions for a seemless experience',
            1,
        );
        t2s(
            '1. Ensure your body is positioned correctly, 2. Face towards your left and keep your body inbetween the two green lines.,',
            1,
        );
        t2s(
            '3. For every technique u will get 45 sec with 30 sec break in each technique, 4. Pause or stop the session using respective buttons.',
            1,
        );
        // t2s(
        //     '5. Pause or stop the session using respective buttons.',
        //     1,
        // );
        t2s(
             
            " 1.pushup  2. Squats/Sit Ups, . Now you are all set click start to begin", 1
        )
        setTimeout(() => {
            setShowInstructions(false);
        }, 41000); // 45 seconds = 45000 milliseconds
    };



    const startCountdown = () => {
        stopSpeech()
        setOnGoing(true);
        setPause(false);
        setCountdown(3);

        // Announce the start of the technique
        t2s(
            `Starting ${techniqueIndex ? techniqueList[techniqueIndex + 1] : techniqueList[0]} get ready,  Face towards your left while you do the excercise`,
            1,
        );

        // Wait for 2 seconds before starting the countdown
        setTimeout(() => {
            let count = 3;
            t2s(`${count}`, 1.5);
            
    
            // Announce each second of the countdown
            const interval = setInterval(() => {
                if(count-1>0){
                t2s(`${count-1}`, 1.5);}
                setCountdown(prev => {
                    
                    if (prev === 1) {
                        clearInterval(interval);
                        setCountdown(null);
                        handleStart();
                        return null
                    }
                    return prev !== null || prev=== 1 ? prev - 1 : null;
                });
    
                count -= 1;
            }, 1000);
        }, 5000);
    };

    const drawSlantedLines = (
        context: CanvasRenderingContext2D,
        width: number,
        height: number,
    ) => {
        // Clear the canvas
        // context.clearRect(0, 0, width, height);

        // Set line style
        context.strokeStyle = '#3EF57E';
        context.lineWidth = 2;

        // Calculate points
        const endX1 = width * 0.3;
        const endX2 = width * 0.7;
        const startY = height;
        // const startX1 = 0.1 * width;
        const startX1 = 0;
        const endY = height - height * 0.4;
        // const startX2 = width * 0.9;
        const startX2 = width;

        // Draw left slanted line
        context.beginPath();
        context.moveTo(startX1, startY);
        context.lineTo(endX1, endY);
        context.stroke();

        // Draw right slanted line
        context.beginPath();
        context.moveTo(startX2, startY);
        context.lineTo(endX2, endY);
        context.stroke();
    };

    const onResults = (results: Results) => {
        try{
        if (!webcamRef.current?.video || !canvasRef.current) {
            return;
        }
        const videoWidth = webcamRef.current.video.videoWidth;
        const videoHeight = webcamRef.current.video.videoHeight;

        canvasRef.current.width = videoWidth;
        canvasRef.current.height = videoHeight;

        const canvasElement = canvasRef.current;
        const canvasCtx = canvasElement.getContext('2d');
        if (canvasCtx == null) throw new Error('Could not get context');

        canvasCtx.save();
        canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);

        canvasCtx.drawImage(
            results.image,
            0,
            0,
            canvasElement.width,
            canvasElement.height,
        );

        setCurrentLandMarks(results.poseLandmarks);
        setLoading(false);

        drawConnectors(
            canvasCtx,
            results.poseLandmarks,
            mediapipePose.POSE_CONNECTIONS,
            {color: 'white', lineWidth: 2},
        );
        drawLandmarks(canvasCtx, results.poseLandmarks, {
            color: 'red',
            lineWidth: 2,
            radius: 3,
        });

        drawSlantedLines(canvasCtx, videoWidth, videoHeight);

        canvasCtx.restore();}
        catch{
            throw new Error("Model Loading Error try again..");
            
        }
    };

    useEffect(() => {
        const loadPoseModel = async () => {
            try {
                const userPose = new Pose({
                    locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.2/${file}`,
                });
    
                userPose.setOptions({
                    // maxNumFaces: 1,
                    minDetectionConfidence: 0.8,
                    minTrackingConfidence: 0.8,
                });
    
                userPose.onResults(onResults);
    
                if (webcamRef.current && webcamRef.current.video) {
                    const camera = new Camera(webcamRef.current.video, {
                        onFrame: async () => {
                            if (webcamRef.current && webcamRef.current.video) {
                                await userPose.send({ image: webcamRef.current.video });
                            }
                        },
                    });
                    camera.start();
                }
            } catch (error) {
                console.error("Error loading the model:", error);
                throw new Error("Bad network? Error loading the model, try again.");
            }
        };
    
        loadPoseModel();
    }, []);

    const [tehniqueDone, setTehniqueDone] = useState(false);
    const handleCountdown = () => {
        return new Promise<void>(resolve => { // Specify the Promise type as void
            setCountdown(3);
            const tecname =
                typeof techniqueIndex === 'number'
                    ? techniqueList[techniqueIndex + 1]
                    : techniqueList[0];
            if (tecname === 'Pause') {
                t2s('Take a break of 20 seconds', 1);
            } else {
                t2s(
                    `Starting ${typeof techniqueIndex === 'number' ? techniqueList[techniqueIndex + 1] : techniqueList[0]} get ready, Face towards your left while you do the exercise`,
                    1,
                );
            }
    
            setTimeout(() => {
                let count = 3;
                t2s(`${count}`, 1.5);
                
                // Announce each second of the countdown
                const interval = setInterval(() => {
                    if(count - 1 > 0) {
                        t2s(`${count - 1}`, 1.5);
                    }
                    setCountdown(prev => {
                        if (prev === 1) {
                            clearInterval(interval);
                            setCountdown(null);
                            resolve(); // No need to pass a value
                            return null;
                        }
                        return prev !== null || prev === 1 ? prev - 1 : null;
                    });
                    count -= 1;
                }, 1000);
            }, 5000);
        });
    };
    

    const handelNext = async () => {
        console.log('handelNext==>>');
        console.log({
            techniqueIndex: techniqueIndex,
            techniqueTiming: techniqueTiming,
            pauseTiming: pauseTiming,
            techniqueMode: techniqueMode,
        });
        if (typeof techniqueIndex === 'number') {
            console.log('if (techniqueIndex)');
            if (techniqueIndex + 1 < techniqueList.length) {
                console.log('Next technique');
                await handleCountdown();

                console.log('# countdown done');
                setTechniqueMode(techniqueList[techniqueIndex + 1]);
                setTechniqueIndex(techniqueIndex + 1);
                console.log(techniqueMode);
            } else {
                console.log('Done');
                setTehniqueDone(prev => (!prev ? true : false));
            }
            if (techniqueTiming) {
                setPauseTiming(PauseTime);
                settechniqueTiming(null);
            } else if (pauseTiming) {
                settechniqueTiming(TechniqueTime);
                setPauseTiming(null);
            }
        }
    };

    useEffect(() => {
        if (tehniqueDone) {
            t2s('Session Completed', 1);
            handleFitnessReportSubmit();
            setShowRes(true);
        }
    }, [tehniqueDone]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (techniqueTiming) {
                if (techniqueTiming === 1) {
                    handelNext();
                }
                // console.log({pause: pause});
                if (!pause) {
                    settechniqueTiming(prev => (prev ? prev - 1 : null));
                }
            } else if (pauseTiming) {
                if (pauseTiming === 1) {
                    handelNext();
                }
                console.log({pause: pause});
                if (!pause) {
                    setPauseTiming(prev => (prev ? prev - 1 : null));
                }
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [pause, techniqueTiming, pauseTiming]);

    const handleStart = () => {
        // setFormSubmitted(false)
        setPause(false);
        console.log('Start==>');
        if (typeof techniqueIndex !== 'number') {
            console.log("if (typeof techniqueIndex !== 'number') ");
            setTechniqueMode(techniqueList[0]);
            setTechniqueIndex(0);
            setPause(false);
            setOnGoing(true);
            settechniqueTiming(TechniqueTime);
        } else {
            if (techniqueList.length > techniqueIndex + 1) {
                console.log('if (techniqueList.length > techniqueIndex + 1)');
                setTechniqueMode(techniqueList[techniqueIndex + 1]);
                setTechniqueIndex(techniqueIndex + 1);
            } else {
                console.log(
                    'else if (techniqueList.length > techniqueIndex + 1)',
                );
                setTechniqueMode(null);
                setTechniqueIndex(null);
                setPause(true);
                console.log(' if (techniqueList.length > techniqueIndex + 1');
                setOnGoing(false);
            }
        }
    };

    useEffect(() => {
        // setLoading(false);
        if (!pause && onGoing) {
            const checkVisibility = () => {
                if (currentLandMarks) {
                    for (let i = 0; i < RIGHT_KEYPOINTS.length; i++) {
                        const index = RIGHT_KEYPOINTS[i];
                        const landmark = currentLandMarks[index];
                        if (
                            landmark &&
                            landmark.visibility &&
                            landmark.visibility < 0.5
                        ) {
                            return true;
                        }
                    }
                }
                return false;
            };

            const hasImproperVisibility = checkVisibility();

            setShowOverlay(hasImproperVisibility);

            if (
                techniqueIndex !== null &&
                techniqueMode &&
                currentLandMarks &&
                !hasImproperVisibility
            ) {
                if (techniqueMode === 'Pushup') {
                    console.log(' if (techniqueMode === Pushup)');
                    calculatePushUpCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Pushup
                    );
                } else if (techniqueMode === 'Squats') {
                    console.log(' if (techniqueMode === techniqueList[1])');
                    calculateSquatCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Squats
                    );
                    //pass
                } else if (techniqueMode === 'Lunges') {
                    console.log(' if (techniqueMode === techniqueList[2])');
                    calculateLungeCount(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        techniqueScores.Lunges
                    );
                    //pass
                } else if (techniqueMode === 'Plank') {
                    console.log(' if (techniqueMode === techniqueList[3])');
                    calculatePlankTime(
                        currentLandMarks,
                        isDown,
                        setIsDown,
                        setTechniqueScores,
                        totalPlankTimeRef,
                        techniqueTiming ?? 0
                    );
                    //pass
                }
            }
        }
    }, [
        pause,
        setPause,
        techniqueIndex,
        techniqueMode,
        currentLandMarks,
        isDown,
        setIsDown,
        techniqueScores,
        displayWelcomeMessage,
        displayInstructionStop,
    ]);

    const handelPause = () => {
        setPause(true);
        console.log('handelpause');
    };
    const handelContinue = () => {
        console.log('Continue');
        setPause(false);
        setOnGoing(true);
    };

    const student = useSelector((state: RootState) => state.activeProfile);

    // const {
    //     isSuccess: isAssignmentDetailsFetchSuccess,
    //     isLoading: isAssignmentDetailsFetching,
    //     data: assignmentData,
    // } = useQuery({
    //     queryKey: [""],
    //     queryFn: () =>
    //         FitnessService.instance.postFitnessData(
    //             student.activeStudent.id,
    //             techniqueScores.Pushup,
    //             techniqueScores.Plank,
    //             techniqueScores.Lunges,
    //             techniqueScores.Squats,
    //         ),
    //     refetchOnWindowFocus: false,
    //     enabled: true,
    // });

    const postFitnessReportData = async (data: {
        studentId: number;
        pushup: number;
        plank: number;
        lunge: number;
        squats: number;
    }): Promise<ApiResponse_here | undefined> => {
        const response = await FitnessService.instance.postFitnessData(
            data.studentId,
            data.pushup,
            data.plank,
            data.lunge,
            data.squats,
        );
        if (response.success) {
            return response.data;
        } else {
            throw new Error(response.error?.message);
        }
    };

    const {
        mutate: submitReport,
        isLoading: isNewReportLoading,
        isSuccess: isNewReportAdded,
    } = useMutation(postFitnessReportData, {
        onSuccess: data => {
            console.log('Added Fitness Report Successfully!');
            // setFormSubmitted(true);
            setTechniqueMode(null);
            setTechniqueIndex(null);
            setPause(true);
            console.log('onSuccess: data');
            // setOnGoing(false);
            setShowOverlay(false);
            alert('Session ended');
            setTehniqueDone(false);
            // setEnrolmentFormData(initialFormData);
        },
        onError: error => {
            alert('Failed to add Report. Please try again!');
            setTechniqueMode(null);
            setTechniqueIndex(null);
            setPause(true);
            console.log('onSuccess: data!');
            // setOnGoing(false);
            // setShowOverlay(false);
            alert('Session ended');
            setTehniqueDone(false);
        },
    });
    // useEffect(() => {
    //     const canvas = canvasRef.current;
    //     if (canvas) {
    //         const context = canvas.getContext('2d');

    //         // Set canvas dimensions
    //         canvas.width = canvas.offsetWidth;
    //         canvas.height = canvas.offsetHeight;

    //         // Function to draw slanted lines
    //         const drawLines = () => {
    //             const width = canvas.width;
    //             const height = canvas.height;

    //             if (context) {
    //                 // Clear the canvas
    //                 context.clearRect(0, 0, width, height);

    //                 // Set line style
    //                 context.strokeStyle = 'red';
    //                 context.lineWidth = 2;

    //                 // Calculate points
    //                 const startX1 = width * 0.3;
    //                 const startX2 = width * 0.7;
    //                 const startY = height;
    //                 const endX = width / 2;
    //                 const endY = 0;

    //                 // Draw left slanted line
    //                 context.beginPath();
    //                 context.moveTo(startX1, startY);
    //                 context.lineTo(endX, endY);
    //                 context.stroke();

    //                 // Draw right slanted line
    //                 context.beginPath();
    //                 context.moveTo(startX2, startY);
    //                 context.lineTo(endX, endY);
    //                 context.stroke();
    //             }
    //         };

    //         drawLines();
    //     }
    // }, []);

    const handleFitnessReportSubmit = () => {
        // setFormSubmitted(true)
        console.log(
            student.activeStudent.id,
            techniqueScores.Pushup,
            techniqueScores.Plank,
            techniqueScores.Lunges,
            techniqueScores.Squats,
        );
        submitReport({
            studentId: student.activeStudent.id,
            pushup: techniqueScores.Pushup,
            plank: techniqueScores.Plank,
            lunge: techniqueScores.Lunges,
            squats: techniqueScores.Squats,
        });
    };

    return {
        techniqueScores,
        loading,
        webcamRef,
        canvasRef,
        techniqueMode,
        displayWelcomeMessage,
        displayInstructionStop,
        showModal,
        confirmStop,
        cancelStop,
        showInstructions,
        onGoing,
        startCountdown,
        handelNext,
        pause,
        handelPause,
        handelContinue,
        handelStop,
        techniqueTiming,
        pauseTiming,
        showOverlay,
        countdown,
        cancelInstructionStop,
        showRes,
        goHome,
    };
};

export default FitnessPageViewModel;
