import React, { useMemo } from 'react'
import style from "./style.module.css"
import Select from 'react-select';
import EnrolmentPageViewmodel from '../../view-models/enrolment-viewmodel';
import Layout from '../../components/common/layout';
import FullScreenLoader from '../../components/common/full-sreen-loader';
import { BOARD_OPTIONS, GRADE_OPTIONS } from './data';
import DragCloseDrawer from '../../components/common/drag-close-drawer';
import { motion, AnimatePresence } from "framer-motion";
import ProfileClaimPage, { Gallery, SingleImage } from '../profile-claim-page';


const EnrolmentFormPage = () => {

     const {
        isLoading,
        handleEnrolmentDataInputChange,
        handleEnrolmentFormDetailsSubmit,
        mapClassesToOptions,
        mapAcademiesToOptions,
        enrolmentFormData,
        formSubmitted,
        handleGoBack,
        userState,
        handleFileChange,
        isImagePosting,
        isTermsAndConditionsOpen,
        setIsTermsAndConditionsOpen,
        academyId,
        claimableStudents,
        index,
        setIndex,
        selectedStudentsId,
        handleAddRemoveClaim,
        dobInput,
        setDobInput,
        handleClaimStudentSubmit,
        isClaimStudentModalClosed,
        setIsClaimStudentModalClosed,
     } = EnrolmentPageViewmodel();

     const renderForm  = useMemo(()=> {
        return !formSubmitted ? (
            <div className="sm:w-[90vw] w-full h-full px-4 border m-auto py-4 sm:px-8  bg-[#FFF8DB] rounded-2xl text-black overflow-y-auto">
                <div className="text-center">
                    <div className="font-semibold text-2xl  ">
                        Enrollment Form
                    </div>
                </div>

                <form action="" onSubmit={handleEnrolmentFormDetailsSubmit}>
                    <div className='flex sm:gap-8'>
                        <div className="w-full flex flex-col sm:flex-grow justify-between sm:justify-start sm:gap-4 mt-4">
                            <div className="flex flex-col w-full">
                                <label className={` font-semibold `}>
                                    Select Academy:{' '}
                                    {/* <span className="text-red-400">&#42;</span> */}
                                </label>
                                <Select
                                    options={mapAcademiesToOptions()}
                                    className="text-black mb-2 mt-1"
                                    classNamePrefix="Select Prefix"
                                    isDisabled={academyId ? true : false}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor:enrolmentFormData?.academy.length > 0 ? '#cccccc' : 'red',
                                        }),
                                    }}
                                    onChange={item => {
                                        handleEnrolmentDataInputChange('academy', [
                                            item?.value ?? 0,
                                        ]);
                                    }}
                                    value={mapAcademiesToOptions().filter(option =>
                                        enrolmentFormData?.academy.includes(
                                            option.value,
                                        ),
                                    )}
                                />
                            </div>
                            <div className="flex flex-col w-full">
                                <label className={` font-semibold`}>
                                    Select Classes:{' '}
                                    {/* <span className="text-red-400"></span> */}
                                </label>
                                <Select
                                    isMulti
                                    options={mapClassesToOptions()}
                                    className="text-black mt-1"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor:enrolmentFormData?.classes.length > 0 ? '#cccccc' : 'red',
                                        }),
                                    }}
                                    classNamePrefix="Select Prefix"
                                    onChange={item => {
                                        handleEnrolmentDataInputChange(
                                            'classes',
                                            item.map(({value}) => value),
                                        );
                                    }}
                                    value={mapClassesToOptions().filter(option =>
                                        enrolmentFormData.classes?.includes(
                                            option.value,
                                        ),
                                    )}
                                />
                            </div>
                        </div>
                        <div className='w-full sm:w-[380px] hidden sm:flex flex-col items-center justify-center p-2'>
                            <div className={style.formImageInputBtnWrapper}>
                                {
                                    isImagePosting ? (
                                        <div className='h-full w-full object-contain flex items-center justify-center'>
                                            <div className='w-8 h-8 border-t-4 border-blue-500 border-solid border-opacity-50 rounded-full animate-spin items-center'></div>
                                        </div>
                                    ) : enrolmentFormData?.imageUrl ? (
                                        <img
                                            src={enrolmentFormData.imageUrl}
                                            className='h-full w-full object-contain'
                                        />
                                    ) : (
                                        <div className='h-full w-full object-contain flex items-center justify-center'>
                                            <div className='text-slate-400 text-sm font-semibold justify-center rounded-lg'>No Image Uploaded</div>
                                        </div>
                                    )
                                }
                            </div>
                            <div className='text-xs text-gray-400 my-1'>Max file size: 10MB</div>
                            <label htmlFor="fileInput" className="relative cursor-pointer flex items-center w-full h-12 justify-center">
                                <input
                                    type="file"
                                    id="fileInput"
                                    className="opacity-0 absolute w-full h-full inset-0"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    disabled={isImagePosting}
                                />
                                <div className="p-2 bg-blue-400 cursor-pointer text-white w-full rounded text-center font-semibold">
                                    <div>Upload student image</div>
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="mt-4 text-base font-bold">
                        {' '}
                        Student Details
                    </div>
                    <div className='w-full sm:hidden flex flex-col items-center justify-center p-2'>
                        <div className={style.formImageInputBtnWrapper}>
                            {
                                isImagePosting ? (
                                    <div className='h-full w-full object-contain flex items-center justify-center'>
                                        <div className='w-8 h-8 border-t-4 border-blue-500 border-solid border-opacity-50 rounded-full animate-spin items-center'></div>
                                    </div>
                                ) : enrolmentFormData?.imageUrl ? (
                                    <img
                                        src={enrolmentFormData.imageUrl}
                                        className='h-full w-full object-contain'
                                    />
                                ) : (
                                    <div className='h-full w-full object-contain flex items-center justify-center'>
                                        <div className='text-slate-400 text-sm font-semibold justify-center rounded-lg'>No Image Uploaded</div>
                                    </div>
                                )
                            }
                        </div>
                        <div className='text-xs text-gray-400 my-1'>Max file size: 10MB</div>
                        <label htmlFor="fileInput" className="relative cursor-pointer flex items-center w-full h-12 justify-center">
                            <input
                                type="file"
                                id="fileInput"
                                className="opacity-0 absolute w-full h-full inset-0"
                                accept="image/*"
                                onChange={handleFileChange}
                                disabled={isImagePosting}
                            />
                            <div className="p-2 bg-blue-400 cursor-pointer text-white w-full rounded text-center font-semibold">
                                <div>Upload student image</div>
                            </div>
                        </label>
                    </div>
                    <div className="w-full flex sm:flex-row flex-col justify-between gap-4 mt-2">
                        <input
                            required
                            type="text"
                            className={`${style.formInput} ${enrolmentFormData.firstName ? '' : '!border-red-400'}`}
                            placeholder="First Name"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'firstName',
                                    e.target.value,
                                );
                            }}
                        />

                        <input
                            type="text"
                            className={style.formInput}
                            placeholder="Middle Name"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'middleName',
                                    e.target.value,
                                );
                            }}
                        />

                        <input
                            type="text"
                            className={style.formInput}
                            placeholder="Last Name"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'lastName',
                                    e.target.value,
                                );
                            }}
                        />
                    </div>

                    <div className="w-full flex sm:flex-row flex-col justify-between gap-4 sm:mt-2 mt-4">
                        <div className="sm:w-1/3 sm:pr-3">
                            <select
                                required
                                className={`${style.formInput} w-1/3 ${enrolmentFormData.gender ? '' : '!border-red-400'}`}
                                value={enrolmentFormData.gender}
                                onChange={e =>
                                    handleEnrolmentDataInputChange(
                                        'gender',
                                        e.target.value,
                                    )
                                }>
                                <option value="">Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                            </select>
                        </div>
                        <div className="sm:w-1/3 sm:pl-3">
                            <div className={`bg-white w-full inline-flex p-3 gap-2 border rounded-lg ${enrolmentFormData.dob ? '' : '!border-red-400'}`}>
                                <label htmlFor='dob' className='text-gray-500 text-sm'>Date of birth</label>
                                <input
                                    required
                                    id='dob'
                                    type="date"
                                    className={`${enrolmentFormData.dob ? '' : 'text-opacity-0' } text-right text-sm focus:outline-none text-gray-500 resize-none flex-grow border-gray-500 border-opacity-30 rounded bg-white disabled:bg-slate-100 sm:max-h-20`}
                                    value={enrolmentFormData.dob}
                                    onChange={e =>
                                        handleEnrolmentDataInputChange(
                                            'dob',
                                            e.target.value,
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="w-full flex sm:flex-row flex-col justify-between sm:gap-2 gap-4 sm:mt-2 mt-4">
                        <div className="sm:w-1/3 sm:pr-2">
                            <select
                                className={style.formInput}
                                value={enrolmentFormData.bloodGroup}
                                onChange={e =>
                                    handleEnrolmentDataInputChange(
                                        'bloodGroup',
                                        e.target.value,
                                    )
                                }>
                                <option value="">Blood group</option>
                                <option value="A+">A+</option>
                                <option value="A-">A-</option>
                                <option value="B+">B+</option>
                                <option value="B-">B-</option>
                                <option value="O+">O+</option>
                                <option value="O-">O-</option>
                                <option value="AB+">AB+</option>
                                <option value="AB-">AB-</option>
                            </select>
                        </div>
                        <div className="sm:w-2/3">
                            <textarea
                                placeholder="Medical Conditions if any"
                                className={style.formInput}
                                onChange={e => {
                                    handleEnrolmentDataInputChange(
                                        'medicalConditions',
                                        e.target.value,
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex justify-between gap-4 sm:mt-2 mt-4">
                        <textarea
                            required
                            className={`${style.formInput} ${enrolmentFormData.address ? '' : '!border-red-400'}`}
                            placeholder="Address"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'address',
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className='flex flex-col sm:flex-row gap-2 items-center mt-2'>
                        <div className="w-full flex justify-between gap-4 sm:mt-2 mt-4">
                            <input 
                                type="text"
                                placeholder='School Name'
                                className={style.formInput}
                                onChange={e => handleEnrolmentDataInputChange('school', e.target.value)}
                                value={enrolmentFormData.school}
                            />
                        </div>
                        <div className="w-full flex justify-between gap-4 sm:mt-2 mt-4">
                            <select
                                className={style.formInput}
                                onChange={e => handleEnrolmentDataInputChange('grade', e.target.value)}
                                value={enrolmentFormData.grade}
                            >
                                <option value={''} className='bg-slate-300'>Select the Grade</option>
                                {GRADE_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-full flex justify-between gap-4 sm:mt-2 mt-4">
                            <select
                                className={style.formInput}
                                onChange={e => handleEnrolmentDataInputChange('board', e.target.value)}
                                value={enrolmentFormData.board}
                            >
                                <option className='bg-slate-300' value={''}>Select the Board</option>
                                {BOARD_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="mt-4 text-base font-bold">
                        {' '}
                        Emergency Contact Details
                    </div>
                    <div className="w-full flex sm:flex-row flex-col justify-between gap-4 mt-4">
                        <input
                            required
                            type="text"
                            className={`${style.formInput} ${enrolmentFormData.relationName ? '' : '!border-red-400'}`}
                            placeholder="Relation 1 Name"
                            value={enrolmentFormData.relationName}
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'relationName',
                                    e.target.value,
                                );
                            }}
                        />

                        <input
                            required
                            type="number"
                            className={`${style.formInput} ${enrolmentFormData.relationNumber ? '' : '!border-red-400'}`}
                            value={enrolmentFormData.relationNumber}
                            placeholder="Relation 1 Contact Number"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'relationNumber',
                                    e.target.value,
                                );
                            }}
                        />

                        <input
                            disabled={true}
                            required
                            type="email"
                            value={enrolmentFormData.relationEmail}
                            className={style.formInput}
                            placeholder="Relation 1 Email Id&#42;"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'relationEmail',
                                    e.target.value,
                                );
                            }}
                        />
                    </div>
                    <div className="w-full flex sm:flex-row flex-col justify-between sm:gap-4 sm:mt-2 mt-4">
                        <div className="sm:w-1/3 sm:pr-3 w-full">
                            <input
                                type="text"
                                className={style.formInput}
                                placeholder="Relation with student"
                                onChange={e => {
                                    handleEnrolmentDataInputChange(
                                        'relationWithStudent',
                                        e.target.value,
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-between sm:gap-1 sm:mt-4 mt-4">
                        <div className='font-semibold'>Remarks</div>
                        <textarea
                            name="remarks"
                            id=""
                            placeholder="Remarks"
                            onChange={e => {
                                handleEnrolmentDataInputChange(
                                    'remarks',
                                    e.target.value,
                                );
                            }}
                            className={style.formInput}></textarea>
                    </div>
                    <div className='flex gap-2 mt-4 justify-center items-center'>
                        <input 
                            className='!rounded-full'
                            type='checkbox'
                            required
                        />
                        <div>I agree to the <span className='text-blue-500 cursor-pointer' onClick={() => setIsTermsAndConditionsOpen(true)}>terms and conditions</span></div>
                    </div>

                    <button
                        type="submit"
                        className="bg-orange-500 text-white font-semibold py-2 w-full rounded mt-10"
                    >
                        Enroll
                    </button>
                </form>
            </div>
        ) : (
            <>
                <div className="  w-[100vw] sm:h-[100vh] h-[80vh] flex flex-col justify-center items-center gap-3">
                    <span className="text-5xl text-black font-semibold">
                        Form Submitted
                    </span>
                    <span className="text-2xl text-black">Thank you</span>
                    <button
                        onClick={handleGoBack}
                        className="px-4 my-6 py-3 bg-yellow-200 rounded-lg font-semibold cursor-pointer">
                        Go Back
                    </button>
                </div>
            </>
        );}
    ,[formSubmitted,mapAcademiesToOptions,mapClassesToOptions, enrolmentFormData,isImagePosting])

  return (
      <Layout isProfileSwitchTabVisible={false} hideMobileBottomBar={claimableStudents.length > 0}>
        {isLoading ? <FullScreenLoader /> :
          <div className="flex m-auto sm:my-4 justify-center w-full sm:h-[96vh] h-full items-center">
              {renderForm}
          </div>
        }
        {isTermsAndConditionsOpen && 
            <DragCloseDrawer
                open={isTermsAndConditionsOpen}
                setOpen={setIsTermsAndConditionsOpen}
                background='white'
            >
                <iframe
                    src={`${window.location.origin}/terms-conditions`}
                    style={{ width: '100%', height: '100%', border: 'none' }}
                    title="Embedded Route"
                />
            </DragCloseDrawer>
        }
        {(claimableStudents.length > 0 && !isClaimStudentModalClosed) && 
            <div className='fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[1000] bg-black bg-opacity-50'>
                <motion.div
                    className='h-[90%] w-[95%] rounded-2xl bg-white p-4 relative flex flex-col'
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{ duration: 0.3 }}
                >
                    <div className='text-2xl text-gray-500 font-semibold mb-4'>Found existing profiles for this academy</div>
                    <hr className='border-gray-300 my-2'/>
                    <div className='flex-grow overflow-y-auto'>
                        <AnimatePresence>
                            <Gallery claimableStudents={claimableStudents} selectedStudentsId={selectedStudentsId} setIndex={setIndex}/>
                                {index !== undefined && (
                                    <motion.div
                                        initial={{opacity: 0}}
                                        animate={{opacity: 0.6}}
                                        exit={{opacity: 0}}
                                        key="overlay"
                                        className="bg-white  fixed inset-0"
                                        onClick={() => setIndex(undefined)}
                                    />
                                )}
        
                                {index !== undefined && (
                                    <SingleImage
                                        key="image"
                                        claimableStudent={
                                            claimableStudents.filter(
                                                item => item.id === index,
                                            )[0]
                                        }
                                        selectedStudentsId={selectedStudentsId}
                                        dobInput={dobInput}
                                        setDobInput={setDobInput}
                                        handleAddRemoveClaim={handleAddRemoveClaim}
                                    />
                                )}
                        </AnimatePresence>
                    </div>
                    <div className="my-2">
                        <button
                            className="py-3 px-4 bg-[#F9CA54] rounded-md text-center font-semibold disabled:cursor-not-allowed disabled:bg-[#f9ca5460] w-full"
                            onClick={handleClaimStudentSubmit}
                            disabled={selectedStudentsId.length === 0}
                        >
                            Claim selected profiles
                        </button>
                    </div>
                    <div className="my-2">
                        <div
                            className="py-3 px-4 bg-[#93C5FD] rounded-md text-center font-semibold text-white"
                            onClick={() => setIsClaimStudentModalClosed(true)}
                        >
                            Continue with new enrollment
                        </div>
                    </div>
                </motion.div>
            </div>
        }
      </Layout>
  );
}

export default EnrolmentFormPage
