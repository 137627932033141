// import React from 'react';
// import Webcam from 'react-webcam';

// import Modal from '../../components/common/modal-popup';
// import LoaderTillCamera from '../../components/common/loaderTillCamera';

// import FitnessPageViewModel from '../../view-models/fitness-viewmodel';
// import InstrucionModal from '../../components/common/instructions-popup';

// import logo from '../../../assets/icons/link.svg';
// import Layout from '../../components/common/layout';
// import { useNavigate } from 'react-router-dom';

// const FitnessPage: React.FC = () => {
//     const {
//         techniqueScores,
//         loading,
//         webcamRef,
//         canvasRef,
//         techniqueMode,
//         displayInstructionStop,
//         showModal,
//         confirmStop,
//         cancelStop,
//         showInstructions,
//         onGoing,
//         startCountdown,
//         handelNext,
//         pause,
//         handelPause,
//         handelContinue,
//         handelStop,
//         techniqueTiming,
//         pauseTiming,
//         showOverlay,
//         countdown,
//         cancelInstructionStop,
//         showRes,
//         goHome,
//     } = FitnessPageViewModel();
//     const navigate = useNavigate()

//     return (
//         <Layout isProfileSwitchTabVisible={true}>
//             <div className="">
//                 <div className="sm:block hidden pt-8 pl-2">
//                     <img src={logo} alt="link" />
//                 </div>
//                 <div className="flex sm:w-[80vw] items-center justify-center">
//                     {loading && <LoaderTillCamera />}
//                     <Webcam
//                         ref={webcamRef}
//                         onUserMediaError={(error) => {
//                             alert("To access this feature you must give camera access");
//                             navigate(-1);
//                         }}
//                         style={{
//                             position: 'absolute',
//                             marginLeft: 'auto',
//                             marginRight: 'auto',
//                             left: 0,
//                             right: 0,
//                             textAlign: 'center',
//                             zIndex: 9,
//                             top: 0,
//                             display: 'none',
//                         }}
//                     />
//                     <div className="flex flex-col md:flex-row md:ml-[80px]">
//                         <div className="flex flex-col items-center justify-around md:w-[50vw]">
//                             <canvas
//                                 ref={canvasRef}
//                                 className="w-[90%]  h-[50vh] sm:h-auto"
//                                 style={{
//                                     position: 'relative',
//                                     zIndex: 10,
//                                     backgroundColor: 'rgba(255, 255, 255, 0.1)',
//                                     border: '2px solid black',
//                                 }}
//                             />
//                             <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                 <button
//                                     className={`flex-1 p-4 ${!onGoing ? 'w-[43vw]' : 'w-full'} text-white font-bold bg-blue-600 m-2 rounded-xl`}
//                                     onClick={() => displayInstructionStop()}>
//                                     Instructions
//                                 </button>
//                                 {!onGoing && (
//                                     <button
//                                         className="flex-1 p-4 w-[43vw] text-white font-bold bg-green-500 m-2 rounded-xl"
//                                         onClick={startCountdown}>
//                                         Start
//                                     </button>
//                                 )}
//                             </div>
//                             {onGoing && (
//                                 <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelNext}>
//                                         Next
//                                     </button>
//                                     {!pause ? (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelPause}>
//                                             Pause
//                                         </button>
//                                     ) : (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelContinue}>
//                                             Continue
//                                         </button>
//                                     )}
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelStop}>
//                                         Stop
//                                     </button>
//                                 </div>
//                             )}

//                             {showOverlay && !pause && (
//                                 <>
//                                     <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
//                                         <div className="text-white text-xl p-4 bg-gray-800 bg-opacity-90 rounded-md">
//                                             Improper visibility due to lighting
//                                             conditions or body not fully in
//                                             frame.
//                                         </div>
//                                         {onGoing && (
//                                             <div className="flex flex-row justify-between gap-y-4 w-[93%] z-[100]">
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelNext}>
//                                                     Next
//                                                 </button>
//                                                 {!pause && (
//                                                     <button
//                                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                                         onClick={handelPause}>
//                                                         Pause
//                                                     </button>
//                                                 )}
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelStop}>
//                                                     Stop
//                                                 </button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </>
//                             )}

//                             {countdown !== null && (
//                                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                                     <div className="text-white text-9xl">
//                                         {countdown}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                         {onGoing && (
//                             <div className='flex flex-col'>
//                                 <div>
//                                     <h1>
//                                         {techniqueTiming || pauseTiming
//                                             ? `${techniqueMode} TIMER:`
//                                             : ''}{' '}
//                                         {techniqueTiming
//                                             ? techniqueTiming
//                                             : pauseTiming}
//                                     </h1>
//                                 </div>
//                                 <div className="flex flex-col gap-2 w-[93%]">
//                                     {Object.entries(techniqueScores).map(
//                                         ([techniqueName, score]) => (
//                                             <div
//                                                 key={techniqueName}
//                                                 className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {techniqueName}
//                                                 </div>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {score}
//                                                 </div>
//                                             </div>
//                                         ),
//                                     )}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Modal
//                 show={showRes}
//                 message={
//                     <div className="flex flex-col">
//                         <h1>Good Job 👍</h1>
//                         {onGoing && (
//                             <div className="flex flex-col gap-2 w-[93%]">
//                                 {Object.entries(techniqueScores).map(
//                                     ([techniqueName, score]) => (
//                                         <div
//                                             key={techniqueName}
//                                             className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
//                                             <div className="flex-1 p-4 font-bold">
//                                                 {techniqueName}
//                                             </div>
//                                             <div className="flex-1 p-4 font-bold">
//                                                 {score}
//                                             </div>
//                                         </div>
//                                     ),
//                                 )}
//                             </div>
//                         )}

//                         <h2>Wanna try again?</h2>
//                     </div>
//                 }
//                 onConfirm={confirmStop}
//                 onCancel={goHome}
//             />
//             <Modal
//                 show={showModal}
//                 message="Are you sure all your progress will be lost?"
//                 onConfirm={confirmStop}
//                 onCancel={cancelStop}
//             />
//             <InstrucionModal
//                 show={showInstructions}
//                 message=<ul className="list-disc pl-6 text-left">
//                     <li>Ensure your body is positioned correctly.</li>
//                     <li>Face towards your left and keep your body inbetween the two green lines</li>
//                     <li>
//                         For every technique you will get 45 sec with 20 sec break
//                         in each technique
//                     </li>
//                     <li>
//                          Pause or stop the session using respective buttons.
//                     </li>
//                     <li>The order of the techniques are as follows</li>
//                         <ul>
//                             <ol>"Squats/Situps" </ol>
//                             {/* <ol>"planks"</ol> */}
//                         </ul>
//                     {/* <li>Pause or stop the session using respective buttons.</li> */}
//                     {/* <li>Click "Start" to begin the session.</li> */}
//                 </ul>
//                 onCancel={cancelInstructionStop}
//             />
//         </Layout>
//     );
// };

// export default FitnessPage;







// import React, { useState, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import Modal from '../../components/common/modal-popup';
// import LoaderTillCamera from '../../components/common/loaderTillCamera';
// import FitnessPageViewModel from '../../view-models/fitness-viewmodel';
// import InstrucionModal from '../../components/common/instructions-popup';
// import logo from '../../../assets/icons/link.svg';
// import Layout from '../../components/common/layout';
// import { useNavigate } from 'react-router-dom';
// import {stopSpeech, t2s} from '../../../utils/ai/t2s';


// const FitnessPage: React.FC = () => {
//     const [showWelcomeModal, setShowWelcomeModal] = useState(true);
//     const [showInstructions, setShowInstructions] = useState(false);

//     const {
//         techniqueScores,
//         loading,
//         webcamRef,
//         canvasRef,
//         techniqueMode,
//         displayInstructionStop,
//         showModal,
//         confirmStop,
//         cancelStop,
//         showOverlay,
//         onGoing,
//         startCountdown,
//         handelNext,
//         pause,
//         handelPause,
//         handelContinue,
//         handelStop,
//         techniqueTiming,
//         pauseTiming,
//         countdown,
//         cancelInstructionStop,
//         showRes,
//         goHome,
//     } = FitnessPageViewModel();

//     const navigate = useNavigate();

//     const handleWelcomeNext = () => {
//         stopSpeech(); // Stop the TTS when the welcome popup is closed
//         setShowWelcomeModal(false);
//         setShowInstructions(true); // Show instruction popup after welcome popup
//       };

      
 

//     // const handleWelcomeNext = () => {
//     //     setShowWelcomeModal(false);
//     //     setShowInstructions(true); // Show instruction popup after welcome popup
//     // };

//     useEffect(() => {   // const handleInstructionCancel = () => {
//         //     stopSpeech(); 
//         //     setShowInstructions(false);
//         //     // If needed, reset or update any state here
//         // };
    
//         if (!showWelcomeModal) {
//             setShowWelcomeModal(false);
//         }
//     }, [showWelcomeModal]);


//     const handleInstructionCancel = () => {
//         stopSpeech(); 
//         setShowInstructions(false);
//         // If needed, reset or update any state here
//     };

//     return (
//         <Layout isProfileSwitchTabVisible={true}>
//             <div className="">
//                 <div className="sm:block hidden pt-8 pl-2">
//                     <img src={logo} alt="link" />
//                 </div>
//                 <div className="flex sm:w-[80vw] items-center justify-center">
//                     {loading && <LoaderTillCamera />}
//                     <Webcam
//                         ref={webcamRef}
//                         onUserMediaError={(error) => {
//                             alert("To access this feature you must give camera access");
//                             navigate(-1);
//                         }}
//                         style={{
//                             position: 'absolute',
//                             marginLeft: 'auto',
//                             marginRight: 'auto',
//                             left: 0,
//                             right: 0,
//                             textAlign: 'center',
//                             zIndex: 9,
//                             top: 0,
//                             display: 'none',
//                         }}
//                     />
//                     <div className="flex flex-col md:flex-row md:ml-[80px]">
//                         <div className="flex flex-col items-center justify-around md:w-[50vw]">
//                             <canvas
//                                 ref={canvasRef}
//                                 className="w-[90%] h-[50vh] sm:h-auto"
//                                 style={{
//                                     position: 'relative',
//                                     zIndex: 10,
//                                     backgroundColor: 'rgba(255, 255, 255, 0.1)',
//                                     border: '2px solid black',
//                                 }}
//                             />
//                             <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                 <button
//                                     className={`flex-1 p-4 ${!onGoing ? 'w-[43vw]' : 'w-full'} text-white font-bold bg-blue-600 m-2 rounded-xl`}
//                                     onClick={() => displayInstructionStop()}>
//                                     Instructions
//                                 </button>
//                                 {!onGoing && (
//                                     <button
//                                         className="flex-1 p-4 w-[43vw] text-white font-bold bg-green-500 m-2 rounded-xl"
//                                         onClick={startCountdown}>
//                                         Start
//                                     </button>
//                                 )}
//                             </div>
//                             {onGoing && (
//                                 <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelNext}>
//                                         Next
//                                     </button>
//                                     {!pause ? (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelPause}>
//                                             Pause
//                                         </button>
//                                     ) : (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelContinue}>
//                                             Continue
//                                         </button>
//                                     )}
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelStop}>
//                                         Stop
//                                     </button>
//                                 </div>
//                             )}

//                             {showOverlay && !pause && (
//                                 <>
//                                     <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
//                                         <div className="text-white text-xl p-4 bg-gray-800 bg-opacity-90 rounded-md">
//                                             Improper visibility due to lighting
//                                             conditions or body not fully in
//                                             frame.
//                                         </div>
//                                         {onGoing && (
//                                             <div className="flex flex-row justify-between gap-y-4 w-[93%] z-[100]">
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelNext}>
//                                                     Next
//                                                 </button>
//                                                 {!pause && (
//                                                     <button
//                                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                                         onClick={handelPause}>
//                                                         Pause
//                                                     </button>
//                                                 )}
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelStop}>
//                                                     Stop
//                                                 </button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </>
//                             )}

//                             {countdown !== null && (
//                                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                                     <div className="text-white text-9xl">
//                                         {countdown}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                         {onGoing && (
//                             <div className="flex flex-col">
//                                 <div>
//                                     <h1>
//                                         {techniqueTiming || pauseTiming
//                                             ? `${techniqueMode} TIMER:`
//                                             : ''}{' '}
//                                         {techniqueTiming
//                                             ? techniqueTiming
//                                             : pauseTiming}
//                                     </h1>
//                                 </div>
//                                 <div className="flex flex-col gap-2 w-[93%]">
//                                     {Object.entries(techniqueScores).map(
//                                         ([techniqueName, score]) => (
//                                             <div
//                                                 key={techniqueName}
//                                                 className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {techniqueName}
//                                                 </div>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {score}
//                                                 </div>
//                                             </div>
//                                         ),
//                                     )}
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Modal
//                 show={showRes}
//                 message={
//                     <div className="flex flex-col">
//                         <h1>Good Job 👍</h1>
//                         {onGoing && (
//                             <div className="flex flex-col gap-2 w-[93%]">
//                                 {Object.entries(techniqueScores).map(
//                                     ([techniqueName, score]) => (
//                                         <div
//                                             key={techniqueName}
//                                             className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
//                                             <div className="flex-1 p-4 font-bold">
//                                                 {techniqueName}
//                                             </div>
//                                             <div className="flex-1 p-4 font-bold">
//                                                     {score}
//                                             </div>
//                                         </div>
//                                     ),
//                                 )}
//                             </div>
//                         )}

//                         <h2>Wanna try again?</h2>
//                     </div>
//                 }
//                 onConfirm={confirmStop}
//                 onCancel={goHome}
//             />
//             <Modal
//                 show={showModal}
//                 message="Are you sure all your progress will be lost?"
//                 onConfirm={confirmStop}
//                 onCancel={cancelStop}
//             />
//             <Modal
//                 show={showWelcomeModal}
//                 message={
                    
//                     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                         <div className="bg-white p-6 rounded-lg text-center max-w-sm w-full">
//                             <h1 className="text-2xl font-bold mb-4">Hello, Welcome to the SpArts Exclusive AI module</h1>
//                             <p className="mb-4">
//                                 Our all new SpArts Exclusive Exercise Module is designed specifically for students of SpArts partner academies! This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across SpArts.
//                             </p>

//                             <p className="mb-4">
//                                 Get started today and take your skills to the next level!
//                             </p>
//                             <button
//                                 className="p-4 w-40 text-white font-bold bg-blue-600 rounded-xl"
//                                 onClick={handleWelcomeNext}
//                             >
//                                 Next
//                             </button>
//                         </div>
//                     </div>
//                 }
//                 onConfirm={handleWelcomeNext}
//                 onCancel={() => setShowWelcomeModal(false)}
//             />
//             <InstrucionModal
//                 show={showInstructions}
//                 message={
//                     <ul className="list-disc pl-6 text-left">
//                         <li>Ensure your body is positioned correctly.</li>
//                         <li>Face towards your left and keep your body inbetween the two green lines</li>
//                         <li>For every technique you will get 45 sec with 20 sec break in each technique</li>
//                         <li>Pause or stop the session using respective buttons.</li>
//                         <li>The order of the techniques are as follows:</li>
//                         <ul>
//                             <li>Pushup</li>
//                             <li>Squats/Situps</li>
//                         </ul>
//                     </ul>
//                 }
//                 onCancel={handleInstructionCancel}
//             />
//         </Layout>
//     );
// };

// export default FitnessPage;






// import React, { useState, useEffect } from 'react';
// import Webcam from 'react-webcam';
// import Modal from '../../components/common/modal-popup';
// import LoaderTillCamera from '../../components/common/loaderTillCamera';
// import FitnessPageViewModel from '../../view-models/fitness-viewmodel';
// import InstrucionModal from '../../components/common/instructions-popup';
// import logo from '../../../assets/icons/link.svg';
// import Layout from '../../components/common/layout';
// import { useNavigate } from 'react-router-dom';
// import { stopSpeech, t2s } from '../../../utils/ai/t2s'; // Ensure t2s and stopSpeech are correctly implemented

// const FitnessPage: React.FC = () => {
//     const [showWelcomeModal, setShowWelcomeModal] = useState(true);
//     const [showInstructions, setShowInstructions] = useState(false);

//     const {
//         techniqueScores,
//         loading,
//         webcamRef,
//         canvasRef,
//         techniqueMode,
//         displayInstructionStop,
//         showModal,
//         confirmStop,
//         cancelStop,
//         showOverlay,
//         onGoing,
//         startCountdown,
//         handelNext,
//         pause,
//         handelPause,
//         handelContinue,
//         handelStop,
//         techniqueTiming,
//         pauseTiming,
//         countdown,
//         cancelInstructionStop,
//         showRes,
//         goHome,
//     } = FitnessPageViewModel();

//     const navigate = useNavigate();

//     useEffect(() => {
//         if (showWelcomeModal) {
//             // Debugging line
//             console.log('Welcome modal is shown. Triggering TTS.');
//             t2s(
//                 `Hello, Welcome to the sparts Exclusive AI module. Our all new sparts Exclusive Exercise Module is designed specifically for students of sparts partner academies! This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across sparts. Get started today and take your skills to the next level!`,
//                 1 // Adjust the speed as needed
//             );
//         }
//     }, [showWelcomeModal]);

//     useEffect(() => {
//         if (!showWelcomeModal) {
//             stopSpeech();
//         }
//     }, [showWelcomeModal]);

//     useEffect(() => {
//         if (showInstructions) {
//             t2s(
//                 `Ensure your body is positioned correctly. Face towards your left and keep your body in between the two green lines. For every technique, you will get 45 seconds with a 20-second break in each technique. Pause or stop the session using the respective buttons. The order of the techniques is as follows: Pushup and Squats/Situps.`,
//                 1 // Adjust the speed as needed
//             );
//         }
//     }, [showInstructions]);

//     const handleWelcomeNext = () => {
//         stopSpeech(); // Stop the TTS when the welcome popup is closed
//         setShowWelcomeModal(false);
//         setShowInstructions(true); // Show instruction popup after welcome popup
//     };

//     const handleInstructionCancel = () => {
//         stopSpeech(); 
//         setShowInstructions(false);
//     };


// const handleInstructionButtonClick = () => {
//     setShowInstructions(true); // Show instruction popup
// };

//     return (
//         <Layout isProfileSwitchTabVisible={true}>
//             <div className="">
//                 <div className="sm:block hidden pt-8 pl-2">
//                     <img src={logo} alt="link" />
//                 </div>
//                 <div className="flex sm:w-[80vw] items-center justify-center">
//                     {loading && <LoaderTillCamera />}
//                     <Webcam
//                         ref={webcamRef}
//                         onUserMediaError={(error) => {
//                             alert("To access this feature you must give camera access");
//                             navigate(-1);
//                         }}
//                         style={{
//                             position: 'absolute',
//                             marginLeft: 'auto',
//                             marginRight: 'auto',
//                             left: 0,
//                             right: 0,
//                             textAlign: 'center',
//                             zIndex: 9,
//                             top: 0,
//                             display: 'none',
//                         }}
//                     />
//                     <div className="flex flex-col md:flex-row md:ml-[80px]">
//                         <div className="flex flex-col items-center justify-around md:w-[50vw]">
//                             <canvas
//                                 ref={canvasRef}
//                                 className="w-[90%] h-[50vh] sm:h-auto"
//                                 style={{
//                                     position: 'relative',
//                                     zIndex: 10,
//                                     backgroundColor: 'rgba(255, 255, 255, 0.1)',
//                                     border: '2px solid black',
//                                 }}
//                             />
//                             <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                 <button
//                                     className={`flex-1 p-4 ${!onGoing ? 'w-[43vw]' : 'w-full'} text-white font-bold bg-blue-600 m-2 rounded-xl`}
//                                         onClick={handleInstructionButtonClick}>
                    
//                                     Instructions
//                                 </button>
//                                 {!onGoing && (
//                                     <button
//                                         className="flex-1 p-4 w-[43vw] text-white font-bold bg-green-500 m-2 rounded-xl"
//                                         onClick={startCountdown}>
//                                         Start
//                                     </button>
//                                 )}
//                             </div>
//                             {onGoing && (
//                                 <div className="flex flex-row justify-between gap-y-4 w-[93%]">
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelNext}>
//                                         Next
//                                     </button>
//                                     {!pause ? (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelPause}>
//                                             Pause
//                                         </button>
//                                     ) : (
//                                         <button
//                                             className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                             onClick={handelContinue}>
//                                             Continue
//                                         </button>
//                                     )}
//                                     <button
//                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                         onClick={handelStop}>
//                                         Stop
//                                     </button>
//                                 </div>
//                             )}

//                             {showOverlay && !pause && (
//                                 <>
//                                     <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
//                                         <div className="text-white text-xl p-4 bg-gray-800 bg-opacity-90 rounded-md">
//                                             Improper visibility due to lighting
//                                             conditions or body not fully in
//                                             frame.
//                                         </div>
//                                         {onGoing && (
//                                             <div className="flex flex-row justify-between gap-y-4 w-[93%] z-[100]">
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelNext}>
//                                                     Next
//                                                 </button>
//                                                 {!pause && (
//                                                     <button
//                                                         className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
//                                                         onClick={handelPause}>
//                                                         Pause
//                                                     </button>
//                                                 )}
//                                                 <button
//                                                     className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
//                                                     onClick={handelStop}>
//                                                     Stop
//                                                 </button>
//                                             </div>
//                                         )}
//                                     </div>
//                                 </>
//                             )}

//                             {countdown !== null && (
//                                 <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                                     <div className="text-white text-9xl">
//                                         {countdown}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                         {onGoing && (
//                             <div className="flex flex-col">
//                                 <div>
//                                     <h1>
//                                         {techniqueTiming || pauseTiming
//                                             ? `${techniqueMode} TIMER:`
//                                             : ''}{' '}
//                                         {techniqueTiming
//                                             ? techniqueTiming
//                                             : pauseTiming}
//                                     </h1>
//                                 </div>
//                                 <div className="flex flex-col gap-2 w-[93%]">
//                                     {Object.entries(techniqueScores).map(
//                                         ([techniqueName, score]) => (
//                                             <div
//                                                 key={techniqueName}
//                                                 className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {techniqueName}
//                                                 </div>
//                                                 <div className="flex-1 p-4 font-bold">
//                                                     {score}
//                                                 </div>
//                                             </div>
//                                         ),
//                                     )}
//                                 </div>
//                                 <h2>Wanna try again?</h2>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </div>
//             <Modal
//                 show={showModal}
//                 message="Are you sure all your progress will be lost?"
//                 onConfirm={confirmStop}
//                 onCancel={goHome}
//             />
//             <Modal
//                 show={showWelcomeModal}
//                 message={
//                     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//                         <div className="bg-white p-6 rounded-lg text-center max-w-sm w-full">
//                             <h1 className="text-2xl font-bold mb-4">Hello, Welcome to the SpArts Exclusive AI module</h1>
//                             <p className="mb-4">
//                                 Our all new SpArts Exclusive Exercise Module is designed specifically for students of SpArts partner academies! This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across SpArts.
//                             </p>
//                             <p className="mb-4">
//                                 Get started today and take your skills to the next level!
//                             </p>
//                             <button
//                                 className="p-4 w-40 text-white font-bold bg-blue-600 rounded-xl"
//                                 onClick={handleWelcomeNext}
//                             >
//                                 Next
//                             </button>
//                         </div>
//                     </div>
//                 }
//                 onConfirm={handleWelcomeNext}
//                 onCancel={() => {
//                     stopSpeech();
//                     setShowWelcomeModal(false);
//                 }}
//             />
//             <InstrucionModal
//                 show={showInstructions}
//                 message={
//                     <ul className="list-disc pl-6 text-left">
//                         <li>Ensure your body is positioned correctly.</li>
//                         <li>Face towards your left and keep your body inbetween the two green lines</li>
//                         <li>For every technique you will get 45 sec with 20 sec break in each technique</li>
//                         <li>Pause or stop the session using respective buttons.</li>
//                         <li>The order of the techniques are as follows:</li>
//                         <ul>
//                             <li>Pushup</li>
//                             <li>Squats/Situps</li>
//                         </ul>
//                     </ul>
//                 }
//                 onCancel={handleInstructionCancel}
//             />
//         </Layout>
//     );
// };

// export default FitnessPage;



import React, { useState, useEffect } from 'react';
import Webcam from 'react-webcam';
import Modal from '../../components/common/modal-popup';
import LoaderTillCamera from '../../components/common/loaderTillCamera';
import FitnessPageViewModel from '../../view-models/fitness-viewmodel';
import InstrucionModal from '../../components/common/instructions-popup';
import logo from '../../../assets/icons/link.svg';
import Layout from '../../components/common/layout';
import { useNavigate } from 'react-router-dom';
import { stopSpeech, t2s } from '../../../utils/ai/t2s'; // Ensure t2s and stopSpeech are correctly implemented

const FitnessPage: React.FC = () => {
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(true);
    const [showWelcomeModal, setShowWelcomeModal] = useState(false);
    const [showInstructions, setShowInstructions] = useState(false);
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);

    const {
        techniqueScores,
        loading,
        webcamRef,
        canvasRef,
        techniqueMode,
        displayInstructionStop,
        showModal,
        confirmStop,
        cancelStop,
        showOverlay,
        onGoing,
        startCountdown,
        handelNext,
        pause,
        handelPause,
        handelContinue,
        handelStop,
        techniqueTiming,
        pauseTiming,
        countdown,
        cancelInstructionStop,
        showRes,
        goHome,
    } = FitnessPageViewModel();

    const navigate = useNavigate();

    useEffect(() => {
        if (!disclaimerAccepted) return;
        
        if (showWelcomeModal) {
        //       t2s(
        //     'Hello, Welcome to the sparts Exclusive AI module.',
        //     1
        // );
        // t2s(
        //     'Our all new sparts Exclusive Exercise Module is designed specifically for students of sparts partner academies!',
        //     1
        // );
        // t2s(
        //     'This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across sparts.',
        //     1
        // );
    
        // t2s(
        //     'Get started today and take your skills to the next level!',
        //     1
        // );
            // t2s(
            //     `Hello, Welcome to the sparts Exclusive AI module. Our all new sparts Exclusive Exercise Module is designed specifically for students of sparts partner academies! This innovative module offers a unique opportunity for students to practice basic exercises and compete with batchmates and students across sparts. Get started today and take your skills to the next level!`,
            //     1 // Adjust the speed as needed
            // );
        }
    }, [showWelcomeModal, disclaimerAccepted]);

    useEffect(() => {
        if (!showWelcomeModal) {
            stopSpeech();
        }
    }, [showWelcomeModal]);

    useEffect(() => {
        if (showInstructions) {
            t2s(
                "Let’s Get Moving! Follow These Steps:", 
                1
            );
            t2s(
                "1. Ensure your body is positioned correctly. 2. Face towards your left and keep your body in between the two green lines.", 
                1
            );
            t2s(
                "How it Works:", 
                1
            );
            t2s(
                "• Each technique lasts for 45 seconds, followed by a 20-second break. • You can pause or stop the session using the respective buttons.", 
                1
            );
            t2s(
                "Techniques Order:", 
                1
            );
            t2s(
                "1. Pushups 2. Squats/Sit-ups", 
                1
            );
            t2s(
                "Let’s crush this workout together! Ready, set, go!", 
                1
            );
        }
    }, [showInstructions]);

    const handleDisclaimerAccept = () => {
        setDisclaimerAccepted(true);
        setShowDisclaimerModal(false);
        setShowInstructions(true); 
    };

    const handleDisclaimerCancel = () => {
        navigate(-1); // Go back if the disclaimer is not accepted
        
    };

    const handleWelcomeNext = () => {
        stopSpeech(); // Stop the TTS when the welcome popup is closed
        setShowWelcomeModal(false);
        setShowInstructions(true); // Show instruction popup after welcome popup
    };

    const handleInstructionCancel = () => {
        stopSpeech();
        setShowInstructions(false);
    };

    const handleInstructionButtonClick = () => {
        setShowInstructions(true); // Show instruction popup
    };

    return (
        <Layout isProfileSwitchTabVisible={true}>
            <div className="">
                <div className="sm:block hidden pt-8 pl-2">
                    <img src={logo} alt="link" />
                </div>
                <div className="flex sm:w-[80vw] items-center justify-center">
                    {loading && <LoaderTillCamera />}
                    <Webcam
                        ref={webcamRef}
                        onUserMediaError={(error) => {
                            alert("To access this feature you must give camera access");
                            navigate(-1);
                        }}
                        style={{
                            position: 'absolute',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            left: 0,
                            right: 0,
                            textAlign: 'center',
                            zIndex: 9,
                            top: 0,
                            display: 'none',
                        }}
                    />
                    <div className="flex flex-col md:flex-row md:ml-[80px]">
                        <div className="flex flex-col items-center justify-around md:w-[50vw]">
                            <canvas
                                ref={canvasRef}
                                className="w-[90%] h-[50vh] sm:h-auto"
                                style={{
                                    transform: "scaleX(-1)",
                                    position: 'relative',
                                    zIndex: 10,
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                    border: '2px solid black',
                                }}
                            />
                            <div className="flex flex-row justify-between gap-y-4 w-[93%]">
                                <button
                                    className={`flex-1 p-4 ${!onGoing ? 'w-[43vw]' : 'w-full'} text-white font-bold bg-[#3AB7FC] m-2 rounded-xl`}
                                    onClick={handleInstructionButtonClick}>
                                    Instructions
                                </button>
                                {!onGoing && (
                                    <button
                                        className="flex-1 p-4 w-[43vw] text-white font-bold bg-green-400 m-2 rounded-xl"
                                        onClick={startCountdown}>
                                        Start
                                    </button>
                                )}
                            </div>
                            {onGoing && (
                                <div className="flex flex-row justify-between gap-y-4 w-[93%]">
                                    <button
                                        className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-400 m-2 rounded-xl"
                                        onClick={handelNext}>
                                        Next
                                    </button>
                                    {!pause ? (
                                        <button
                                            className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
                                            onClick={handelPause}>
                                            Pause
                                        </button>
                                    ) : (
                                        <button
                                            className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
                                            onClick={handelContinue}>
                                            Continue
                                        </button>
                                    )}
                                    <button
                                        className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
                                        onClick={handelStop}>
                                        Stop
                                    </button>
                                </div>
                            )}

                            {showOverlay && !pause && (
                                <>
                                    <div className="fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 z-50">
                                        <div className="text-white text-xl p-4 bg-gray-800 bg-opacity-90 rounded-md">
                                            Improper visibility due to lighting
                                            conditions or body not fully in
                                            frame.
                                        </div>
                                        {onGoing && (
                                            <div className="flex flex-row justify-between gap-y-4 w-[93%] z-[100]">
                                                <button
                                                    className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
                                                    onClick={handelNext}>
                                                    Next
                                                </button>
                                                {!pause && (
                                                    <button
                                                        className="flex-1 p-4 w-[30vw] text-white font-bold bg-blue-600 m-2 rounded-xl"
                                                        onClick={handelPause}>
                                                        Pause
                                                    </button>
                                                )}
                                                <button
                                                    className="flex-1 p-4 w-[30vw] text-white font-bold bg-red-600 m-2 rounded-xl"
                                                    onClick={handelStop}>
                                                    Stop
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {countdown !== null && (
                                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                                    <div className="text-white text-9xl">
                                        {countdown}
                                    </div>
                                </div>
                            )}
                        </div>
                        {onGoing && (
                            <div className="flex flex-col">
                                <div>
                                    <h1>
                                        {techniqueTiming || pauseTiming
                                            ? `${techniqueMode} TIMER:`
                                            : ''}{' '}
                                        {techniqueTiming
                                            ? techniqueTiming
                                            : pauseTiming}
                                    </h1>
                                </div>
                                <div className="flex flex-col gap-2 w-[93%]">
                                    {Object.entries(techniqueScores).map(
                                        ([techniqueName, score]) => (
                                            <div
                                                key={techniqueName}
                                                className={`flex flex-row justify-between gap-y-4 w-full ${techniqueName === techniqueMode ? 'border-2 border-blue-600' : ''}`}>
                                                <div className="flex-1 p-4 font-bold">
                                                    {techniqueName}
                                                </div>
                                                <div className="flex-1 p-4 font-bold">
                                                    {score}
                                                </div>
                                            </div>
                                        ),
                                    )}
                                </div>
                                {/* <h2>Wanna try again?</h2> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                show={showModal}
                message="Are you sure all your progress will be lost?"
                onConfirm={confirmStop}
                onCancel={goHome}
            />
            <Modal
    show={showDisclaimerModal}
    message={
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg text-center max-w-sm w-full md:max-w-md lg:max-w-lg xl:max-w-xl">
                <h1 className="text-xl md:text-2xl font-bold mb-4">Important! 🚨</h1>
                <p className="mb-4 text-sm md:text-base text-left">
                    Hey there, SpArts superstar! 🌟
                </p>
                <p className="mb-4 text-sm md:text-base text-left">
                    Welcome to the SpArts Exclusive AI Module! This cutting-edge tool is tailor-made for students of SpArts partner academies. Get ready to practice basic exercises and compete with your batchmates and fellow SpArts students.
                </p>
                <p className="mb-4 text-sm md:text-base text-left">
                    Before you dive into our awesome exercise module, have a quick chat with your physician. Safety first!
                </p>
                <p className="mb-4 text-sm md:text-base text-left">
    Remember:
</p>
<ul className="list-disc list-inside mb-4 text-sm md:text-base text-left">
    <li>Rest when needed 🛌</li>
    <li>Never sacrifice form 🙅‍♂️</li>
    <li>Go at your own pace 🐢</li>
</ul>
                {/* <p className="mb-4 text-sm md:text-base">
                    Remember:
                </p>
                <ul className="list-disc list-inside mb-4 text-sm md:text-base ">
                    <li>Rest when needed 🛌</li>
                    <li>Never sacrifice form 🙅‍♂️</li>
                    <li>Go at your own pace 🐢</li>
                </ul> */}
                <p className="mb-4 text-sm md:text-base text-left">
                    Let the fun begin! 💪🎉
                </p>
                <div className="flex items-center justify-center mb-4">
                    <input
                        type="checkbox"
                        id="acceptDisclaimer"
                        className="mr-2"
                        checked={disclaimerAccepted}
                        onChange={(e) => setDisclaimerAccepted(e.target.checked)}
                    />
                    <label htmlFor="acceptDisclaimer" className="text-sm md:text-base">
                        I understand and accept these terms of use.
                    </label>
                </div>
                <button
                    className="p-3 w-full md:w-40 text-white font-bold bg-[#3AB7FC] rounded-xl mb-2 md:mb-4"
                    onClick={handleDisclaimerAccept}
                    disabled={!disclaimerAccepted}
                >
                    Accept
                </button>
                <button
                    className="p-3 w-full md:w-40 text-white font-bold bg-red-400 rounded-xl"
                    onClick={handleDisclaimerCancel}
                >
                    Cancel
                </button>
                <p className="mt-4 text-sm text-gray-600">
                    Best experienced on desktop
                </p>
            </div>
        </div>
    }
    onConfirm={handleDisclaimerAccept}
    onCancel={handleDisclaimerCancel}
/>
<InstrucionModal
    show={showInstructions}
    message={
        <div className="text-left">
    <p className="mb-4 text-sm md:text-base font-bold">
        Let’s Get Moving! 🚀
    </p>
    <p className="mb-4 text-sm md:text-base font-bold">
        Follow These Steps:
    </p>
    <ul className="list-disc pl-6 mb-4 text-sm md:text-base">
        <li>Ensure your body is positioned correctly. ✅</li>
        <li>Face towards your left ⬅️ and keep your body in between the two green lines 🟩🟩.</li>
    </ul>
    <p className="mb-4 text-sm md:text-base font-bold">
        How it Works:
    </p>
    <ul className="list-disc pl-6 mb-4 text-sm md:text-base">
    <li>
        Each technique lasts for <strong>45 seconds ⏱️, followed by a 20-second break 🛌</strong>.
    </li>
    <li>You can pause ⏸️ or stop 🛑 the session using the respective buttons.</li>
</ul>

    {/* <ul className="list-disc pl-6 mb-4 text-sm md:text-base">
        <li>Each technique lasts for 45 seconds ⏱️, followed by a 20-second break 🛌.</li>
        <li>You can pause ⏸️ or stop 🛑 the session using the respective buttons.</li>
    </ul> */}
    <p className="mb-4 text-sm md:text-base font-bold">
        Techniques Order:
    </p>
    <ul className="list-disc pl-6 mb-4 text-sm md:text-base font-bold">
        <li>Pushups 💪</li>
        <li>Squats/Sit-ups 🏋️‍♂️</li>
    </ul>
    <p className="mb-4 text-sm md:text-base font-bold">
        Let’s crush this workout together! 💥 Ready, set, go! 🏃‍♀️🏃‍♂️
    </p>
</div>

        // <div className="text-left">
        //     <p>Let’s Get Moving! 🚀</p>
        //     <p>Follow These Steps:</p>
        //     <ul className="list-disc pl-6">
        //         <li>Ensure your body is positioned correctly. ✅</li>
        //         <li>Face towards your left ⬅️ and keep your body in between the two green lines 🟩🟩.</li>
        //     </ul>
        //     <p>How it Works:</p>
        //     <ul className="list-disc pl-6">
        //         <li>Each technique lasts for 45 seconds ⏱️, followed by a 20-second break 🛌.</li>
        //         <li>You can pause ⏸️ or stop 🛑 the session using the respective buttons.</li>
        //     </ul>
        //     <p>Techniques Order:</p>
        //     <ul className="list-disc pl-6">
        //         <li>Pushups 💪</li>
        //         <li>Squats/Sit-ups 🏋️‍♂️</li>
        //     </ul>
        //     <p>Let’s crush this workout together! 💥 Ready, set, go! 🏃‍♀️🏃‍♂️</p>
        // </div>
    }
    onCancel={handleInstructionCancel}
/>

           
        </Layout>
    );
};

export default FitnessPage;








