import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {IoChevronBack} from 'react-icons/io5';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import RowAction from '../../components/common/rowAction';
import FeeTransactionsPageViewModel from '../../view-models/fee-transactions-viewmodel';
import logo from "../../../assets/icons/link.svg"
import Layout from '../../components/common/layout';
import { Routes } from '../../navigation/routes';
import FullScreenLoader from '../../components/common/full-sreen-loader';
const TransactionsPage = () => {

    const {
        isLoading,
        transactions,
        student,
        isCashfreeAvailable
    } = FeeTransactionsPageViewModel();

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };
    
    return (
        <Layout isProfileSwitchTabVisible={true}>
            {isLoading && <FullScreenLoader/>}
            <div className="flex p-2 justify-start flex-col">
                <div className="sm:block hidden">
                    <img src={logo} alt="logo" />
                </div>

                <div className={`flex gap-8 sm:pl-3 sm:pt-4 pl-2`}>
                    <IoChevronBack
                        className="text-blue-500 text-3xl mt-3 sm:block hidden"
                        onClick={handleBackClick}
                    />

                    <span className="sm:text-4xl text-2xl font-light sm:mt-1">
                        Payments
                    </span>
                </div>
                <hr className='my-2 border-2 shadow-md rounded-xl'/>
            </div>
            <div className="flex flex-col h-full">
                {transactions?.length > 0 &&
                    <div className="sm:px-20 sm:py-6 flex flex-col gap-8 flex-grow">
                        {transactions?.slice()?.reverse()?.map(fee => (
                            <RowAction
                                text={`₹ ${fee.amount.toString()}`}
                                id="ih"
                                dates={new Date(fee.fee_date).toDateString()}
                                key={fee.id}
                                feeDetails={fee}
                            />
                        ))}
                    </div>
                }
                {(transactions?.length === 0 || !transactions) && 
                    <div className='text-center text-gray-600 font-semibold pt-2 w-full px-4 text-xl h-[280px] flex items-center justify-center'>
                        No Transactions were made for this class previously.
                    </div>
                }
                {isCashfreeAvailable &&
                    <div className="p-2 flex-shrink-0 flex items-center justify-center">
                        <div 
                            className='w-full md:w-[40%] px-2 py-4 bg-gradient-to-r from-cyan-500 to-blue-500 text-center rounded-lg text-xl font-semibold text-gray-200 active:scale-95 transition-all cursor-pointer'
                            onClick={() => navigate(Routes.feesPayment + '/' + student?.activeClassData?.id + '/' + student?.activeStudent?.id)}
                        >
                            Pay Fees
                        </div>
                    </div>
                }
            </div>
        </Layout>
    );
};

export default TransactionsPage;
