import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACADEMY_FEATURED_QUERY, SUBSCIBED_USER_QUERY } from '../../utils/constants/globals';
import { AcademyService } from '../../services/academy-service';
import { Routes } from '../navigation/routes';
import AnimateHeroSection from '../../utils/animate-hero-section';
import { LandingPageService } from '../../services/landing-page-services';




const LandingPageViewModel = () => {
    AnimateHeroSection();
    const navigate = useNavigate();
    const [isUserSubscribed,setUserSubscribed] = useState<boolean>(false);
    const [isUserAlreadySubscribed,setUserAlreadySubscribed] = useState<boolean>(false);
    const [subscribeUserEmail,setSubscribeUserEmail] = useState<string>('');
    const [closedSubscribeSuccesPrompt,setClosedSubscribeSuccesPrompt] = useState<boolean>(false);

    const handleNavigateToSignUpPage = () => {
        navigate(Routes.SignUp)
    }

    const {
        isSuccess: isAcademiesToFeatureFetched,
        isLoading: isAcademiesToFeatureFetching,
        data: academiesToFeature,
    } = useQuery({
        queryKey: [ACADEMY_FEATURED_QUERY],
        queryFn: () => AcademyService.instance.getAllAcademiesToBeFeatured(),
        refetchOnWindowFocus: false,
        enabled: true,
    });

  const queryClient = useQueryClient();

  const postNewSubscriber = async (
    data: {email: string},
  ): Promise<{data: {email: string}} | undefined> => {
      const response =
          await LandingPageService.instance.postNewSubscriber(data);
      if (response.success) {
          setUserSubscribed(true);
          return response.data;
      } else {
          if(response.error?.message === 'This attribute must be unique'){
            setUserAlreadySubscribed(true)
          }
          throw new Error(response.error?.message);
      }
  };

  const {
      mutate: subscibeUser,
      isLoading: isUserSubscribing,
      isSuccess: isUserSubscribeSuccess,
  } = useMutation(postNewSubscriber, {
      onSuccess: data => {
          queryClient.invalidateQueries(SUBSCIBED_USER_QUERY)
      },
      onError: error => {
          // alert('Failed to add Enrolment Form. Please try again!');
      },
  });

  const handleSubscibeUser = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    subscibeUser({email: subscribeUserEmail});
  }

  const handlePromptClose = () => {
    setClosedSubscribeSuccesPrompt(true)
  }

    return {
        isLoading: isUserSubscribing,
        academiesToFeature,
        isAcademiesToFeatureFetching,
        handleNavigateToSignUpPage,
        handleSubscibeUser,
        isUserSubscribed,
        setUserSubscribed,
        setSubscribeUserEmail,
        subscribeUserEmail,
        isUserAlreadySubscribed,
        closedSubscribeSuccesPrompt,
        handlePromptClose,
    };
};

export default LandingPageViewModel;