import React from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../utils/redux/store';

import { ClassService } from '../../services/class-service';
import { ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS, CLASSES_FEE_TRANSACTIONS_QUERY, REGULAR_ASSIGNMENTS_OF_STUDENT, STUDENT_FITNESS_TESTS_LIST_QUERY, STUDENTS_CLASSES_QUERY } from '../../utils/constants/globals';
import { useNavigate, useParams } from 'react-router-dom';
import { Routes } from '../navigation/routes';
import { FeeService } from '../../services/fee-services';
import { setActiveClassAttendanceData, setActiveClassFeeData } from '../../utils/redux/active-profile';
import { FeePaymentData } from '../../models/fee/fee-payment-data';
import { AttendanceService } from '../../services/attendance-service';
import { AttendanceListWrapper } from '../../models/attendance/attendance-list-wrapper';
import { AttendanceAttributesWraper } from '../../models/attendance/attendance-attributes-wrapper';
import { AssignmentService } from '../../services/assignment-services';
import { FitnessTestsService } from '../../services/fitness-tests-service';

const ClassDetailsPageViewModel = () => {
    const userState = useSelector((state: RootState) => state.user)
    const student = useSelector((state: RootState) => state.activeProfile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams<{id: string}>();


    const {
        isSuccess: isClassDetailsFetchSuccess,
        isLoading: isClassDetailsFetching,
        data: classDetails,
    } = useQuery({
        queryKey: [STUDENTS_CLASSES_QUERY],
        queryFn: () => ClassService.instance.getClassDetails(Number(id)),
        refetchOnWindowFocus: false,
        enabled: (Number(id) ?? 0) > 0,
    });

    const {
        isSuccess: isRegularAssignmentsFetchSuccess,
        isLoading: isRegularAssignmentsFetching,
        data: regularAssignments,
      } = useQuery({
        queryKey: [REGULAR_ASSIGNMENTS_OF_STUDENT, student.activeStudent.id],
        queryFn: () => AssignmentService.instance.getRegularAssignmentsOfStudent(student.activeStudent.id),
        refetchOnWindowFocus: true,
        enabled: true,
      });

      const {
        isLoading: isFitnessTestsRecordsFetching,
        data: fitnessTestRecords,
    } = useQuery({
        queryKey: [STUDENT_FITNESS_TESTS_LIST_QUERY],
        queryFn: () => FitnessTestsService.instance.getStudentFitnessRecords(student?.activeStudent?.id),
        refetchOnWindowFocus: false,
        enabled: student?.activeStudent?.id > 0,
    });

    const {
        isSuccess: isClassFeeTransactionsFetchSuccess,
        isLoading: isClassFeeTransactionsFetching,
        data: classFeeTransactions,
    } = useQuery({
        queryKey: [CLASSES_FEE_TRANSACTIONS_QUERY],
        queryFn: () => FeeService.instance.getFeeTransactionForClass(Number(id),student.activeStudent.id),
        refetchOnWindowFocus: false,
        enabled: (Number(id) ?? 0) > 0 && student.activeStudent.id > 0,
        onSuccess: (response) => dispatch(setActiveClassFeeData(response.data?.data[0] || {} as FeePaymentData))
    });

    const {
        isSuccess: isAttendanceOfActiveStudentFetchSuccess,
        isLoading: isAttendanceOfActiveStudentFetching,
        data: AttendanceOfActiveStudent,
    } = useQuery({
        queryKey: [ATTENDANCE_OF_ACTIVE_STUDENT_FOR_CLASS],
        queryFn: () => AttendanceService.instance.getAttendanceOfStudentForClass(Number(id),student.activeStudent.id),
        refetchOnWindowFocus: false,
        enabled: (Number(id) ?? 0) > 0 && student.activeStudent.id > 0,
        onSuccess: (response) => dispatch(setActiveClassAttendanceData(response.data?.data || {} as AttendanceAttributesWraper[]))
    });

    
    const transactions = classFeeTransactions?.data?.data[0]?.attributes?.fee_dates?.map(item => {
        const schedule = classFeeTransactions?.data?.data[0]?.attributes?.payment_schedule;
        const class_fees = classFeeTransactions?.data?.data[0]?.attributes?.amount;
        const balance = classFeeTransactions?.data?.data[0]?.attributes?.balance;
        return {
            ...item,
            schedule,
            class_fees,
            balance
        }
    })

    const handleSelectClass = (classId: number) => {
      navigate(Routes.class+`/${classId}`, {replace: false});
    }

  return {
    isLoading: isClassFeeTransactionsFetching || isAttendanceOfActiveStudentFetching || isClassDetailsFetching || isRegularAssignmentsFetching || isFitnessTestsRecordsFetching,
    classesData: classDetails?.data?.data,
    student,
    assignmentsCount: regularAssignments?.data?.data?.length ?? 0,
    fitnessRecordsCount: fitnessTestRecords?.data?.data?.length ?? 0,
  }
}

export default ClassDetailsPageViewModel
