// ErrorPage.jsx
import React from 'react';
import { useRouteError, useNavigate } from 'react-router-dom';
import ErrorPage1 from "../../../../assets/icons/ErrorPage1 copy.jpg"




const ComingSoonPage = () => {
  
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous route
  };

  return (
      <div role="alert">
          {/* <h2>Something went wrong:</h2>
      {isError(error) ? (
        <pre>{error.message}</pre>
      ) : (
        <pre>Unknown error occurred</pre>
      )} */}

          <div className="flex flex-col items-center sm:justify-center justify-evenly sm:gap-8 sm:max-h-[100vh] h-[100vh] overflow-hidden">
              <img
                  src="https://media.istockphoto.com/id/1302168946/vector/coming-soon-red-ribbon-label-banner-open-available-now-sign-or-coming-soon-tag-vector.jpg?s=612x612&w=0&k=20&c=uzI1Ztsm3NcyQCscb1kQ3goarshfkR_n2ZDhAwgYPWQ="
                  className="max-h-[60vh]"
                  alt="error"
              />
              <div className='flex flex-col gap-8 justify-center text-center'>
                  <h1 className="sm:text-6xl sm:font-bold text-4xl font-normal">
                      New Feature
                  </h1>
                  <div className="flex flex-col items-center justify-center gap-2">
                      <div className="font-bold text-slate-700 text-lg">
                          Coming Soon
                      </div>
                  </div>
                  <div
                      className="bg-[#FFB721] text-white rounded-full p-4"
                      onClick={handleBackClick}>
                      Go Back
                  </div>
              </div>
          </div>
      </div>
  );
};

export default ComingSoonPage;
