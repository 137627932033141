import React from 'react';
import style from './style.module.css';
import logo from "../../../assets/icons/link.svg"
import FooterStatic from '../../components/footer-static-pages';
import NavbarStatic from '../../components/navbar-static-pages';

const TermsOfUse: React.FC = () => {
    return (
        <div>
            <NavbarStatic />
            <div className={style.termsofuse}>
                <h1 className='text-4xl font-semibold mb-8'>SpArts - Terms of Use – V01 – July 2023</h1>
                <p>
                    This document is an electronic record in terms of
                    Information Technology Act, 2000 and rules there under as
                    applicable and the amended provisions pertaining to
                    electronic records in various statutes as amended by the
                    Information Technology Act, 2000. This document is published
                    in accordance with the provisions of Rule 3 (1) of the
                    Information Technology (Intermediaries guidelines) Rules,
                    2011 that require publishing the rules and regulations,
                    privacy policy and Terms of Use for access or usage of
                    www.sparts.app website and SpArts application for desktop,
                    mobile and handheld devices.
                </p>
                <h2 className='font-bold'>Terms of Use</h2>
                <div className="pl-10">
                    <ul style={{listStyleType: 'disc'}} className='sm:pl-8 mb-8'>
                        <li>
                            These terms of use (the "Terms of Use") govern your
                            use of our website www.sparts.app (the "Website")
                            and our "SpArts" application for desktop, mobile and
                            handheld devices (the "App"). The Website and the
                            App are jointly referred to as the "Platform".
                            Please read these Terms of Use carefully before you
                            use the services. If you do not agree to these Terms
                            of Use, you may not use the services on the
                            Platform, and we request you to uninstall the App.
                            By installing, downloading or even merely using the
                            Platform, you shall be contracting with SpArts and
                            you signify your acceptance to this Terms of Use and
                            other SpArts policies (including but not limited to
                            the Cancellation & Refund Policy, Privacy Policy and
                            Take Down Policy) as posted on the Platform and
                            amended from time to time, which takes effect on the
                            date on which you download, install or use the
                            Platform, and create a legally binding arrangement
                            to abide by the same.
                        </li>
                        <li>
                            The Platform is owned and operated by Borsaikias
                            Technologies Private Limited, a private limited
                            company incorporated under the Companies Act, 1956
                            and having its registered office at H.No. 16,
                            Krishnapur, Dharmeswar Taro Path, Beltola, Guwahati,
                            Assam, 781028, India. For the purpose of these Terms
                            of Use, wherever the context so requires, "you",
                            “user”, or “User” shall mean any natural or legal
                            person who shall transaction on the Platform by
                            providing registration data while registering on the
                            Platform as a registered user using any computer
                            systems. The terms "SpArts", "we", "us" or "our"
                            shall mean Borsaikias Technologies Private Limited.
                        </li>
                        <li>
                            SpArts enables transactions on its Platform between
                            participating academies/coaches and buyers, dealing
                            in (a) sports and creative arts coaching services,
                            (b) consumer goods, and (c) other products and
                            services ("Platform Services"). The buyers
                            ("Buyer/s") can choose and place orders ("Orders")
                            from a variety of products and services listed and
                            offered for sale by various merchants including but
                            not limited to the sports and creative arts
                            academies and coaches ("Merchant/s"), on the
                            Platform. Further, the Buyer can also place Orders
                            for undertaking certain tasks on the Platform
                            (“Tasks”).{' '}
                        </li>
                        <li>
                            Delivering and measuring the effectiveness of
                            advertising campaigns;
                        </li>
                        <li>
                            SpArts enables transportation to and from sports and
                            creative arts academies at select localities of
                            serviceable cities across India ("Transportation
                            Services") by connecting third party service
                            providers i.e. transportation service partners
                            (“TSP”) who will be responsible for providing the
                            pick-up and drop services and completing Tasks
                            initiated by the users of the Platform (Buyers or
                            Merchants). The Platform Services and Transportation
                            Services are collectively referred to as "Services".
                            For both Platform Services and Transportation
                            Services, SpArts is merely acting as an intermediary
                            between the Merchants and Buyers and/or TSPs and
                            Buyers/Merchants.
                        </li>
                    </ul>
                </div>
                <p>
                    TSPs are individual entrepreneurs engaged with SpArts on a
                    voluntary, non-exclusive and principal to principal basis to
                    provide aforementioned services for service fee. TSPs are
                    independent contractors and are free to determine their
                    timings of work. SpArts does not exercise control on the
                    TSPs and the relationship between the TSPs and SpArts is not
                    that of an agent and principal or employee and employer.{' '}
                </p>
                <ul style={{listStyleType: 'disc'}} className='pl-10'>
                    <li>
                        For the transportation services and completing the
                        Tasks, TSPs may charge the users of the Platform (Buyers
                        or Merchants), a service fee (inclusive of applicable
                        taxes whenever not expressly mentioned) determined on
                        the basis of various factors including but not limited
                        to distance covered, time taken, demand for delivery
                        services/Tasks, real time analysis of traffic and
                        weather conditions, seasonal peaks or such other
                        parameters as may be determined from time to time.
                    </li>
                </ul>

                <h2 className='font-bold'>Amendments</h2>
                <p>
                    {' '}
                    These Terms of Use are subject to modifications. We reserve
                    the right to modify or change these Terms of Use and other
                    SpArts policies at any time by posting modified documents on
                    the Platform and notifying you of the same for your perusal.
                    You shall be liable to update yourself of such changes, if
                    any, by accessing the same. You shall, at all times, be
                    responsible for regularly reviewing the Terms of Use and the
                    other SpArts policies and note the changes made on the
                    Platform. Your continued usage of the Services after any
                    change is posted constitutes your acceptance of the amended
                    Terms of Use and other SpArts policies. As long as you
                    comply with these Terms of Use, SpArts grants you a
                    personal, non-exclusive, non-transferable, limited privilege
                    to access, enter, and use the Platform. By accepting these
                    Terms of Use, you also accept and agree to be bound by the
                    other terms and conditions and SpArts policies (including
                    but not limited to Cancellation & Refund Policy, Privacy
                    Policy and Notice and Take Down Policy) as may be posted on
                    the Platform from time to time.{' '}
                </p>

                <h2 className='font-bold'>Acceptance</h2>
                <p>
                    By accepting these Terms of Use, you also accept and agree
                    to be bound by the other terms and conditions and SpArts
                    policies (including but not limited to Cancellation & Refund
                    Policy, Privacy Policy and Notice and Take Down Policy) as
                    may be posted on the Platform from time to time.
                </p>
                <h2 className='font-bold'>Use of Platform and Services</h2>
                <div className="sm:pl-20 pl-10">
                    <ul style={{listStyleType: 'disc'}}>
                        <li>
                            All commercial/contractual terms are offered by and
                            agreed to between Buyers and Merchants alone with
                            respect to products and services being offered by
                            the Merchants. The commercial/contractual terms
                            include without limitation price, applicable taxes,
                            shipping costs, payment terms, date, period and mode
                            of delivery, warranties related to products and
                            services and after sales services related to
                            products and services. SpArts does not have any
                            control or does not determine or advise or in any
                            way involve itself in the offering or acceptance of
                            such commercial/contractual terms between the Buyers
                            and Merchants. SpArts may, however, offer support
                            services to Merchants in respect to Order
                            fulfilment, mode of payment, payment collection,
                            call centre support and other ancillary services,
                            pursuant to independent contracts executed by SpArts
                            with the Merchants. The price of the product and
                            services offered by the Merchant are determined by
                            the Merchant itself and SpArts has no role to play
                            in such determination of price in any way
                            whatsoever.
                        </li>
                        <li>
                            SpArts does not make any representation or warranty
                            as to the item-specifics (such as legal title,
                            creditworthiness, identity, etc.) of any of the
                            Merchants. You are advised to independently verify
                            the bona fides of any particular Merchant that you
                            choose to deal with on the Platform and use your
                            best judgment on that behalf. All Merchant offers
                            and third-party offers are subject to respective
                            party terms and conditions. SpArts takes no
                            responsibility for such offers.
                        </li>
                        <li>
                            SpArts does not make any representation or warranty
                            as to the item-specifics (such as legal title,
                            creditworthiness, identity, etc.) of any of the
                            Merchants. You are advised to independently verify
                            the bona fides of any particular Merchant that you
                            choose to deal with on the Platform and use your
                            best judgment on that behalf. All Merchant offers
                            and third-party offers are subject to respective
                            party terms and conditions. SpArts takes no
                            responsibility for such offers.
                        </li>
                        <li>
                            SpArts neither make any representation or warranty
                            as to specifics (such as quality, value, salability,
                            etc.) of the products or services proposed to be
                            sold or offered to be sold or purchased on the
                            Platform nor does implicitly or explicitly support
                            or endorse the sale or purchase of any products or
                            services on the Platform. SpArts accepts no
                            liability for any errors or omissions, whether on
                            behalf of itself or third parties.
                        </li>
                        <li>
                            SpArts does not make any representation or warranty
                            with respect to any aspect of the services being
                            provided by the TSPs through the Platform including
                            but not limited to transportation services and Task
                            completion services to the Merchants or Buyers as
                            the case may be.
                        </li>
                        <li>
                            SpArts is not responsible for any non-performance or
                            breach of any contract entered into between Buyers
                            and Merchants, and between Merchants/Buyers and TSP
                            on the Platform. SpArts cannot and does not
                            guarantee that the concerned Buyers, Merchants and
                            TSPs will perform any transaction concluded on the
                            Platform. SpArts is not responsible for
                            unsatisfactory or non-performance of services or
                            damages or delays as a result of products which are
                            out of stock, unavailable or back ordered.
                        </li>
                        <li>
                            SpArts is operating an online marketplace and
                            assumes the role of facilitator, and does not at any
                            point of time during any transaction between Buyer
                            and Merchant and/or Buyer and TSP on the Platform
                            come into or take possession of any of the products
                            or services offered by Merchant or TSP. At no time
                            shall SpArts hold any right, title or interest over
                            the products nor shall SpArts have any obligations
                            or liabilities in respect of such contract entered
                            into between Buyer and Merchant and/or Buyer and
                            TSP.
                        </li>
                        <li>
                            SpArts is only providing a platform for
                            communication and it is agreed that the contract for
                            sale of any of the products or services shall be a
                            strictly bipartite contract between the Merchant and
                            the Buyer. In case of complaints from the Buyer
                            pertaining to efficacy, quality, or any other such
                            issues, SpArta shall notify the same to the Merchant
                            and shall also redirect the Buyer to the consumer
                            call center of the Merchant. The Merchant shall be
                            liable for redressing Buyer complaints. In the event
                            you raise any complaint on any Merchant accessed
                            using our Platform, we shall assist you to the best
                            of our abilities by providing relevant information
                            to you, such as details of the Merchant and the
                            specific Order to which the complaint relates, to
                            enable satisfactory resolution of the complaint.
                        </li>
                        <li>
                            Similar to the above, SpArts is only providing a
                            platform for communication with TSP and does not
                            provide any Transportation services or Task
                            completion services with respect to the Orders
                            placed by Merchants/Buyers on the Platform as it is
                            merely facilitating Transportation Services by
                            connecting the Merchants/Buyers with the TSP through
                            the Platform. In case of complaints by the
                            Merchants/Buyers for deficiency or lapse in the
                            transportation services or Task completion services
                            provided by TSP, SpArts shall notify the same to the
                            TSP and also assist Merchants/Buyers to the best of
                            its abilities to enable satisfactory resolution of
                            the complaint.{' '}
                        </li>
                        <li>
                            Please note that there could be risks in dealing
                            with underage persons or people acting under false
                            pretence.
                        </li>
                        <li>
                            SpArts - Use of the Website and Apps (Android and
                            iOS)
                        </li>
                    </ul>
                </div>
                <br />
                <p>
                    You agree, undertake and confirm that your use of Platform
                    shall be strictly governed by the following binding
                    principles:
                </p>
                <ul style={{listStyleType: 'disc'}} className='sm:pl-20 pl-10'>
                    <li>
                        You shall not host, display, upload, download, modify,
                        publish, transmit, update or share any information
                        which:
                    </li>
                    <li>
                        belongs to another person and which you do not have any
                        right to;
                    </li>
                    <li>
                        is grossly harmful, harassing, blasphemous, defamatory,
                        obscene, pornographic, paedophilic, libellous,
                        slanderous, criminally inciting or invasive of another's
                        privacy, hateful, or racially, ethnically objectionable,
                        disparaging, relating or encouraging money laundering or
                        gambling, or otherwise unlawful in any manner
                        whatsoever; or unlawfully threatening or unlawfully
                        harassing including but not limited to "indecent
                        representation of women" within the meaning of the
                        Indecent Representation of Women (Prohibition) Act,
                        1986;
                    </li>
                    <li>is misleading or misrepresentative in any way;</li>
                    <li>
                        is patently offensive to the online community, such as
                        sexually explicit content, or content that promotes
                        obscenity, paedophilia, racism, bigotry, hatred or
                        physical harm of any kind against any group or
                        individual;
                    </li>
                    <li>harasses or advocates harassment of another person;</li>
                    <li>
                        involves the transmission of "junk mail", "chain
                        letters", or unsolicited mass mailing or "spamming";
                    </li>
                    <li>
                        promotes illegal activities or conduct that is abusive,
                        threatening, obscene, defamatory or libellous;
                    </li>
                    <li>
                        infringes upon or violates any third party's rights
                        including, but not limited to, intellectual property
                        rights, rights of privacy (including without limitation
                        unauthorized disclosure of a person's name, email
                        address, physical address or phone number) or rights of
                        publicity;
                    </li>
                    <li>
                        promotes an illegal or unauthorized copy of another
                        person's copyrighted work (see "copyright complaint"
                        below for instructions on how to lodge a complaint about
                        uploaded copyrighted material), such as providing
                        pirated computer programs or links to them, providing
                        information to circumvent manufacture-installed
                        copy-protect devices, or providing pirated music or
                        links to pirated music files;
                    </li>
                    <li>
                        contains restricted or password-only access pages, or
                        hidden pages or images (those not linked to or from
                        another accessible page);
                    </li>
                    <li>
                        provides material that exploits people in a sexual,
                        violent or otherwise inappropriate manner or solicits
                        personal information from anyone;
                    </li>
                    <li>
                        provides instructional information about illegal
                        activities such as making or buying illegal weapons,
                        violating someone's privacy, or providing or creating
                        computer viruses;
                    </li>
                    <li>
                        contains video, photographs, or images of another person
                        (with a minor or an adult);
                    </li>
                    <li>
                        tries to gain unauthorized access or exceeds the scope
                        of authorized access to the Platform or to profiles,
                        blogs, communities, account information, bulletins,
                        friend request, or other areas of the Platform or
                        solicits passwords or personal identifying information
                        for commercial or unlawful purposes from other users;
                    </li>
                    <li>
                        engages in commercial activities and/or sales without
                        our prior written consent such as contests, sweepstakes,
                        barter, advertising and pyramid schemes, or the buying
                        or selling of products related to the Platform.
                        Throughout these Terms of Use, SpArts's prior written
                        consent means a communication coming from SpArts's Legal
                        Department, specifically in response to your request,
                        and expressly addressing and allowing the activity or
                        conduct for which you seek authorization;
                    </li>
                    <li>
                        solicits gambling or engages in any gambling activity
                        which is or could be construed as being illegal;
                    </li>
                    <li>
                        interferes with another user's use and enjoyment of the
                        Platform or any third party's user and enjoyment of
                        similar services;
                    </li>
                    <li>
                        refers to any website or URL that, in our sole
                        discretion, contains material that is inappropriate for
                        the Platform or any other website, contains content that
                        would be prohibited or violates the letter or spirit of
                        these Terms of Use;
                    </li>
                    <li>harm minors in any way;</li>
                    <li>
                        infringes any patent, trademark, copyright or other
                        intellectual property rights or third party's trade
                        secrets or rights of publicity or privacy or shall not
                        be fraudulent or involve the sale of counterfeit or
                        stolen products;
                    </li>
                    <li>violates any law for the time being in force;</li>
                    <li>
                        deceives or misleads the addressee/users about the
                        origin of such messages or communicates any information
                        which is grossly offensive or menacing in nature;
                    </li>
                    <li>impersonate another person;</li>
                    <li>
                        contains software viruses or any other computer code,
                        files or programs designed to interrupt, destroy or
                        limit the functionality of any computer resource; or
                        contains any trojan horses, worms, time bombs,
                        cancelbots, easter eggs or other computer programming
                        routines that may damage, detrimentally interfere with,
                        diminish value of, surreptitiously intercept or
                        expropriate any system, data or personal information;
                    </li>
                    <li>
                        threatens the unity, integrity, defence, security or
                        sovereignty of India, friendly relations with foreign
                        states, or public order or causes incitement to the
                        commission of any criminal offence or prevents
                        investigation of any offence or is insulting any other
                        nation;
                    </li>
                    <li>is false, inaccurate or misleading;</li>
                    <li>
                        directly or indirectly, offers, attempts to offer,
                        trades or attempts to trade in any item, the dealing of
                        which is prohibited or restricted in any manner under
                        the provisions of any applicable law, rule, regulation
                        or guideline for the time being in force; or
                    </li>
                    <li>
                        creates liability for us or causes us to lose (in whole
                        or in part) the services of our internet service
                        provider or other suppliers.
                    </li>
                    <li>
                        You shall not use any "deep-link", "page-scrape",
                        "robot", "spider" or other automatic device, program,
                        algorithm or methodology, or any similar or equivalent
                        manual process, to access, acquire, copy or monitor any
                        portion of the Platform or any Content, or in any way
                        reproduce or circumvent the navigational structure or
                        presentation of the Platform or any Content, to obtain
                        or attempt to obtain any materials, documents or
                        information through any means not purposely made
                        available through the Platform. We reserve our right to
                        prohibit any such activity.
                    </li>
                    <li>
                        You shall not attempt to gain unauthorized access to any
                        portion or feature of the Platform, or any other systems
                        or networks connected to the Platform or to any server,
                        computer, network, or to any of the services offered on
                        or through the Platform, by hacking, "password mining"
                        or any other illegitimate means.
                    </li>
                    <li>
                        You shall not probe, scan or test the vulnerability of
                        the Platform or any network connected to the Platform
                        nor breach the security or authentication measures on
                        the Platform or any network connected to the Platform.
                        You may not reverse look-up, trace or seek to trace any
                        information on any other user of or visitor to Platform,
                        or any other Buyer, including any account on the
                        Platform not owned by you, to its source, or exploit the
                        Platform or any service or information made available or
                        offered by or through the Platform, in any way where the
                        purpose is to reveal any information, including but not
                        limited to personal identification or information, other
                        than your own information, as provided for by the
                        Platform.
                    </li>
                    <li>
                        You shall not make any negative, denigrating or
                        defamatory statement(s) or comment(s) about us or the
                        brand name or domain name used by us including the name
                        'SpArts', or otherwise engage in any conduct or action
                        that might tarnish the image or reputation, of SpArts or
                        Merchant on platform or otherwise tarnish or dilute any
                        SpArts's trade or service marks, trade name and/or
                        goodwill associated with such trade or service marks, as
                        may be owned or used by us. You agree that you will not
                        take any action that imposes an unreasonable or
                        disproportionately large load on the infrastructure of
                        the Platform or SpArts's systems or networks, or any
                        systems or networks connected to SpArts.
                    </li>
                    <li>
                        You agree not to use any device, software or routine to
                        interfere or attempt to interfere with the proper
                        working of the Platform or any transaction being
                        conducted on the Platform, or with any other person's
                        use of the Platform.
                    </li>
                    <li>
                        You may not forge headers or otherwise manipulate
                        identifiers in order to disguise the origin of any
                        message or transmittal you send to us on or through the
                        Platform or any service offered on or through the
                        Platform. You may not pretend that you are, or that you
                        represent, someone else, or impersonate any other
                        individual or entity.
                    </li>
                    <li>
                        You may not use the Platform or any content on the
                        Platform for any purpose that is unlawful or prohibited
                        by these Terms of Use, or to solicit the performance of
                        any illegal activity or other activity that infringes
                        the rights of SpArts and/or others.
                    </li>
                    <li>
                        You shall at all times ensure full compliance with the
                        applicable provisions, as amended from time to time, of
                        (a) the Information Technology Act, 2000 and the rules
                        thereunder; (b) all applicable domestic laws, rules and
                        regulations (including the provisions of any applicable
                        exchange control laws or regulations in force); and (c)
                        international laws, foreign exchange laws, statutes,
                        ordinances and regulations (including, but not limited
                        to sales tax/VAT, income tax, octroi, service tax,
                        central excise, custom duty, local levies) regarding
                        your use of our service and your listing, purchase,
                        solicitation of offers to purchase, and sale of products
                        or services. You shall not engage in any transaction in
                        an item or service, which is prohibited by the
                        provisions of any applicable law including exchange
                        control laws or regulations for the time being in force.
                    </li>
                    <li>
                        In order to allow us to use the information supplied by
                        you, without violating your rights or any laws, you
                        agree to grant us a non-exclusive, worldwide, perpetual,
                        irrevocable, royalty-free, sub-licensable (through
                        multiple tiers) right to exercise the copyright,
                        publicity, database rights or any other rights you have
                        in your Information, in any media now known or not
                        currently known, with respect to your Information. We
                        will only use your information in accordance with these
                        Terms of Use and Privacy Policy applicable to use of the
                        Platform.
                    </li>
                    <li>
                        From time to time, you shall be responsible for
                        providing information relating to the products or
                        services proposed to be sold by you. In this connection,
                        you undertake that all such information shall be
                        accurate in all respects. You shall not exaggerate or
                        overemphasize the attributes of such products or
                        services so as to mislead other users in any manner.
                    </li>
                    <li>
                        You shall not engage in advertising to, or solicitation
                        of, other users of the Platform to buy or sell any
                        products or services, including, but not limited to,
                        products or services related to that being displayed on
                        the Platform or related to us. You may not transmit any
                        chain letters or unsolicited commercial or junk email to
                        other users via the Platform. It shall be a violation of
                        these Terms of Use to use any information obtained from
                        the Platform in order to harass, abuse, or harm another
                        person, or in order to contact, advertise to, solicit,
                        or sell to another person other than us without our
                        prior explicit consent. In order to protect our users
                        from such advertising or solicitation, we reserve the
                        right to restrict the number of messages or emails which
                        a user may send to other users in any 24-hour period
                        which we deem appropriate in its sole discretion. You
                        understand that we have the right at all times to
                        disclose any information (including the identity of the
                        persons providing information or materials on the
                        Platform) as necessary to satisfy any law, regulation or
                        valid governmental request. This may include, without
                        limitation, disclosure of the information in connection
                        with investigation of alleged illegal activity or
                        solicitation of illegal activity or in response to a
                        lawful court order or subpoena. In addition, We can (and
                        you hereby expressly authorize us to) disclose any
                        information about you to law enforcement or other
                        government officials, as we, in our sole discretion,
                        believe necessary or appropriate in connection with the
                        investigation and/or resolution of possible crimes,
                        especially those that may involve personal injury.
                    </li>
                    <li>
                        We reserve the right, but has no obligation, to monitor
                        the materials posted on the Platform. SpArts shall have
                        the right to remove or edit any content that in its sole
                        discretion violates, or is alleged to violate, any
                        applicable law or either the spirit or letter of these
                        Terms of Use. Notwithstanding this right, YOU REMAIN
                        SOLELY RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU
                        POST ON THE PLATFORM AND IN YOUR PRIVATE MESSAGES.
                        Please be advised that such Content posted does not
                        necessarily reflect SpArts views. In no event shall
                        SpArts assume or have any responsibility or liability
                        for any Content posted or for any claims, damages or
                        losses resulting from use of Content and/or appearance
                        of Content on the Platform. You hereby represent and
                        warrant that you have all necessary rights in and to all
                        Content which you provide and all information it
                        contains and that such Content shall not infringe any
                        proprietary or other rights of third parties or contain
                        any libellous, tortious, or otherwise unlawful
                        information.
                    </li>
                    <li>
                        Your correspondence or business dealings with, or
                        participation in promotions of, advertisers found on or
                        through the Platform, including payment and delivery of
                        related products or services, and any other terms,
                        conditions, warranties or representations associated
                        with such dealings, are solely between you and such
                        advertiser. We shall not be responsible or liable for
                        any loss or damage of any sort incurred as the result of
                        any such dealings or as the result of the presence of
                        such advertisers on the Platform.
                    </li>
                    <li>
                        It is possible that other users (including unauthorized
                        users or 'hackers') may post or transmit offensive or
                        obscene materials on the Platform and that you may be
                        involuntarily exposed to such offensive and obscene
                        materials. It also is possible for others to obtain
                        personal information about you due to your use of the
                        Platform, and that the recipient may use such
                        information to harass or injure you. We do not approve
                        of such unauthorized uses, but by using the Platform You
                        acknowledge and agree that we are not responsible for
                        the use of any personal information that you publicly
                        disclose or share with others on the Platform. Please
                        carefully select the type of information that you
                        publicly disclose or share with others on the Platform.
                    </li>
                    <li>
                        SpArts shall have all the rights to take necessary
                        action and claim damages that may occur due to your
                        involvement/participation in any way on your own or
                        through group/s of people, intentionally or
                        unintentionally in DoS/DDoS (Distributed Denial of
                        Services), hacking, pen testing attempts without our
                        prior consent or a mutual legal agreement.
                    </li>
                    <h2>Account Registration </h2>
                    <ul style={{listStyleType: 'disc'}}>
                        <li>
                            You may access the Platform by registering to create
                            an account (" SpArts Account") and become a member
                            (" Membership"); or (c) you can also register to
                            join by logging into your account with certain third
                            party social networking sites ("SNS") (including,
                            but not limited to, Facebook); each such account, a
                            " Third Party Account", via our Platform, as
                            described below. The Membership is limited for the
                            purpose and are subject to the terms, and strictly
                            not transferable. As part of the functionality of
                            the Platform services, you may link your SpArts
                            Account with Third Party Accounts, by either:
                        </li>
                        <li>
                            providing your Third Party Account login information
                            to us through the Platform; or
                        </li>
                        <li>
                            allowing us to access your Third Party Account, as
                            is permitted under the applicable terms and
                            conditions that govern your use of each Third Party
                            Account.
                        </li>
                        <li>
                            You represent that you are entitled to disclose your
                            Third Party Account login information to us and/or
                            grant us access to your Third Party Account
                            (including, but not limited to, for use for the
                            purposes described herein), without breach by you of
                            any of the terms and conditions that govern your use
                            of the applicable Third Party Account and without
                            obligating us to pay any fees or making us subject
                            to any usage limitations imposed by such third party
                            service providers.
                        </li>
                        <li>
                            By granting us access to any Third Party Accounts,
                            you understand that we will access, make available
                            and store (if applicable) any content or information
                            that you have provided to and stored in your Third
                            Party Account (" SNS Content") so that it is
                            available on and through the Platform via your
                            SpArts Account.
                        </li>
                        <li>
                            Unless otherwise specified in these Terms of Use,
                            all SNS Content, if any, will be considered to be
                            your content for all purposes of these Terms of Use.
                        </li>
                        <li>
                            Depending on the Third Party Accounts, you choose,
                            and subject to the privacy settings that you have
                            set in such Third Party Accounts, personally
                            identifiable information that you post to your Third
                            Party Accounts will be available on and through your
                            SpArts Account on the Platform.
                        </li>
                        <li>
                            Please note that if a Third Party Account or
                            associated service becomes unavailable or our access
                            to such Third Party Account is terminated by the
                            third party service provider, then SNS Content will
                            no longer be available on and through the Platform.
                        </li>
                        <li>
                            We will create your SpArts Account for your use of
                            the Platform services based upon the personal
                            information you provide to us or that we obtain via
                            SNS, as described above. You can only have one
                            SpArts Account and are not permitted to create
                            multiple accounts. SpArts reserves the right to
                            suspend such multiple accounts without being liable
                            for any compensation where you have created multiple
                            accounts on the Platform.
                        </li>
                        <li>
                            You agree to provide accurate, current and complete
                            information during the registration process and
                            update such information to keep it accurate, current
                            and complete.
                        </li>
                        <li>
                            We reserve the right to suspend or terminate your
                            SpArts Account and your access to the Services (i)
                            if any information provided during the registration
                            process or thereafter proves to be inaccurate, not
                            current or incomplete; (ii) if it is believed that
                            your actions may cause legal liability for you,
                            other users or us; and/or (iii) if you are found to
                            be non-compliant with the Terms of Use or other
                            SpArts policies.
                        </li>
                        <li>
                            You are responsible for safeguarding your password.
                            You agree that you will not disclose your password
                            to any third party and that you will take sole
                            responsibility for any activities or actions under
                            your SpArts Account, whether or not you have
                            authorized such activities or actions. You will
                            immediately notify us of any unauthorized use of
                            your SpArts Account.
                        </li>
                        <li>
                            Goods and services purchased from the Platform are
                            intended for your personal use and you represent
                            that the same are not for resale or you are not
                            acting as an agent for other parties.
                        </li>
                    </ul>

                    <h2>Order Booking and Financial Terms</h2>
                    <ul style={{listStyleType: 'disc'}}>
                        <li>
                            The Platform allows the Buyers to place Orders and
                            upon acceptance of such Orders by the Merchants,
                            SpArts will, subject to the terms and conditions set
                            out herein, facilitates delivery of goods or
                            services, or completion of Tasks through TSP.
                        </li>
                        <li>
                            SpArts does not own, sell, resell on its own such
                            products offered by the Merchants, and/or does not
                            control the Merchants or the related services
                            provided in connection thereof. Buyer understands
                            that any Order that he/she places shall be subject
                            to the terms and conditions set out in these Terms
                            of Use including, but not limited to, product
                            availability, delivery location serviceability, and
                            acceptance of Orders by Merchants/TSPs.
                        </li>
                        <li>
                            As a general rule, all Orders placed on the Platform
                            and Delivery Services are treated as confirmed.
                        </li>
                        <li>
                            However, upon Buyer’s successful completion of
                            booking an Order, we may call the Buyer on the
                            telephone or mobile number provided to confirm the
                            details of such Order, price to be paid and the
                            estimated delivery time. For this purpose, Buyer
                            will be required to share certain information with
                            us, including but not limited to Buyer’s (i) first
                            and last name (ii) mobile number; and (iii) email
                            address. It shall be Buyer’s sole responsibility to
                            bring any incorrect details to our attention.
                        </li>
                        <li>
                            In addition to the foregoing, we may also contact
                            you by phone and / or email to inform and confirm
                            any change in the Order, due to availability or
                            unavailability or change in Order or change in price
                            of any item in the Order as informed by the
                            Merchant. Please note that any change or
                            confirmation of the Order shall be treated as final.
                            It is clarified that SpArts reserves the right to
                            not to process Buyer's Order in the event Buyer or
                            Merchant or TSP is unavailable on the phone or any
                            other means of communication at the time when we
                            call you for confirming the Order and such event the
                            provisions of the Cancellation and Refund Policy
                            shall be applicable.
                        </li>
                        <li>
                            All payments made against the Orders or Services on
                            the Platform by you shall be compulsorily in Indian
                            Rupees acceptable in the Republic of India. The
                            Platform will not facilitate transactions with
                            respect to any other form of currency with respect
                            to the Orders or Services made on Platform. You can
                            pay by (i) credit card or debit card or net banking;
                            (ii) any other RBI approved payment method at the
                            time of booking an Order; or (iii) credit or debit
                            card or cash at the time of delivery. You
                            understand, accept and agree that the payment
                            facility provided by SpArts is neither a banking nor
                            financial service but is merely a facilitator
                            providing an electronic, automated online electronic
                            payment, receiving payment on delivery, collection
                            and remittance facility for the transactions on the
                            Platform using the existing authorized banking
                            infrastructure and credit card payment gateway
                            networks. Further, by providing payment facility,
                            SpArts is neither acting as trustees nor acting in a
                            fiduciary capacity with respect to the transaction
                            or the transaction price.
                        </li>
                        <li>
                            Buyers acknowledge and agree that SpArts acts as the
                            Merchant's and TSP’s payment agent for the limited
                            purpose of accepting payments from Buyers/Merchants
                            on behalf of the Merchant or TSP, as the case may
                            be. Upon your payment of amounts to us, which are
                            due to the Merchant or TSP, your payment obligation
                            to the Merchant or TSP for such amounts is
                            completed, and we are responsible for remitting such
                            amounts to the Merchant or TSP. You shall not, under
                            any circumstances whatsoever, make any payment
                            directly to the Merchant for Order bookings or to
                            the TSP for delivery of the Order or completion of
                            the Task made using the Platform.
                        </li>
                        <li>
                            Buyer agrees to pay for the total amount for the
                            Order placed on the Platform. SpArts will collect
                            the total amount in accordance with these Terms of
                            Use and the pricing terms set forth in the
                            applicable listing of product or restaurant service
                            for the particular Merchant, apart from the delivery
                            fees for Delivery Services. Please note that we
                            cannot control any amount that may be charged to
                            Buyer by his/her bank related to our collection of
                            the total amount, and we disclaim all liability in
                            this regard.
                        </li>
                        <li>
                            In connection with Buyer’s Order, he/she will be
                            asked to provide customary billing information such
                            as name, billing address and credit card information
                            either to us or our third party payment processor.
                            Buyer agrees to pay us for the Order placed by you
                            on the Platform, in accordance with these Terms,
                            using the methods described under clause VIII (6)
                            above. Buyer hereby authorizes the collection of
                            such amounts by charging the credit card provided as
                            part of requesting the booking, either directly by
                            us or indirectly, via a third party online payment
                            processor or by one of the payment methods described
                            on the Platform. If Buyer is directed to our
                            third-party payment processor, he/she may be subject
                            to terms and conditions governing use of that third
                            party's service and that third party's personal
                            information collection practices. Please review such
                            terms and conditions and privacy policy before using
                            the Platform services. Once the Order is confirmed
                            you will receive a confirmation email summarizing
                            the confirmed booking.
                        </li>
                        <li>
                            The final tax bill will be issued by the Merchant
                            and TSP (if registered for tax purposes) to the
                            Buyer along with the Order and SpArts is merely
                            collecting the payment on behalf of such Merchant
                            and TSP. All applicable taxes and levies, the rates
                            thereof and the manner of applicability of such
                            taxes on the bill are being charged and determined
                            by the Merchant and TSP. SpArts holds no
                            responsibility for the legal correctness/validity of
                            the levy of such taxes. The sole responsibility for
                            any legal issue arising on the taxes shall reside
                            with the Merchant and the TSP.
                        </li>
                        <li>
                            The prices reflected on the Platform, including
                            packaging or handling charges, are determined solely
                            by the Merchant and are listed based on Merchant's
                            information. Very rarely, prices may change at the
                            time of placing Order due to Merchant changing the
                            menu price without due intimation and such change of
                            price are at the sole discretion of the Merchant
                            attributing to various factors beyond control.
                        </li>
                        <li>
                            In order to continually improve and provide you/
                            Buyer with a seamless experience we need to ensure
                            maintenance and upkeep of our application/platform.
                            For such upkeep and maintenance, we may, charge a
                            nominal non-refundable amount from you/Buyer as
                            ‘platform fees’.
                        </li>
                    </ul>

                    <p>
                        Disclaimer: Prices on any product(s) as reflected on the
                        Platform may due to some technical issue, typographical
                        error or product information supplied by Merchant be
                        incorrectly reflected and in such an event Merchant may
                        cancel Buyer’s Order(s).
                    </p>
                    <ul style={{listStyleType: 'disc'}}>
                        <li>
                            The Merchant shall be solely responsible for any
                            warranty/guarantee of the goods or services sold to
                            the Buyers and in no event shall be the
                            responsibility of SpArts.
                        </li>
                        <li>
                            The transactions are bilateral between the Merchant
                            and Buyer, and between Merchant/Buyer and TSP,
                            therefore, SpArts is not liable to charge or deposit
                            any taxes applicable on such transactions.{' '}
                        </li>
                    </ul>

                    <h2>Cancellations and Refunds</h2>
                    <p>
                        Please refer to the Cancellation and Refund Policy for
                        cancellation and refunds terms in relation to usage of
                        the Platform for availing Services.{' '}
                    </p>
                    <div>
                        <ul style={{listStyleType: 'disc'}}>
                            <li>
                                The Buyer agrees and acknowledges that SpArts
                                shall not be responsible for:
                            </li>
                            <li>
                                The services or goods provided by the Merchants
                                including but not limited to serving of food
                                Orders suiting your requirements and taste;
                            </li>
                            <li>
                                The Merchant's services or goods, or services
                                provided by TSPs not being up to Buyer
                                expectations or leading to any loss, harm or
                                damage to him/her;
                            </li>
                            <li>
                                The availability or unavailability of certain
                                items on the menu;
                            </li>
                            <li>
                                The Merchant serving the incorrect Orders; or
                            </li>
                            <li>
                                Product liability of goods provided by
                                Merchants.
                            </li>
                            <li>
                                The details of the menu and price list available
                                on the Platform with respect to restaurant
                                services, goods or any other services are based
                                on the information provided by the Merchants and
                                SpArts shall not be responsible for any change
                                or cancellation or unavailability.
                            </li>
                            <li>
                                Buyers and Merchants agree and acknowledge that
                                SpArts is not responsible for any liability
                                arising out of delivery services provided by TSP
                                to them.
                            </li>
                            <li>
                                Buyers may not be able to avail Services if
                                their delivery location is outside SpArts’s
                                current scope of Service. SpArts will keep the
                                Buyer informed of the same at the time of
                                confirming his/her Order booking.
                            </li>
                            <li>
                                Buyer understands that delivery time quoted at
                                the time of confirming the Order is an
                                approximate estimate and may vary based on the
                                information obtained from TSPs and Merchants.
                                SpArts will not be responsible for any delay in
                                the delivery of an Order.
                            </li>
                            <li>
                                Buyer understands that there are certain
                                Merchants who undertake delivery of their goods
                                and services to the Buyer and the Merchant may
                                charge the Buyer for such service. SpArts
                                exercises no control on such delivery services
                                and same shall be under the control of Merchant
                                alone and hence all or any disputes arising out
                                of such delivery services shall be between Buyer
                                and Merchant alone. SpArts shall not be
                                responsible for such delivery services and
                                assumes no liability for disputes arising out of
                                the same.
                            </li>
                            <li>
                                Buyer’s Order will be only delivered to the
                                address designated by him/her at the time of
                                placing the Order on the Platform. Buyer’s Order
                                will be cancelled in the event of any change of
                                the address as informed by the TSP and Buyer
                                shall not be entitled to any refund for the
                                same. Delivery of goods and services in the
                                event of change of the delivery location shall
                                be subject to acceptance by the TSP or sole
                                discretion of SpArts.
                            </li>

                            <li>
                                The Buyer shall undertake to provide adequate
                                directions, information and authorisations to
                                accept delivery. In the event of no delivery due
                                to any act or omission attributable to Buyer,
                                the goods or services shall be deemed to have
                                been delivered to the Buyer and all risk and
                                responsibility in relation thereto shall pass to
                                the Buyer without being entitled to any refund.
                            </li>
                            <li>
                                The Buyer understands that SpArts’s (including
                                Merchant’s and TSP’s) liability ends once Order
                                has been delivered to him/her, except where the
                                product liability of the Merchant subsists.{' '}
                            </li>
                            <li>Services provided:</li>
                            <li>
                                You agree and acknowledge that SpArts shall not
                                be liable for any damages, losses or claims to
                                You or any third party in the event you have
                                failed to adhere to the Terms of Use.
                            </li>
                            <li>
                                Buyer shall be required to provide credit or
                                debit card details to the approved payment
                                gateways while making the payment on the
                                Platform. In this regard, Buyer agrees to
                                provide correct and accurate credit/ debit card
                                details to the approved payment gateways for
                                availing the Services. Buyer shall not use the
                                credit/ debit card which is not lawfully owned
                                by Buyer, i.e. in any transaction, Buyer must
                                use his/her own credit/ debit card. The
                                information provided by the Buyer will not be
                                utilized or shared with any third party unless
                                required in relation to fraud verifications or
                                by law, regulation or court order. Buyer shall
                                be solely responsible for the security and
                                confidentiality of his/her credit/ debit card
                                details. We expressly disclaim all liabilities
                                that may arise as a consequence of any
                                unauthorized use of your credit/ debit card.
                            </li>
                            <li>
                                Buyer shall be required to provide credit or
                                debit card details to the approved payment
                                gateways while making the payment on the
                                Platform. In this regard, Buyer agrees to
                                provide correct and accurate credit/ debit card
                                details to the approved payment gateways for
                                availing the Services. Buyer shall not use the
                                credit/ debit card which is not lawfully owned
                                by Buyer, i.e. in any transaction, Buyer must
                                use his/her own credit/ debit card. The
                                information provided by the Buyer will not be
                                utilized or shared with any third party unless
                                required in relation to fraud verifications or
                                by law, regulation or court order. Buyer shall
                                be solely responsible for the security and
                                confidentiality of his/her credit/ debit card
                                details. We expressly disclaim all liabilities
                                that may arise as a consequence of any
                                unauthorized use of your credit/ debit card.
                            </li>
                            <li>
                                SpArts does not offer any refunds against goods
                                or services already purchased from a Merchant or
                                TSP through the Platform unless an error that is
                                directly attributable to SpArts has occurred
                                during the purchase of such product or services.
                            </li>
                            <li>
                                We constantly strive to provide you with
                                accurate information on the Platform. However,
                                in the event of an error, we may, in our sole
                                discretion, contact you with further
                                instructions.
                            </li>
                            <li>
                                If you use the Platform, you do the same at your
                                own risk.
                            </li>
                            <li>
                                Buyer agrees that the Services shall be provided
                                through the Platform only during the working
                                hours of the relevant Merchants and TSPs.
                            </li>
                            <li>
                                <h4>No Endorsement</h4>
                            </li>
                            <li>
                                We do not endorse any Merchant. In addition,
                                although these Terms of Use require you to
                                provide accurate information, we do not attempt
                                to confirm, and do not confirm if it is
                                purported identity. We will not be responsible
                                for any damage or harm resulting from your
                                interactions with other Members.
                            </li>
                            <li>
                                By using the Services, you agree that any legal
                                remedy or liability that you seek to obtain for
                                actions or omissions of other Members or other
                                third parties will be limited to a claim against
                                the particular Members or other third parties
                                who caused you harm and you agree not to attempt
                                to impose liability on, or seek any legal remedy
                                from us with respect to such actions or
                                omissions.
                            </li>
                            <li>
                                <h4>
                                    Specific Terms with respect to Use of
                                    Platform for purchase of Pharmaceutical
                                    Products
                                </h4>
                            </li>
                            <li>
                                Buyer can use the Platform for purchase of
                                various medicines and pharmaceutical products
                                from the pharmacy stores that requires a valid
                                medical prescription issued by a medical expert/
                                doctor to be provided to a registered pharmacist
                                for the purpose of dispensing such medicines and
                                pharmaceutical products (“Prescription Drugs”),
                                offered for sale on the Platform by the
                                Merchant. In Order to purchase Prescription
                                Drugs from the Merchant through the Platform,
                                Buyer is required to upload a scanned copy of
                                the valid prescription on the Platform. The
                                Order would not be processed by the Merchant
                                until a copy of a valid prescription is uploaded
                                on the Platform, which shall be in turn shared
                                with the Merchant. The Merchant will verify the
                                prescription uploaded by Buyer and in case of
                                the Merchant observes any discrepancy in the
                                prescription uploaded by Buyer, the Merchant may
                                reject the Order. Buyer is also required to make
                                the original prescription available at the time
                                of delivery of the Prescription Drugs. Buyer
                                shall allow the TSP to stamp the original
                                prescription at the time of medicine delivery
                                failing which medicines will not be delivered.
                            </li>
                            <li>
                                Buyer understands and agrees that SpArts’s
                                Platform is merely a technology platform and the
                                medicines and pharmaceutical products are sold
                                by the Merchant. SpArts shall not be held
                                responsible in any manner for any error or
                                omission or act committed on part of the
                                Merchant.
                            </li>
                            <li>
                                Buyer agrees and undertakes that he/she will not
                                repeat the use of prescription for which drugs
                                have already been dispensed. In case a Buyer
                                found repeating the use of prescription, the
                                Order will be cancelled immediately. In any
                                event, SpArts shall not be responsible for any
                                adverse effects or harm caused to Buyer.
                            </li>
                            <li>
                                Buyer agrees and confirms that he/she is
                                completely aware of the indications, side
                                effects, drug interactions, effects of missed
                                doses or overdose of the medicines Buyer Order
                                through the Platform. It is imperative to seek
                                professional advice from Medical practitioner
                                before purchasing or consuming any medicine.
                            </li>
                            <li>
                                The Merchant/SpArts may maintain a record of the
                                prescriptions uploaded by the Buyers.
                            </li>
                            <li>
                                Please refer to the Cancellation and Refund
                                Policy for cancellation and refunds terms in
                                relation to usage of the Platform for availing
                                Services.
                            </li>
                            <li>
                                <h4>
                                    Specific Terms with respect to Use of
                                    Platform for purchase of Cigarettes and
                                    Other Tobacco Products
                                </h4>
                            </li>
                            <li>
                                Buyer agrees and undertakes that he/she will not
                                use the Platform to purchase cigarettes and
                                other tobacco products if he/she is below the
                                age of 18 years.
                            </li>
                            <li>
                                The TSP may request Buyer to provide a valid age
                                proof at the time of delivery of cigarettes
                                and/other tobacco products, Buyer agrees and
                                undertakes to provide a valid age proof, if
                                requested for verification. If the Buyer is
                                below the age of 18 years the TSP shall cancel
                                the Order. Buyer shall not place Order for loose
                                cigarettes.
                            </li>
                            <li>
                                Please refer to the Cancellation and Refund
                                Policy for cancellation and refunds terms in
                                relation to usage of the Platform for availing
                                Services.
                            </li>
                            <li>
                                <h4>
                                    Specific Terms with respect to Use of
                                    Platform for purchase of alcoholic beverages
                                </h4>
                            </li>
                            <li>
                                Eligibility to use the Wine Shop category in the
                                Platform: By accessing the Wine Shop category in
                                the Platform, the Buyer represents that he/she
                                is of legal drinking age in Buyer’s state of
                                domicile and/ or where he/she is accessing the
                                Platform and has not been previously suspended
                                or prohibited from accessing or otherwise
                                availing the Services of the Platform.
                            </li>
                            <li>
                                Mandatory age and Know Your Customer (KYC)
                                verification: The Buyer agrees to undergo and
                                complete mandatory age and KYC verification
                                process to access the Platform. The Buyer
                                undertakes to provide a valid and legible KYC
                                document for the purpose of verification,
                                failing which he/she will not be allowed to
                                access the Platform. The Buyer shall be solely
                                liable as to the veracity of the KYC documents
                                provided by him/her, SpArts undertakes no
                                liability in this regard. Collection of the
                                Buyer’s details and documents for verification
                                will be subject to our Privacy Policy.
                            </li>
                            <li>
                                One Time Password (OTP) at the time of delivery:
                                Once the Order for alcoholic beverages is
                                placed, the Buyer will receive an OTP on his/her
                                registered mobile number which the Buyer should
                                mandatorily provide to the TSP to receive
                                delivery of the Order of alcoholic beverages. If
                                OTP is not provided to the TSP, the Buyer’s
                                Order in this case will be cancelled without any
                                refund, and the products will not be delivered
                                to him/her.
                            </li>
                            <p>
                                The Buyer agrees and undertakes that he/she will
                                not share the OTP with any person below the
                                legal drinking age to collect delivery of
                                his/her Order of alcoholic beverages.
                            </p>
                            <li>
                                Delivery Address: The Buyer agrees and
                                undertakes that he/she will not provide the
                                address of any public place including but not
                                limited to educational institution, hospital,
                                religious places as delivery address for the
                                Order. If the delivery address is found to be
                                the address of a public place, SpArts reserves
                                the right to immediately cancel the Order
                                without being liable to process any refund.
                            </li>
                            <li>
                                Please refer to the Cancellation and Refund
                                Policy for cancellation and refunds terms in
                                relation to usage of the Platform for availing
                                Services.
                            </li>
                            <li>
                                <h2>
                                    Specific Terms with respect to Use of
                                    Platform for availing Services of SpArts
                                    Genie
                                </h2>
                            </li>
                            <li>
                                The pick-up and drop off services are offered by
                                and agreed to between the Users and the TSP
                                alone. SpArts assumes the role of facilitator
                                only and SpArts merely provides a Platform to
                                facilitate pick-up and drop off services between
                                TSP and Users. At no point of time, SpArts shall
                                be held responsible or liable for any
                                transactions between Users and TSP and for the
                                services offered by TSP.
                            </li>
                            <li>
                                The TSP will provide services as per your sole
                                instructions. SpArts does not have any control
                                or does not determine or advise or in any way
                                involve itself in the offering or acceptance of
                                such services to be provided by the TSP to the
                                User.
                            </li>
                            <li>
                                You agree that the details of the items for
                                pick-up and drop or concierge services, and the
                                pick-up and drop location are provided or
                                entered by you in the Platform, in accordance
                                with which the TSP will render the services and
                                perform the Task. SpArts shall not be held
                                responsible for any issues concerning the Task
                                as the same is performed by the TSP as per your
                                sole instructions and/or the details provided by
                                you.
                            </li>
                            <li>
                                You agree and undertake that you shall not
                                request for a pick-up or drop of any item which
                                is illegal, hazardous, dangerous, or otherwise
                                restricted or prohibited under any statute or
                                law or regulation for transportation including
                                but not limited to, items classified as
                                hazardous material, dangerous goods, prohibited
                                or restricted articles by IATA (International
                                Air Transport Association), ICAO (International
                                Civil Aviation Organization), BCAS (Bureau of
                                Civil Aviation Security) or other government or
                                regulatory agencies; radio-active, incendiary,
                                corrosive or flammable substances, hazardous
                                chemicals, explosives, firearms or parts thereof
                                and ammunition, firecrackers, cyanides,
                                precipitates, gold and silver ore, bullion,
                                precious metals and stones, jewellery,
                                semi-precious stones including commercial
                                carbons or industrial diamonds, currency of any
                                nationality, securities, coupons, stamps,
                                negotiable instruments in bearer form, cashier's
                                cheques, travellers’ cheques, money orders,
                                sodexo pass, passports, credit/debit/ATM cards,
                                antiques, works of art, lottery tickets and
                                gambling devices, crockeries, livestock, fish,
                                insects, animals, plants and plant material,
                                human corpses, organs or body parts, blood,
                                urine and other liquid diagnostic specimens,
                                hazardous or bio-medical waste, wet ice,
                                pornographic materials, perishable foodstuffs,
                                fragile glassware, contraband, bottled alcoholic
                                beverages or any intoxicant, infectious item or
                                narcotics and psychotropic substances, Indian
                                Postal Articles and all items that infringe the
                                Indian Postal Act of 1898, liquid, semi-liquid
                                and gases, machinery parts containing oil,
                                grease, fuel or batteries, philately items,
                                oxidizing substances and organic peroxides
                                solids, etc. You further agree that you shall
                                not request for pick-up or drop of item(s) which
                                require special transportation permit or require
                                any special license under applicable law and/or
                                item(s) which exceed beyond the dimensions of
                                14*14 inches and weight beyond 12 kgs.
                            </li>
                            <li>
                                In the event of you requesting transportation of
                                any illegal or unlawful or prohibited items as
                                mentioned above or which is otherwise restricted
                                under any applicable law, SpArts has the right
                                to report the same to the law enforcement
                                authorities.
                            </li>
                            <li>
                                In the event, during transit of your item(s)
                                from pick-up location to drop-off location or
                                while undertaking concierge services, if police
                                or other law enforcement agencies demand for
                                display of the item(s) for verification, the TSP
                                shall have the right to display the item(s) to
                                such authorities.
                            </li>
                            <li>
                                You agree and undertake that you shall abstain
                                from sending high value item(s) whilst using the
                                Services through the Platform and if you use the
                                Services to send any high value item, it shall
                                be at your own risk only and SpArts shall not be
                                held responsible for loss or any damage caused
                                to such item(s). It is your sole responsibility
                                to insure the items(s) to cover the risk of loss
                                or damage to your item(s) during transit, SpArts
                                shall not be responsible for the same.
                            </li>
                            <li>
                                SpArts and the TSP shall have the right to deny
                                performance of Task(s) where it is not possible
                                for the TSP to transfer the item(s) from the
                                pick-up location to the drop-off location due to
                                the big volume of the item(s).
                            </li>
                            <li>
                                You understand and agree that the recipient of
                                the item(s) should be available at the drop-off
                                location at time communicated for delivery of
                                the item(s). In the event the recipient is not
                                available at the drop-off location or refuses to
                                take delivery or cannot be located by the TSP,
                                you will receive a notification either through
                                App and/ SMS and/ or a phone call
                                (“Non-acceptance Message”). On receipt of
                                Non-acceptance Message, it shall be your
                                responsibility to take re-delivery of the
                                item(s) at your location on payment of
                                additional cost which may be communicated by
                                SpArts. In the event you refuse to pay the cost
                                of re-transportation or re-delivery of the
                                item(s) for which a Non-acceptance Message has
                                been sent to you, you hereby authorize SpArts
                                and/the TSP to hold the item(s) in lieu of its
                                claim of re-transportation of the item(s) and
                                subject to payment of the said amount release
                                the item(s) in your favour. In the event you
                                fail to act in the matter and take re-delivery
                                of the item(s) from the TSP, within the
                                reasonable time especially in case perishable
                                goods or goods with limited shelf life, you
                                hereby waive all your claims to such item(s) and
                                declare that SpArts or the TSP shall not be
                                liable for any loss or damage caused or
                                suffered, to the item(s), whether directly or
                                indirectly.
                            </li>
                            <li>
                                Please refer to the Cancellation and Refund
                                Policy for cancellation and refunds terms in
                                relation to usage of the Platform for availing
                                Services.
                            </li>
                        </ul>
                    </div>
                </ul>
            </div>
            <FooterStatic />
        </div>
    );
};

export default TermsOfUse;
